import { Api } from "./Api";
import { User, BaseUser } from "../models/User";
import { locale } from "moment";
import { Common } from "../utils/Common";

export class UserApi {
  static ROLE_BASE_ENDP: string = "/role";
  static COMPANY_BASE_ENDP: string = "/company";
  static ROLE_LIST_ENDP: string = UserApi.ROLE_BASE_ENDP + "/list";
  static BASE_ENDP: string = "/user";
  static GET_COMPANIES_ENDP: string =  UserApi.COMPANY_BASE_ENDP + "/list";
  static USERS_LIST: string = UserApi.BASE_ENDP + "/list";
  static USER_CREATE: string = UserApi.BASE_ENDP + "/create";
  static USER_UPDATE: string = UserApi.BASE_ENDP + "/update";
  static USER_DELETE: string = UserApi.BASE_ENDP + "/delete";
  static USERS_UPLOAD: string = UserApi.BASE_ENDP + "/upload_full";
  static USER_RESET: string = UserApi.BASE_ENDP + "/reset_password";
  static USER_FORGOT: string = UserApi.BASE_ENDP + "/forgot_password";
  static USER_FORGOTTEN_RESET: string = UserApi.BASE_ENDP + "/reset";
  static DOWNLOAD_TEMPLATE: string = UserApi.BASE_ENDP + "/upload_full/template";


  static GetMe = (mobileView: boolean) => {
    let params = { app_fe: "backoffice" };
    if(mobileView || Common.CheckMobileUrl()){
      console.log("[SWITCH MOBILE URL]")
      params = { app_fe: "mobile" };
    }

    return Api.Post(UserApi.BASE_ENDP, params);
  };

  static GetCompanies = () => {
    return Api.Post(UserApi.GET_COMPANIES_ENDP, {});
  };
  static GetRoles = () => {
    return Api.Post(UserApi.ROLE_LIST_ENDP, {});
  };
  static GetUsersList = (params?:any) => {
    return Api.Post(UserApi.USERS_LIST, params);
  };
  static ResetPasswords = (users: User[]) => {
    let guids: string[] = [];
    for (var i in users) {
      guids.push(users[i].guid);
    }
    return Api.Post(UserApi.USER_RESET, { users: users });
  };
  static CreateUser = (user: User) => {
    return Api.Post(UserApi.USER_CREATE, UserApi.UserRequest(user));
  };
  static UpdateUser = (user: User) => {
    return Api.Put(UserApi.USER_UPDATE, UserApi.UserRequest(user));
  };
  static UploadUsers = (formData: FormData) => {
    return Api.UploadFile(UserApi.USERS_UPLOAD, formData);
  };
  static DownloadTemplate = () => {
    return Api.Post(UserApi.DOWNLOAD_TEMPLATE, {});
  };
  static DeleteUser = (user: User) => {
    return Api.Delete(UserApi.USER_DELETE, UserApi.UserRequest(user));
  };
  static ForgotPassword = (params?:any) => {
    return Api.Post(UserApi.USER_FORGOT, params);
  };
  static ResetForgottenPassword = (params?:any) => {
    return Api.Post(UserApi.USER_FORGOTTEN_RESET, params);
  };
  static UserRequest = (user: User) => {
    let request: BaseUser = new BaseUser({
      name: user.name,
      surname: user.surname,
      fiscal_code: user.fiscal_code,
      guid: user.guid,
      company_code: user.company_code,
      email: user.email,
      locale: user.locale,
      password: user.password,
      password_hash: user.password_hash,
      user_type: user.user_type,
      roles: user.roles,
    });
    

    if (request.password_hash === "") {
      delete request["password_hash"];
    }

    if (request.password === "") {
      delete request["password"];
    }

    if (request.guid === "") {
      delete request["guid"];
    }
    return request;
  };
}
