import { Api, HTTP_STATUS } from "../../../api/Api";
import { UserLog } from "../../../interfaces/UserLogState";
import { UsersLog } from "../../../utils/UsersLog";
import { LOCAL_STORAGE_AUTH_KEY } from "../../localStorage";
import { loggedOut, startLogout } from "../auth";
import { createUsersLog } from "../backoffice/usersLog";
import { AddError } from "../toast";


export const REQUEST_FAILED = "REQUEST_FAILED";
export const REQUEST_DATA = "REQUEST_DATA";
export const USERS_LIST_DONE = "USERS_LIST_DONE";
export const CHECK_IN_DONE = "CHECK_IN_DONE";
export const REQUEST_DATA_BLOCK = "REQUEST_DATA_BLOCK";
export const USER_INFO_DONE = "USER_INFO_DONE";
export const REQUEST_CERTIFICATION_LIST = "REQUEST_DATA";
export const CERTIFICATION_LIST_DONE = "CERTIFICATION_LIST_DONE";
export const REQUEST_ACCEPT_CERTIFICATION = "REQUEST_DATA";
export const ACCEPT_CERTIFICATION = "ACCEPT_CERTIFICATION";
export const REQUEST_IS_CERTIFICATION = "REQUEST_DATA";
export const IS_CERTIFICATION_DONE = "IS_CERTIFICATION_DONE";
export const REQUEST_CONFIGURATION = "REQUEST_DATA";
export const CONFIGURATION_DONE = "CONFIGURATION_DONE";

export function requestFailed() {
    return { type: REQUEST_FAILED };
}

export function usersListDone(userList: any) {
    return { type: USERS_LIST_DONE, userList };
}

export function requestUsersList() {
    return { type: REQUEST_DATA };
}

export function requestCheckin() {
    return { type: REQUEST_DATA_BLOCK };
}

export function checkInDone(response: any) {
    return { type: CHECK_IN_DONE, response };
}

export function userInfoDone(userInfo: any) {
    return { type: USER_INFO_DONE, userInfo };
}

export function requestCertificationList() {
    return { type: REQUEST_CERTIFICATION_LIST };
}

export function certificationListDone(response: any) {
    return { type: CERTIFICATION_LIST_DONE, response };
}

export function requestAcceptCertification() {
    return { type: REQUEST_ACCEPT_CERTIFICATION };
}

export function acceptCertificationDone(response: any) {
    return { type: ACCEPT_CERTIFICATION, response };
}

export function requestIsCertification() {
    return { type: REQUEST_DATA };
}

export function isCertificationDoneResponse(response: any) {
    return { type: IS_CERTIFICATION_DONE, response };
}

export function requestConfiguration() {
    return { type: REQUEST_DATA };
}

export function configurationDoneResponse(response: any) {
    return { type: CONFIGURATION_DONE, response };
}

/**
 * Restituisce lista utenti 
 */
export function getUsersList(body: any) {
    return (dispatch: any, getState: any) => {
        dispatch(requestUsersList());

        return Api.Post('/user/list', body)
            .then((response: any) => {
                if (response.status === HTTP_STATUS.OK) {
                    return response.json();
                } else {
                    let unauthorized = false;
                    let error = "";
                    return Api.IsAuthorized(response)
                        .then((text: any) => {
                            error = text;
                        })
                        .catch((err: string) => {
                            error = err;
                            unauthorized = true;
                        })
                        .finally(() => {
                            if (unauthorized) {
                                dispatch(startLogout());
                            }
                            throw new Error(error);
                        });
                }
            })
            .then(res => {
                if (res !== null) {
                    console.log("[Action] GET USERS LIST", res)
                    dispatch(usersListDone(res));
                }
            })
            .catch(err => {
                console.log(err);
                if(err.toString().includes("access_token")){
                    dispatch(startLogout())
                }
                dispatch(AddError(err));
                dispatch(requestFailed())
            });
    };
}

/**
 * Restituisce dati utente
 */
export function user() {
    return (dispatch: any, getState: any) => {
        dispatch(requestUsersList());
        return Api.Post('/user', {})
            .then((response: any) => {
                if (response.status === HTTP_STATUS.OK) {
                    return response.json();
                } else {
                    let unauthorized = false;
                    let error = "";
                    return Api.IsAuthorized(response)
                        .then((text: any) => {
                            error = text;
                        })
                        .catch((err: string) => {
                            error = err;
                            unauthorized = true;
                        })
                        .finally(() => {
                            if (unauthorized) {
                                dispatch(startLogout());
                            }
                            throw new Error(error);
                        });
                }
            })
            .then(res => {
                if (res !== null) {
                    console.log("[Action] GET USERS INFO", res)
                    dispatch(userInfoDone(res));
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(AddError(err));

                dispatch(requestFailed())
            });
    };
}


/**
 * Checkin utente + eventuali colleghi taggati 
 * @param body
 */
export function checkIn(body: any[]) {
    //SetUp UserLog Variable
    let userLog = new UserLog({
        action_guid: UsersLog.QRCODE_CHECKIN_ACTION,
        fe_view_guid: UsersLog.HOME_PAGE_MOBILE_VIEW,
        be_api_guid: UsersLog.QRCODE_CHECKIN_API,
        description: "QRCode Checkin Scan"
    })

    return (dispatch: any, getState: any) => {
        dispatch(requestCheckin());
        let res: any = {}
        return Api.Post('/event/create', body)
            .then((response: Response) => {
                if (response.status === HTTP_STATUS.OK) {
                    return response.json();
                } else {
                    let unauthorized = false;
                    let error = "";
                    let res = response.clone()
                    return Api.IsAuthorized(response)
                        .then((text: any) => {
                            error = text;
                        })
                        .catch((err: string) => {
                            error = err;
                            unauthorized = true;
                        })
                        .finally(() => {
                            if (unauthorized) {
                                dispatch(startLogout());
                                throw new Error(error);
                            } else {
                                res.json().then((msg: any) => {
                                    dispatch(checkInDone(msg))
                                })
                            }
                        });
                }
            })
            .then(res => {
                if (res !== null) {
                    console.log("[Action] CHECK IN DONE", res)
                    dispatch(checkInDone(res));
                    //createUserLog
                    createUsersLog(userLog);
                }
            })
            .catch(err => {
                console.log(err);
                // localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
                // dispatch(loggedOut());
                if(err.toString().includes("access_token")){
                    dispatch(startLogout())
                }
                dispatch(AddError(err));
                dispatch(requestFailed())
            });
    };
}

/**
 * Restituisce lista certificati
 */
export function certificationList() {
    return (dispatch: any, getState: any) => {
        dispatch(requestCertificationList());
        return Api.Post('/certification/list', { locale: navigator.language.replace('-', '_') })
            .then((response: any) => {
                if (response.status === HTTP_STATUS.OK) {
                    return response.json();
                } else {
                    let unauthorized = false;
                    let error = "";
                    return Api.IsAuthorized(response)
                        .then((text: any) => {
                            error = text;
                        })
                        .catch((err: string) => {
                            error = err;
                            unauthorized = true;
                        })
                        .finally(() => {
                            if (unauthorized) {
                                dispatch(startLogout());
                            }
                            throw new Error(error);
                        });
                }
            })
            .then(res => {
                if (res !== null) {
                    console.log("[Action] GET CERTIFICATION INFO", res)
                    dispatch(certificationListDone(res));
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(AddError(err));

                dispatch(requestFailed())
            });
    };
}

/**
 * Accettazione autocertificazione
 */
export function acceptCertification() {
    return (dispatch: any, getState: any) => {
        dispatch(requestAcceptCertification());
        return Api.Post('/certification_history/create', {
            "certification_guid": 1,
            "no_close_contact": false,
            "no_cold_flue": true
        })
            .then((response: any) => {
                if (response.status === HTTP_STATUS.OK) {
                    return response.json();
                } else {
                    let unauthorized = false;
                    let error = "";
                    return Api.IsAuthorized(response)
                        .then((text: any) => {
                            error = text;
                        })
                        .catch((err: string) => {
                            error = err;
                            unauthorized = true;
                        })
                        .finally(() => {
                            if (unauthorized) {
                                dispatch(startLogout());
                            }
                            throw new Error(error);
                        });
                }
            })
            .then(res => {
                if (res !== null) {
                    console.log("[Action] ACCEPT CERTIFICATION", res)
                    dispatch(acceptCertificationDone(res));
                    dispatch(isCertificationDone())
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(AddError(err));
                dispatch(acceptCertificationDone(err));
                // localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
                // localStorage.removeItem('userInfo');
                // dispatch(loggedOut());
                // dispatch(requestFailed())
            });
    };
}


/**
 * Verifica se autocertificazione è stata già fatta 
 */
export function isCertificationDone() {
    return (dispatch: any, getState: any) => {
        dispatch(requestIsCertification());
        return Api.Post('/certification_history/list', {
            today: true
        })
            .then((response: any) => {
                if (response.status === HTTP_STATUS.OK) {
                    return response.json();
                } else {
                    let unauthorized = false;
                    let error = "";
                    return Api.IsAuthorized(response)
                        .then((text: any) => {
                            error = text;
                        })
                        .catch((err: string) => {
                            error = err;
                            unauthorized = true;
                        })
                        .finally(() => {
                            if (unauthorized) {
                                dispatch(startLogout());
                            }
                            throw new Error(error);
                        });
                }
            })
            .then(res => {
                if (res !== null) {
                    console.log("[Action] isCertificationDone", res)
                    dispatch(isCertificationDoneResponse(res));
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(AddError(err));
                dispatch(requestFailed())
            });
    };
}

export function configuration() {
    return (dispatch: any, getState: any) => {
        dispatch(requestConfiguration());
        return Api.Post('/configuration', {})
            .then((response: any) => {
                if (response.status === HTTP_STATUS.OK) {
                    return response.json();
                } else {
                    let unauthorized = false;
                    let error = "";
                    return Api.IsAuthorized(response)
                        .then((text: any) => {
                            error = text;
                        })
                        .catch((err: string) => {
                            error = err;
                            unauthorized = true;
                        })
                        .finally(() => {
                            if (unauthorized) {
                                dispatch(startLogout());
                            }
                            throw new Error(error);
                        });
                }
            })
            .then(res => {
                if (res !== null) {
                    console.log("[Action] configuration", res)
                    dispatch(configurationDoneResponse(res));
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(AddError(err));

                dispatch(requestFailed())
            });
    };
}