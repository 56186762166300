import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LUActions } from '../../../../../utils/LocationUtils';
import Button from '../../../../UI/Button/Button';
import { Button as UIButton, Icon, Label } from 'semantic-ui-react';
import Input from '../../../../UI/Input/Input';
import { Input as UInput } from 'semantic-ui-react';
import { Location } from '../../../../../models/Location';
import "./LocationDetail.scss";
import { Desk } from '../../../../../models/Desk';
import { Row, Col } from 'react-bootstrap';
import { AutoComplete } from 'primereact/autocomplete';
import { IoCloseOutline } from 'react-icons/io5';

const MAP_FILE_SIZE_LIMIT = 2000000;
const ACCEPTED_FORMAT = ["pdf","png", "jpg", "jpeg"]

const WsManagerLocationDetail = (props: any) => {

    const [title, setTitle] = useState(props.selectedNode.title)
    const [riskPercentage, setRiskPercentage] = useState(props.selectedNode.risk_percentage)
    const [eda, setEda] = useState(props.estimated_distance_time)
    const [seatsTotal, setSeatsTotal] = useState(props.selectedNode.seats_total)
    const [seatsAvailable, setSeatsAvailable] = useState(props.selectedNode.seats_available)
    const [newDeskName, setNewDeskName] = useState("")
    const [desks, setDesks] = useState<any[]>(props.desks)
    const [loadingGuid, setLoadingGuid] = useState()
    const [timezoneSuggestion, setTimezoneSuggestion] = useState(props.selectedNode.timezone)
    const [suggested, setSuggested] = useState<any>()
    const [timezone, setTimezone] = useState(props.selectedNode.timezone) //{'guid': null, 'timezone': null}
    const [timezoneCode, setTimezoneCode] = useState(props.selectedNode.timezone_code)
    const [guid, setGuid] = useState("")
    const [mapFile, setMatpFile] = useState<any>()
    const [mapFileFormat, setMapFileFormat] = useState("")

    const { formatMessage } = props.intl;


    useEffect(() => {

        console.log("--- Node Selected --- ", props.selectedNode)

        setNewDeskName("")
        setSuggested("")
        setTitle(props.selectedNode.title)
        setTimezone(props.selectedNode.timezone)
        setRiskPercentage(props.selectedNode.risk_percentage)
        setEda(props.selectedNode.estimated_distance_time)
        setSeatsTotal(props.selectedNode.seats_total)
        setSeatsAvailable(props.selectedNode.seats_available)
        setRiskPercentage(props.selectedNode.risk_percentage)
        setEda(props.selectedNode.estimated_distance_time)
        setDesks(props.desks)
        if (typeof props.selectedNode.timezone === 'string') {
            setTimezoneSuggestion(props.selectedNode.timezone)
            setTimezoneCode(props.selectedNode.timezone_code)
        } else { // receive timezone object
            setTimezoneSuggestion(props.selectedNode.timezone.timezone)
        }
        closeAllDeskTabs()
        console.log("[LOCAL DETAILS -- DETAIL NODE]", props.selectedNode)
        if (props.desks && props.desks.length > 0) {
            updateTotalSeats(props.desks)
        }
        setLoadingGuid(undefined)


        //init download list timezones
        // if (!props.timezones || (props.timezones.items.length === 0 && !props.timezones.loading)) {
        //     if (!suggested || suggested.length === 0) {
        //         setTimezoneSuggestions(undefined)

        //     }

        // } else {

        //     console.log("[SUGGESTION TIMEZONES] ", props.timezones);
        //     let suggestions: any[] = [...props.timezones]
        //     console.log("SUGGESTIONS", suggestions)

        //     setTimezoneSuggestions(suggestions)
        // }



    }, [props.selectedNode])


    const saveInfo = () => {
        let newNode: Location = props.selectedNode
        newNode.title = newNode.name = title
        newNode.estimated_distance_time = eda
        newNode.risk_percentage = riskPercentage
        newNode.timezone = timezone;
        newNode.timezone_code = timezoneCode;
        if (!isNullOrUndefined(timezone.guid)) {
            newNode.timezone = timezone;
            newNode.timezone_code = timezone.guid;
        }

        console.log("--- New Node --- ", newNode)

        props.onSave(newNode)
    }


    const disableEditButton = (node: any) => {
        return title === ""
        // || node.estimated_distance_time === "" || node.risk_percentage === ""
    }

    const save = () => {
        let newNode: Location = props.selectedNode
        newNode.seats_available = seatsTotal
        newNode.estimated_distance_time = eda
        newNode.risk_percentage = riskPercentage
        props.onSave(newNode)
    }

    const addDesk = () => {
        console.log("cl")
        if (newDeskName && newDeskName.length > 0) {
            let array = [...desks]
            let desk = new Desk(
                {
                    name: newDeskName,
                    seats_available: 0,
                    seats_busy: 0,
                    parent_location_guid: props.selectedNode.guid,
                    company_code: props.selectedNode.company_code
                }
            )
            delete desk["guid"]
            array.push(desk)
            setDesks(array)
            let node = { ...props.selectedNode }
            node.desks_properties = array
            props.onUpdateDesks(node)
            console.log("CREO", node)
        }
    }

    const toggleDeskTab = (desk: Desk, i: number, open?: boolean) => {
        // debugger
        desk.opened = open === undefined ? !desk.opened : open
        let array = [...desks]
        array[i] = desk
        setDesks(array)

    }

    const closeAllDeskTabs = () => {
        if (!props.selectedNode.desks_properties) return
        let array = [...props.selectedNode.desks_properties]
        for (var i in array) {
            array[i].opened = false
        }
        setDesks(array)
    }

    const updateTotalSeats = (desks = props.desks) => {
        console.log("UPDATE SEATS", props.selectedNode)
        let seatsAvailable = 0
        let seatsTotal = 0
        for (var i in desks) {
            if (desks[i].available === 1) {
                seatsAvailable += parseInt(desks[i].seats_available)
                seatsTotal += parseInt(desks[i].seats_total)
            }
        }
        setSeatsAvailable(seatsAvailable)
        setSeatsTotal(seatsTotal)

    }

    const onUpdateSeatsInput = (value: any, index: any, prop: any) => {
        let array = [...desks]
        array[index][prop] = parseInt(value)
        setLoadingGuid(array[index].guid)
        setDesks(array)
        updateTotalSeats()

    }

    const onDeskUpdate = (desk: Desk) => {
        let node: Location = { ...props.selectedNode }
        setLoadingGuid(desk.guid)
        node.desks_properties = desks
        node.seats_total = seatsTotal
        node.seats_available = seatsAvailable
        props.onUpdateDesks(node)
        console.log("UPDATE", node)
    }
    const onDeleteDesk = (index: number) => {
        let node = { ...props.selectedNode }
        node.desks_properties = desks
        node.desks_properties[index].deleted = 1
        setLoadingGuid(node.desks_properties[index].guid)
        node.deskLoading = node.desks_properties[index].guid
        props.onDeleteDesk(node)
        console.log("DELETE", node)
    }
    const onDisableEnableDesk = (index: number, available: number) => {
        let node = { ...props.selectedNode }
        node.desks_properties = desks
        node.desks_properties[index].available = available
        setLoadingGuid(node.desks_properties[index].guid)
        props.onUpdateDesks(node)
        console.log("DISABLE/ENABLE", node)
    }
    const onPrintDesk = (index: number) => {
        let array = [...desks]
        let toPrint = { name: `${props.selectedNode.name} - ${array[index].name}`, guid: array[index].guid }
        props.onPrintDesk([toPrint])
        console.log("PRINT", toPrint)
    }

    const isDeskLoading = (desk: Desk) => {
        return props.selectedNode.loading && loadingGuid === desk.guid
    }


    const onSelectChange = (e: any) => {

        if (e && e.value) {
            setSuggested(e.value)
        } else {
            setSuggested("")
        }
    }

    const suggestTimezones = (event: any) => {
        if (event.query.length > 0) {
            console.log("[TIMEZONE VALUE SENT] ", event.query)
            props.onSearchTimezones({ timezone: { value: event.query } })
        }
    }

    const onSuggestionSelection = (e: any) => {
        setTimezone(e.value)
        setTimezoneSuggestion(e.value.timezone)
        if (localStorage.getItem("DEBUG_MODE") == "true") {
            console.log("[TIMEZONE SELECTED] ", e.value)
        }
    }

    const isNullOrUndefined = (string: any) => {
        if (string == undefined || string == null || string == "")
            return true;
        return false
    }

    const readMapFile = (e: any) => {
        let reader = new FileReader()
        if (e.target.files?.item(0)) {
            let filename_substrings = e.target.files?.item(0).name.split(".")
            if (e.target.files?.item(0).size < MAP_FILE_SIZE_LIMIT) {
                if (ACCEPTED_FORMAT.includes(filename_substrings[filename_substrings.length-1])){
                // @ts-ignore a File object is an instance of Blob
                reader.readAsBinaryString(e.target.files?.item(0))
                reader.onload = function () {
                    if (reader.result)
                        setMatpFile(window.btoa(String(reader.result)))
                }
                setMapFileFormat(filename_substrings[filename_substrings.length-1])}
                else {
                    setMatpFile("")
                    props.onError({ message: formatMessage({ id: 'app.generic.format_not_supported' }) }, true, true)
                }
            } else {
                setMatpFile("")
                props.onError({ message: formatMessage({ id: 'app.generic.file_exceeds_limit' }) }, true, true)
            }

        }
        else { setMatpFile("") }
    }

    return (
        <div className={`_flex h-100 detail-tab flex-direction-column ${props.selectedNode.guid !== "" ? "active" : ""}`} style={{ background: '#efeeef', overflowY: 'scroll' }}>
            <div>
            <br />
            <IoCloseOutline className="icon-close" onClick={props.onCloseDetails} />
                <div className="detail-title">
                   
                    <h2><FormattedMessage id="app.wsmanager.location.detail.title" defaultMessage="Room Settings" /></h2>
                    <div className="detail-title-buttons _flex align-items-center">
                        <Button btntype="rounded-input print" clicked={props.onPrint}>
                            <i className="icon-cv19-print mr-2"></i>
                            <FormattedMessage id="location.printQr" defaultMessage="Print Qr" />
                        </Button>
                        {/* <Icon className="close" name='times circle outline' onClick={(e: any) => { setSelectedNode(new Location()) }} /> */}
                    </div>
                </div>
                <div className="detail-form justify-content-center">
                    <FormattedMessage id="app.generic.title" defaultMessage="Title">
                        {
                            (placeholder: string) => (
                                <Input elementType="input"
                                    fluid
                                    icon="icon map marker"
                                    placeholder={placeholder}
                                    changed={(event: any) => { setTitle(event.target.value) }}
                                    type="text"
                                    elementConfig={{ maxlength: 60 }}
                                    containerClass="ui left icon input w-100"
                                    className="my-2 rounded-input "
                                    value={title} />
                            )
                        }
                    </FormattedMessage>

                    {/* <div className="_flex flex-direction-row justify-content-between my-3">
                        <div className="_flex">
                            <FormattedMessage id="timezone.query.select" defaultMessage="Select Timezone">
                                {(placeholder: string) => (
                                        <FormattedMessage id="timezone.query.filter" defaultMessage="Search Timezone">
                                            {(filterPlaceholder: string) => (
                                                <div className="_flex">
                                                <AutoComplete className="_flex mr-3 " 
                                                    value={timezoneSearch} 
                                                    dropdown={true} 
                                                    placeholder={filterPlaceholder} 
                                                    field="timezone" 
                                                    onChange={onSelectChange}
                                                    // suggestions={props.timezone} completeMethod={suggestTimezones} onDropdownClick={suggestTimezones} />
                                                    suggestions={props.timezones} 
                                                    onDropdownClick={suggestTimezones} />
                                                </div>
                                            )}
                                        </FormattedMessage>
                                    )}
                            </FormattedMessage>
                        </div>
                    </div> */}

                    <div className="_flex w-100 flex-direction-column input-with-label mt-3">
                        <label className="_flex">
                            <FormattedMessage id="app.wsmanager.location.booking.timezones_selection" defaultMessage="Search Timezones" />
                        </label>

                        <AutoComplete className="_flex mr-3 "
                            value={suggested}
                            dropdown={true}
                            field="timezone"
                            onChange={onSelectChange}
                            onSelect={onSuggestionSelection}
                            suggestions={props.timezones}
                            completeMethod={suggestTimezones}
                            onDropdownClick={suggestTimezones}
                            placeholder={(!isNullOrUndefined(timezoneSuggestion)) ? timezoneSuggestion : props.intl.formatMessage({ id: "timezone.query.search" })} //
                        />
                    </div>


                    {/* <FormattedMessage id="location.riskPercentage" defaultMessage="Risk Percentage">
                    {
                        (placeholder: string) => (
                            <Input elementType="input"
                                fluid
                                icon="icon percent"
                                placeholder={placeholder}
                                changed={(event: any, value: any) => { setRiskPercentage(event.target.value) }}
                                type="text"
                                containerClass="ui left icon input w-80"
                                className="my-2 rounded-input w-100"
                                value={riskPercentage} />
                        )
                    }
                </FormattedMessage>
                <FormattedMessage id="location.eda" defaultMessage="Estimated Distance Time">
                    {
                        (placeholder: string) => (
                            <Input elementType="input"
                                fluid
                                icon="icon time"
                                placeholder={placeholder}
                                changed={(event: any, value: any) => { setEda(event.target.value) }}
                                type="text"
                                containerClass="ui left icon input w-80"
                                className="my-2 rounded-input w-100"
                                value={eda} />
                        )
                    }
                </FormattedMessage> */}

                    <div className="_flex w-100" style={{ margin: '0 auto' }}>
                        <Button
                            btntype="OK detail-bg px-4 w-100 rounded-input my-3"
                            clicked={(e: any) => { props.onClearNode() }}
                        >
                            <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" />
                        </Button>
                        <Button
                            btntype="OK green-bg px-4 w-100 rounded-input my-3"
                            clicked={(e: any) => { saveInfo() }}
                            loading={props.selectedNode.loading}
                            disabled={disableEditButton(props.selectedNode)}>
                            <FormattedMessage id="app.generic.save" defaultMessage="Save" />
                        </Button>
                    </div>
                </div>
                <div >
                    <Row className="mx-0">
                        <label className="_flex">
                            <FormattedMessage id="app.wsmanager.location.detail.seats_available" defaultMessage="Available Seats" />
                        </label>

                        <div className="labelstyle" style={{ width: "100%", marginBottom: "1em" }}>
                            <Label image >
                                <i className="icon-cv19-seat"></i>

                                <Label.Detail >{seatsTotal}</Label.Detail>
                            </Label>
                        </div>


                    </Row>
                    <Row className="mx-0">
                        <label className="_flex">
                            <FormattedMessage id="app.wsmanager.location.detail.seats_bookable" defaultMessage="Bookable Seats" />
                        </label>
                        <div className="labelstyle" style={{ width: "100%", marginBottom: "1em" }}>
                            <Label image>
                                <i className="icon-cv19-seat"></i>

                                <Label.Detail >{seatsAvailable}</Label.Detail>
                            </Label>
                        </div>


                    </Row>
                    {/* <div className="_flex w-100 justify-content-end" style={{ margin: '0 auto' }}>


                        <UIButton
                            className="basic-icon-button mt-2"
                            basic
                            icon='save outline'
                            content={<FormattedMessage id="app.generic.save" defaultMessage="Save" />}
                            loading={props.selectedNode.loading}
                            onClick={(e: any) => save()}
                        >

                        </UIButton>

                    </div> */}
                </div>

                <div className="detail-title">
                    <h2><FormattedMessage id="app.wsmanager.location.detail.subtitle" defaultMessage="Desks Settings" /></h2>
                </div>
                <div className="detail-form justify-content-center" >
                    <FormattedMessage id="app.wsmanager.location.detail.newdesk" defaultMessage="Insert new desk name here">
                        {
                            (placeholder: string) => (
                                <div className="_flex input-container">
                                    <Input elementType="input"
                                        fluid
                                        placeholder={placeholder}
                                        type="text"
                                        containerClass="ui input w-100"
                                        className="my-2 rounded-input w-100 text-center"
                                        changed={(event: any) => { setNewDeskName(event.target.value) }}
                                        value={newDeskName} />
                                    {newDeskName && newDeskName.length > 0 ? <i className="icon-cv19-add input-icon right" onClick={addDesk}></i> : ""}
                                </div>
                            )
                        }
                    </FormattedMessage>
                    {
                        desks && desks.length > 0 ?
                            desks
                                .filter((item: Desk) => {
                                    return !item.deleted
                                })
                                .map((desk: Desk, i: number) => (

                                    <div key={i}>

                                        <div className={`card desk ${!desk.opened ? "closed" : ""}`}>
                                            <div className={`desk-title align-items-center justify-content-between ${desk.available === 0 ? 'disabled' : ''}`} >
                                                <div className="_flex align-items-center h-100 container" onClick={(e: any) => toggleDeskTab(desk, i)} style={{ flex: 1 }}>
                                                    <i className="icon-cv19-levels"></i> <h5>{desk.name}</h5>
                                                </div>
                                                <i className="icon-cv19-close input-icon right" onClick={(e: any) => toggleDeskTab(desk, i, false)}></i>
                                            </div>
                                            <div className="desk-body">

                                                <Row className="mx-0">

                                                    <div className="labelstyle" style={{ width: "100%", marginBottom: "1em" }}>
                                                        <Label style={{ display: "flex", alignItems: "center" }}>
                                                            <FormattedMessage id="app.wsmanager.location.detail.seats_available" defaultMessage="Available Seats" />
                                                            <Label.Detail style={{ textAlign: "right" }}><Input elementType="input"
                                                                fluid
                                                                disabled={desk.available === 0}
                                                                type="number"
                                                                elementConfig={{ onBlur: (e: any) => onDeskUpdate(desk), min: 0 }}
                                                                containerClass="ui border-none"
                                                                className="my-2 rounded-input text-center w-50"
                                                                changed={(event: any) => { onUpdateSeatsInput(event.target.value, i, "seats_total") }}
                                                                value={desk.seats_total}
                                                            /></Label.Detail>
                                                        </Label>
                                                    </div>

                                                    <div className="labelstyle" style={{ width: "100%", marginBottom: "1em" }}>
                                                        <Label style={{ display: "flex", alignItems: "center"}}>
                                                            <FormattedMessage id="app.wsmanager.location.detail.seats_bookable" defaultMessage="Seats" />
                                                            <Label.Detail style={{ display: "flex", alignItems: "center", justifyContent:'center', width: '150px', textAlign: "right" }}> 
                                                            <Input elementType="input"
                                                                fluid
                                                                disabled={desk.available === 0}
                                                                type="number"
                                                                elementConfig={{ onBlur: (e: any) => onDeskUpdate(desk), min: 0, max: desk.seats_total }}
                                                                containerClass="ui border-none"
                                                                className="my-2 rounded-input text-center w-50"
                                                                changed={(event: any) => { onUpdateSeatsInput(event.target.value, i, "seats_available") }}
                                                                value={desk.seats_available} /></Label.Detail>
                                                        </Label>

                                                    </div>

                                                </Row>
                                            </div>
                                            <hr></hr>
                                            <div className="desk-footer">
                                                <div className="_flex w-100 justify-content-end" style={{ margin: '0 auto', flexWrap: 'wrap' }}>
                                                    <UIButton
                                                        className="basic-icon-button"
                                                        basic
                                                        icon={<i className="icon-cv19-print-outline"></i>}
                                                        content={<FormattedMessage id="app.generic.qr" defaultMessage="Print Qr" />}
                                                        loading={isDeskLoading(desk)}
                                                        onClick={(e: any) => onPrintDesk(i)}
                                                    >

                                                    </UIButton>
                                                    <UIButton
                                                        className="basic-icon-button"
                                                        basic
                                                        icon={<i className="icon-cv19-warning"></i>}
                                                        content={desk.available ? <FormattedMessage id="app.generic.disable" defaultMessage="Disable" /> : <FormattedMessage id="app.generic.enable" defaultMessage="Enable" />}
                                                        loading={isDeskLoading(desk)}
                                                        onClick={(e: any) => onDisableEnableDesk(i, desk.available ? 0 : 1)}
                                                    >

                                                    </UIButton>
                                                    <UIButton
                                                        className="basic-icon-button"
                                                        basic
                                                        icon={<i className="icon-cv19-trash-outline"></i>}
                                                        content={<FormattedMessage id="app.generic.delete" defaultMessage="Delete" />}
                                                        loading={isDeskLoading(desk)}
                                                        onClick={(e: any) => onDeleteDesk(i)}
                                                    >

                                                    </UIButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            :
                            ""
                    }
                </div>
                <div className="detail-title">
                    <h2><FormattedMessage id="app.wsmanager.location.detail.subsubtitle" defaultMessage="Location Map" /></h2>
                </div>
                <div className="detail-form justify-content-center" style={{ marginBottom: '5em' }}>
                    <label ><FormattedMessage id="app.wsmanager.location.detail.upload_map_text" defaultMessage="Upload location map (.pdf format)" /></label>
                    <input
                        type="file" id="file_upload"
                        name="file_upload"
                        accept="image/pdf"
                        onChange={readMapFile}
                    />
                    <div className="_flex w-100" style={{ margin: '0 auto' }}>
                        {(props.linkToMap != '') ?
                            <Button
                                btntype="OK green-bg px-4 w-100 rounded-input my-3"
                                clicked={(e: any) => {
                                    //props.getMap(props.selectedNode.guid)
                                    window.open(props.linkToMap)
                                }}
                                loading={props.selectedNode.loading}>
                                <FormattedMessage id="app.generic.download_map" defaultMessage="Get" />
                            </Button>
                        : (null)}
                            <Button
                                btntype="OK green-bg px-4 w-100 rounded-input my-3"
                                clicked={(e: any) => { props.onMapSubmit(props.selectedNode.guid, mapFile, mapFileFormat) }}
                                loading={props.selectedNode.loading}
                                disabled={!mapFile}>
                                <FormattedMessage id="app.generic.submit_map" defaultMessage="Submit" />
                            </Button>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default injectIntl(WsManagerLocationDetail)