import React, { useEffect, useState } from 'react';
import { AppState } from '../../../interfaces/AppState';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getReservations, updateReservation, deleteReservation, getTimetables, getReservation, approveReservations, rejectReservations, changePage, exportData } from '../../../store/actions/backoffice/booking';
import { BaseReservation, Reservation } from '../../../models/Reservation';
import ReservationsTab from '../../../components/backoffice/Tabs/WsManager/Reservations/Reservations';
import { getMotivations, getEntrance } from '../../../store/actions/backoffice/settings';
import { getUsers } from '../../../store/actions/backoffice/users';
import { AddError } from '../../../store/actions/toast';
import { switchTabSuccess } from '../../../store/actions/backoffice/app';

const ReservationsTabContainer = (props: any) => {

    const [perPage, setPerPage ] = useState(10)
    const [csvData, setCsvData] = useState()
    const page: number = 1


    useEffect(() => {   
        console.log("isModerator_web: "+props.isModerator); 
        props.getReservations(props.isModerator, { page: page, per_page: perPage })

    }, [props.isModerator, props.authState?.auth?.selectedCompany])


    const refreshReservations = (perPage : any) => {
        setPerPage(perPage);
        props.getReservations(props.isModerator, { page: page, per_page: perPage })
    } 

    return <ReservationsTab
        loading={props.loading}
        loading_export={props.loading_export}
        page={page}
        perPage={perPage}
        tabChange={props.tabChange}
        updatePerPage={ (perPage: any) => { refreshReservations(perPage);} }
        onGetReservations={(data?: any) => { props.getReservations(props.isModerator, data);} }
        onDeleteReservation={(item: Reservation, data?: any) => props.deleteReservation(item, props.isModerator, data)}
        onUpdateReservation={props.updateReservation}
        onBookingUpdate={(item: Reservation, data?: any) => props.updateReservation(item, props.isModerator, data)}
        authState={props.authState}
        usersState={props.usersState}
        bookingState={props.bookingState}
        settings={props.settings}
        onGetTimetables={props.getTimetables}
        onApproveReservations={(items: any[], data?: any) => props.approveReservations(items, props.isModerator, data)}
        onRejectReservations={(items: any[], data?: any) => props.rejectReservations(items, props.isModerator, data)}
        onGetEntrance={props.getEntrance}
        onGetMotivations={props.getMotivations}
        onGetReservation={() => {
            // props.getReservations(props.isModerator)
        }}
        onGetUsers={props.getUsers}
        isModerator={props.isModerator}
        onExportData={(data?: any) => props.onExportData(data)}
        name={props.name}
    />

}


const mapDispatchToProps = (dispatch: any) => ({
    getReservations: (
        isModerator: boolean,
        data?: any
    ) => { dispatch(getReservations(isModerator, data)); },
    approveReservations: (reservations: Reservation[], isModerator: boolean, data?: any) => { dispatch(approveReservations(reservations, isModerator, data)) },
    rejectReservations: (reservations: Reservation[], isModerator: boolean, data?: any) => { dispatch(rejectReservations(reservations, isModerator, data)) },
    updateReservation: (reservation: Reservation, isModerator: boolean, data?: any) => { dispatch(updateReservation(reservation, isModerator, data)) },
    deleteReservation: (reservation: Reservation, isModerator: boolean, data?: any) => { dispatch(deleteReservation(reservation, isModerator, data)) },
    getMotivations: () => { dispatch(getMotivations()) },
    getEntrance: () => { dispatch(getEntrance()) },
    getReservation: (guid: string) => { dispatch(getReservation(guid)) },
    getUsers: () => { dispatch(getUsers()) },
    getTimetables: (booking_datetime: string, location_guid: string, book_all_day?: boolean) => { dispatch(getTimetables(booking_datetime, location_guid, book_all_day)) },
    onError: (error?: any) => { dispatch(AddError(error)) },
    onExportData: (data?: any) => { dispatch(exportData(data)) },
})

const mapStateToProps = (state: AppState, ownProps: any) => ({
    loading: state.booking.loading,
    authState: state.authReducer,
    bookingState: state.booking,
    usersState: state.users,
    settings: state.settings,
    tabChange: state.app.tabChange,
    isModerator: ownProps.isModerator,
    loading_export: state.booking.loading_export,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReservationsTabContainer))