import Firebase from "firebase";
import { Api, HTTP_STATUS } from "../../api/Api";
import { UserApi } from "../../api/UserApi";
import { AppState } from "../../interfaces/AppState";
import { APP } from "../../interfaces/SwitchState";
import { UserLog } from "../../interfaces/UserLogState";
import { User } from "../../models/User";
import { Common } from "../../utils/Common";
import { UsersLog } from "../../utils/UsersLog";
import {
  LOCAL_STORAGE_AUTH_KEY,
  LOCAL_STORAGE_GOOGLE_REDIRECT
} from "../localStorage";
import { setApp } from "./backoffice/app";
import { getEntrance } from "./backoffice/settings";
import { createUsersLog } from "./backoffice/usersLog";
import { AddError } from "./toast";
import moment from 'moment';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

Firebase.initializeApp(firebaseConfig);
Firebase.analytics();
Firebase.performance()

const GoogleProvider = new Firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: "select_account" });

let calendar_scope_feature = true;
if (process.env.REACT_APP_MOBILE_BOOKING === "true") {
  GoogleProvider.addScope("https://www.googleapis.com/auth/calendar");
}

const MicrosoftProvider = new Firebase.auth.OAuthProvider("microsoft.com");

export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const REQUEST_COMPANIES = "REQUEST_COMPANIES";
export const REQUEST_LOGIN_GOOGLE = "REQUEST_LOGIN_GOOGLE";
export const REQUEST_LOGIN_MICROSOFT = "REQUEST_LOGIN_MICROSOFT";
export const USER_LOGOUT = "USER_LOGOUT";
export const GOT_COMPANIES = "GOT_COMPANIES";
export const SWITCH_COMPANY = "SWITCH_COMPANY";
export const DEBUG_MODE = "DEBUG_MODE";
export const DEBUG_MODE_FLAG = "true";
export const PASSWORD_NEW = "PASSWORD_NEW";
export const PASSWORD_NEW_ENTERED = "PASSWORD_NEW_ENTERED";

let TOKEN_EXPIRATION_LIMIT = 30;

export function loggedIn(auth = {}) {
  return { type: LOGGED_IN, auth };
}

export function loggedOut() {
  return { type: USER_LOGOUT };
}

export function needPasswordNew(err: any) {
  return { type: PASSWORD_NEW, err };
}

export function passwordNewEntered() {
  return { type: PASSWORD_NEW_ENTERED };
}

/**
 * This State usage is mainly spinner preview
 */
export function requestLogin() {
  return { type: 0 };
}
export function requestCompanies() {
  return { type: REQUEST_COMPANIES };
}
export function gotCompanies(auth: any) {
  return { type: GOT_COMPANIES, auth };
}

export function requestLoginGoogle() {
  return { type: REQUEST_LOGIN_GOOGLE };
}

export function requestMicrosoftLogin() {
  return { type: REQUEST_LOGIN_MICROSOFT };
}

/**
 * Login Action Call
 * @param {String} email
 * @param {String} password
 */
export function startLogin(email: any, password: any) {
  //DEBUG VARIABLE SET
  localStorage.setItem(DEBUG_MODE, DEBUG_MODE_FLAG);

  return (dispatch: any, getState: any) => {
    dispatch(requestLogin());

    let unsubscribe = Firebase.auth().onAuthStateChanged((authState) => {
      if (authState) {
        // User is signed in.
        console.log("AUTH STATE", authState);
        if (authState.emailVerified) {
          authState
            .getIdToken(true)
            .then((token: any) => {
              console.log("FIREBASE TOKEN", token);
              // dispatch(loggedIn({}));
              dispatch(checkForToken(token, email, UsersLog.LOGIN_API));
              unsubscribe();
            })
            .catch((tokenErr: any) => {
              console.log("TOKEN ERR", tokenErr);
              dispatch(AddError(tokenErr));
              dispatch(loggedOut());
              unsubscribe();
            });
        } else {
          dispatch(
            AddError({ message: "login.email.notverified" }, true, true)
          );
          authState.sendEmailVerification();
          dispatch(loggedOut());
          unsubscribe();
        }
      } else {
        // No user is signed in.
      }
    });

    Firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .then((signInRes: any) => {
        console.log("LOGGEDIN", signInRes);
      })
      .finally(() => {
        dispatch(uploadConfigurations())
      })
      .catch((signinErr: any) => {
        console.log("FIREBASE signinErr", signinErr);
        dispatch(AddError(signinErr));
        dispatch(loggedOut());

        UserLogFailed(email, signinErr.message);

        // if (signinErr.code === FirebaseUtils.ERRORS.USER_NOT_FOUND) {
        //   Firebase.auth()
        //     .createUserWithEmailAndPassword(email, password)
        //     .then((signupRes: any) => {
        //       console.log("LOGGEDIN", signupRes);
        //     })
        //     .catch((signupErr: any) => {
        //       console.log("FIREBASE signupErr", signupErr);
        //       dispatch(AddError(signupErr));
        //       dispatch(loggedOut());
        //     });
        // } else {

        // }
        // dispatch(startLogout());
      });

    // return Api.Post("/login", { email: email, password: password })
    //   .then((response) => {
    //     console.log("LOGIN", response);
    //     if (response.status === HTTP_STATUS.OK) {
    //       return response.json();
    //     } else {
    //       return response
    //         .json()
    //         .then((res: any) => {
    //           console.log("LOG", res);
    //           throw new Error(res.Error);
    //         })
    //         .catch((err: any) => {
    //           throw new Error(err);
    //         });
    //       // localStorage.removeItem("auth");
    //       // dispatch(loggedOut());
    //       // response.json().then((msg: any) => dispatch(AddError({message:"error"})));
    //     }
    //   })
    //   .then((res: any) => {
    //     if (res !== null) {
    //       console.log(res);
    //       localStorage.setItem("auth", JSON.stringify(res));
    //       UserApi.GetMe()
    //         .then((data: any) => {
    //           if (data.status === HTTP_STATUS.OK) {
    //             return data.json();
    //           } else {
    //             let unauthorized = false;
    //             let error = "";
    //             return Api.IsAuthorized(data)
    //               .then((text: any) => {
    //                 error = text;
    //               })
    //               .catch((err: string) => {
    //                 error = err;
    //                 unauthorized = true;
    //               })
    //               .finally(() => {
    //                 if (unauthorized) {
    //                   dispatch(startLogout());
    //                 }
    //                 throw new Error(error);
    //               });
    //           }
    //         })
    //         .then((data) => {
    //           user = new User(data).set(res);
    //           user.email = email;
    //           user.password = password;
    //           dispatch(loggedIn(user));
    //           localStorage.setItem("auth", JSON.stringify(user));
    //         })
    //         .catch((dataErr) => {
    //           dispatch(AddError(dataErr));
    //           dispatch(loggedOut());
    //           // dispatch(loggedOut());
    //         });
    //       // dispatch(loggedIn(res));
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     dispatch(AddError(err));
    //     dispatch(loggedOut());
    //   });
  };
}

export const UserLogFailed = (email?: string, description?: string) => {
  //SetUp UserLog Variable
  let userLog = new UserLog({
    action_guid: UsersLog.AUTH_LOGIN_FAILED,
    fe_view_guid: UsersLog.LOGIN_PAGE_VIEW,
    be_api_guid: UsersLog.LOGIN_API,
    description: description ? description : "Login Failed",
    email: email ? email : "",
  });

  createUsersLog(userLog);
};

export const googleLogin = () => {
  //DEBUG VARIABLE SET
  localStorage.setItem(DEBUG_MODE, DEBUG_MODE_FLAG);
  let email: string;

  return (dispatch: any, getState: any) => {
    dispatch(requestLoginGoogle());

    if (Common.IsMobile()) {
      localStorage.setItem(LOCAL_STORAGE_GOOGLE_REDIRECT, "true");
      Firebase.auth()
        .signInWithRedirect(GoogleProvider)
        .finally(() => {
          dispatch(uploadConfigurations())
        });
    } else {
      Firebase.auth()
        .signInWithPopup(GoogleProvider)
        .then((googleSignIn: any) => {
          console.log(
            "GOOGLE SIGNIN",
            googleSignIn,
            googleSignIn.additionalUserInfo,
            googleSignIn.user
          );
          if(process.env.REACT_APP_MOBILE_BOOKING === "true" ){
            localStorage.setItem(
              "oauth2",
              JSON.stringify({
                oauth2_token: googleSignIn.credential.accessToken,
              })
            );
          }


          checkForGoogleUser(googleSignIn, dispatch);
        })
        .finally(() => {
          dispatch(uploadConfigurations())
        })
        .catch((googleSignInErr: any) => {
          console.log("GOOGLE SIGN IN ERR", googleSignInErr);
          dispatch(AddError(googleSignInErr));
          // dispatch(loggedOut());
          dispatch(startLogout());
          window.location.reload();
        });
    }
  };
};

export const checkForRedirectLogin = () => {
  return (dispatch: any, getState: any) => {
    dispatch(requestLoginGoogle());
    Firebase.auth()
      .getRedirectResult()
      .then(function (googleSignIn: any) {
        //console.log("MOBILE GOOGLESIGNIN: ", googleSignIn)
        if(process.env.REACT_APP_MOBILE_BOOKING === "true" ){
          localStorage.setItem(
            "oauth2",
            JSON.stringify({
              oauth2_token: googleSignIn.credential.accessToken,
            })
          );
        }
        checkForGoogleUser(googleSignIn, dispatch, false);
      })
      .catch((googleSignInErr: any) => {
        console.log("GOOGLE SIGN IN ERR", googleSignInErr);
        dispatch(AddError(googleSignInErr));
        // dispatch(loggedOut());
        dispatch(startLogout());
        window.location.reload();
      });
  };
};

export const microsoftLogin = (tenantId: any) => {
  MicrosoftProvider.setCustomParameters({
    prompt: "select_account",
    // Optional "tenant" parameter in case you are using an Azure AD tenant.
    // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
    // or "common" for tenant-independent tokens.
    // The default value is "common".
    tenant: tenantId,
  });

  return (dispatch: any, getState: any) => {
    dispatch(requestMicrosoftLogin());
    Firebase.auth()
      .signInWithPopup(MicrosoftProvider)
      .then((microsoftSignIn) => {
        console.log(
          "MICROSOFT SIGNIN",
          microsoftSignIn,
          microsoftSignIn.additionalUserInfo,
          microsoftSignIn.user
        );
        if (microsoftSignIn.user) {
          microsoftSignIn.user
            .getIdToken(true)
            .then((token: any) => {
              console.log("FIREBASE MS TOKEN", token);
              let email: any = "";
              email = microsoftSignIn.user?.email;

              if (email) {
                dispatch(
                  checkForToken(token, email, UsersLog.LOGIN_MICROSOFT_API)
                );
              } else {
                throw new Error();
              }
            })
            .catch((tokenErr: any) => {
              console.log("FIREBASE MS TOKEN ERR", tokenErr);
              dispatch(AddError(tokenErr));
              dispatch(startLogout());
            });
        } else {
          throw new Error();
        }
      })
      .finally(() => {
        dispatch(uploadConfigurations())
      })
      .catch((err) => {
        console.log("FIREBASE MS SIGN IN ERR", err);

        if (err.code === 'auth/account-exists-with-different-credential') {
          dispatch(needPasswordNew(err))

        }
      });
  };
};

export const microsoftLoginEnterPasswordNew = (err: any, password: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(passwordNewEntered());

    var pendingCred = err.credential;
    var email = err.email;
    var existingEmail = null;
    var pendingCred = null;

    Firebase.auth().fetchSignInMethodsForEmail(email).then(methods => {
      existingEmail = err.email;
      pendingCred = err.credential;

      if (methods[0] === 'password') {
        var provider = new Firebase.auth.EmailAuthProvider();

        Firebase.auth().signInWithEmailAndPassword(email, password).then((result: any) => {

          //Firebase.auth().signInWithPopup(provider).then((result: any) => {

          return result.user.linkWithCredential(pendingCred);
        }).then((microsoftSignIn) => {

          let email: any = "";
          email = microsoftSignIn.user?.email;

          if (email) {

            if(process.env.REACT_APP_MOBILE_BOOKING === "true" ){
              localStorage.setItem(
                "oauth2",
                JSON.stringify({
                  oauth2_token: microsoftSignIn.credential?.accessToken,
                })
              );
            }


            checkForGoogleUser(microsoftSignIn, dispatch);

          } else {
            throw new Error();
          }
        });
        return;
      }

    });
  }
}

export const checkForGoogleUser = (
  googleSignIn: any,
  dispatch: any,
  avoidThrowingNullUserError?: boolean
) => {
  if (googleSignIn.user) {
    googleSignIn.user
      .getIdToken(true)
      .then((token: any) => {
        console.log("FIREBASE TOKEN", token);
        let email: any = "";
        email = googleSignIn.user?.email;

        if (email) {
          dispatch(checkForToken(token, email, UsersLog.LOGIN_FIREBASE_API));
        } else {
          throw new Error();
        }
      })
      .catch((tokenErr: any) => {
        console.log("TOKEN ERR", tokenErr);
        dispatch(AddError(tokenErr));
        dispatch(startLogout());
      });
  } else {
    if (!avoidThrowingNullUserError) {
      throw new Error();
    }
  }
};

export const checkForToken = (
  token: string,
  email: string,
  loginType: String
) => {

  //SetUp UserLog Variable
  let userLog = new UserLog({
    action_guid: UsersLog.AUTH_LOGIN,
    fe_view_guid: UsersLog.LOGIN_PAGE_VIEW,
    be_api_guid: loginType,
    description: "Login",
    email: email,
  });

  let mobileView = Common.IsMobile();
  return (dispatch: any, getState: any) => {
    let user: User;
    if (token) {
      localStorage.setItem(
        "auth",
        JSON.stringify({
          access_token: token,
        })
      );
      UserApi.GetMe(mobileView)
        .then((data: any) => {
          if (data.status === HTTP_STATUS.OK) {
            return data.json();
          } else {
            let unauthorized = false;
            let error = "";
            return Api.IsAuthorized(data)
              .then((text: any) => {
                error = text;
              })
              .catch((err: string) => {
                error = err;
                unauthorized = true;
              })
              .finally(() => {
                if (unauthorized) {
                  dispatch(startLogout());
                }
                throw new Error(error);
              });
          }
        })
        .then((data) => {
          user = new User(data);
          user.access_token = token;
          user.email = email;
          let auth = JSON.stringify(user);
          localStorage.setItem("auth", JSON.stringify(user));
          // user.password = password ? password : "";
          let app = APP.COVID19;
          localStorage.setItem("appPermission", "COVID19");
          for (var i in user.roles) {
            if (user.roles[i].app === APP.WSMANAGER) {
              app = APP.WSMANAGER;
              localStorage.setItem("appPermission", "ALL");
              break;
            }
          }
          console.log("[APP SWITCHED ON] ", app);
          dispatch(setApp(app));
          dispatch(loggedIn(user));

          //createUserLog
          createUsersLog(userLog);
        })
        .finally(() => {
          // Get Configurations at Login
          dispatch(getEntrance());
        })
        .catch((dataErr) => {
          dispatch(AddError(dataErr));
          dispatch(startLogout());

          userLog.action_guid = UsersLog.AUTH_LOGIN_FAILED;
          userLog.description = "Login Failed";
          createUsersLog(userLog);
          // dispatch(loggedOut());
          // dispatch(loggedIn(user));
        });
    } else {
    }
  };
};

/**
 * Logout action call
 */
export function getCompanies() {
  return (dispatch: any, getState: any) => {
    let user: User = new User((getState() as AppState).authReducer.auth);
    dispatch(requestCompanies());
    UserApi.GetCompanies()
      .then((data: any) => {
        if (data.status === HTTP_STATUS.OK) {
          return data.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(data)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((data) => {
        console.log("GET COMPANIES", data);
        user.companies = data;
        localStorage.setItem("auth", JSON.stringify(user));
        dispatch(gotCompanies(user));
      })
      .catch((dataErr) => {
        dispatch(AddError(dataErr));
      });
  };
}

export const switchCompany = (company: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(requestCompanies());
    let user: User = new User((getState() as AppState).authReducer.auth);
    user.selectedCompany = company;
    localStorage.setItem("auth", JSON.stringify(user));
    dispatch(gotCompanies(user));
  };
};

/**
 * Logout action call
 */
export function startLogout(email?: string) {
  //SetUp UserLog Variable
  let userLog = new UserLog({
    action_guid: UsersLog.AUTH_LOGOUT,
    fe_view_guid: UsersLog.HEADER_TAB_VIEW,
    be_api_guid: UsersLog.LOGOUT_API,
    description: "Logout",
    email: email ? email : "",
  });

  return (dispatch: any, getState: any) => {
    console.log("logged out");
    dispatch(requestLogin());

    Firebase.auth()
      .signOut()
      .finally(() => {
        createUsersLog(userLog);

        localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        dispatch(loggedOut());
      });

    // return Api.Post("/logout", {})
    //   .then((response) => {
    //     localStorage.removeItem("auth");
    //     dispatch(loggedOut());
    //     //  dispatch ({type: 'USER_LOGOUT'});
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     localStorage.removeItem("auth");
    //     dispatch(loggedOut());
    //     //  dispatch ({type: 'USER_LOGOUT'});
    //   });
  };
}

export function uploadConfigurations() {
  return (dispatch: any, getState: any) => {
    let state = getState();
    //console.log("[++++ Redux state ++++] ", state)
    TOKEN_EXPIRATION_LIMIT = state.settings?.entrance?.token_expiration_limit;
  };
}

export async function isTokenAlive() {
  const _MS_PER_MIN = 1000 * 60;
  //const _MS_PER_HOUR = 1000 * 60 * 60;
  //const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  let diff: number = 0;
  let alive: boolean = true;

  /***  OLD TOKEN ***/
  await Firebase.auth()
    .currentUser?.getIdTokenResult()
    .then((idTokenResult: any) => {
      console.log('[OLD][----- User JWT -----]', idTokenResult.token);
      console.log('[OLD][----- JWT Signed In Time -----]', idTokenResult.authTime);
      console.log('[OLD][----- JWT Issued at Time -----]', idTokenResult.issuedAtTime);
      console.log('[OLD][----- JWT Expiration Time -----]', idTokenResult.expirationTime);

      let signInDateTime = moment(moment(idTokenResult.authTime).format('YYYY-MM-DD HH:mm')).toDate();
      let expirationDateTime = moment(moment(idTokenResult.expirationTime).format('YYYY-MM-DD HH:mm')).toDate();
      let currentDateTime = moment(moment().format('YYYY-MM-DD HH:mm')).toDate();
      console.log('[OLD][----- signInDateTime -----]', signInDateTime);
      console.log('[OLD][----- expirationDateTime -----]', expirationDateTime);
      console.log('[OLD][----- currentDateTime -----]', currentDateTime);
      console.log('[OLD] TOKEN_EXPIRATION_LIMIT: ',TOKEN_EXPIRATION_LIMIT)
      diff =
        (currentDateTime.getTime() - signInDateTime.getTime()) / _MS_PER_MIN;
      console.log('[OLD][----- Timestamps Diff -----]', diff);
    })
    .finally(() => {
      if (diff > TOKEN_EXPIRATION_LIMIT) {
        alive = false;
      }
    })
    .catch((error: any) => {
      console.log("[----- Alive Token Error -----] ", error);
    });

  /***  NEW TOKEN ***/
  Firebase.auth()
    .currentUser?.getIdTokenResult(true)
    .then((idTokenResult: any) => {
      let auth: any = localStorage.getItem("auth");
      if (auth) {
        auth = JSON.parse(auth);
        auth.access_token = idTokenResult.token;
      }
      localStorage.setItem("auth", JSON.stringify(auth));
    })
    .catch((error: any) => {
      console.log("[----- Alive Token Error -----] ", error);
    });

  return alive;
}


