import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import {connect} from 'react-redux';
import { AppState } from '../../../interfaces/AppState';
import BookingTab from '../../../components/tracker/Card/NewReservation/NewReservation_v1';
import { getMotivations} from '../../../store/actions/backoffice/settings';
import { getLocations } from '../../../store/actions/backoffice/location';
import { getUsers } from '../../../store/actions/backoffice/users';
import { getAvailableLocations, getParentLocation } from '../../../store/actions/backoffice/locationV2';
import { getReservations, getTimetables, onBooking } from '../../../store/actions/backoffice/booking';
import { BaseReservation } from '../../../models/Reservation';

const BookingTabContainer = (props: any) => {
   
    useEffect(() => { 
        props.getLocationsList(props.adminView)
        props.getMotivationsList()
        //props.getUsers()

    }, [props.adminView, props.authState?.auth?.selectedCompany])


    return(
        <div className="h-100">
            
            <BookingTab 
                reasonList={props.reasonList}
                locationsList={props.locationList}
                usersState={props.usersState}
                onGetUsers={props.getUsers}
                auth={props.auth}
                loading={props.loading}
                getAvailableLocations={props.getAvailableLocations}
                listLocationOptions={props.listLocation }
                onBooking={props.onBooking}
                getParentLocation={props.getParentLocation}
                listParentLocation={props.listParentLocation}
                bookingState = {props.bookingState}
            />
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    getLocationsList: () => {dispatch(getLocations(false))},
    getMotivationsList: () => { dispatch(getMotivations()) },
    getUsers: (data?: any) => { dispatch(getUsers(data)) },
    getAvailableLocations: (guid: any, date: any, seats_amount: number) => dispatch(getAvailableLocations(guid, date, seats_amount)),
    onBooking: (reservation: BaseReservation, email_array: any, motivation: string, location: string) => { dispatch(onBooking(reservation, email_array, motivation, location)) },
    getParentLocation: (disabled: boolean, companies: any[]) => dispatch(getParentLocation(disabled, companies)),
    getReservations: () => { dispatch(getReservations()); },
})

const mapStateToProps = (state: AppState, ownProps: any) => ({
    reasonList: state.settings.motivations,
    locationList: state.location.list,
    usersState: state.users,
    auth: state.authReducer.auth,
    loading: state.location.loading,
    listLocation: state.locationV2.listLocation,
    listParentLocation: state.locationV2.listParentLocation,
    bookingState: state.booking,
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BookingTabContainer))