import { LocationApi } from "../../../api/LocationsApi";
import { AddError, AddSuccess } from "../toast";
import { LocationAction } from "../../../models/actions/LocationAction";
import { HTTP_STATUS, Api } from "../../../api/Api";
import { AppState } from "../../../interfaces/AppState";
import { startLogout } from "../auth";
import { AuthState } from "../../../interfaces/AuthState";
import { requestFailed } from "./location"
//per v2
export const REQUEST_AVAILABLE_LOCATION = "REQUEST_AVAILABLE_LOCATION";
export const REQUEST_PARENT_LOCATION = "REQUEST_PARENT_LOCATION";
export const REQUEST_MAP_LOCATION = "REQUEST_MAP_LOCATION";
export const GET_AVAILABLE_LOCATION = "GET_AVAILABLE_LOCATION";
export const GET_PARENT_LOCATION = "GET_PARENT_LOCATION";
export const GET_MAP_LOCATION = "GET_MAP_LOCATION";

export const requestAvailableLocations = () => {
  return { type: REQUEST_AVAILABLE_LOCATION };
};

export const availableLocationsSuccess = (res = {}) => {
  return { type: GET_AVAILABLE_LOCATION, res };
};


export const requestParentLocation = () => {
  return { type: REQUEST_PARENT_LOCATION };
};

export const availableParentLocation = (res = {}) => {
  return { type: GET_PARENT_LOCATION, res };
};

export const requestMapLocations = () => {
  return { type: REQUEST_MAP_LOCATION };
};

export const mapLocationSuccess = (res = {}) => {
  return { type: GET_MAP_LOCATION, res };
};;


export function getParentLocation(disabled: boolean, companies: any[]) {
  let params = {
    "disabled": disabled,
    "companies": companies
  }
  /*
    {"disabled":1,
    "companies":[
        "16fd2706-8baf-433b-82eb-8c7fad"
    ]}
    
  */

  return (dispatch: any, getState: any) => {
    let authState: AuthState = (getState() as AppState).authReducer;

    dispatch(requestAvailableLocations());

    return LocationApi.GetParentLocation(params)
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          if (localStorage.getItem("DEBUG_MODE") == "true") {
            console.log(res);
          }

          dispatch(availableParentLocation(res));
        }
      })
      .catch((err: any) => {
        console.log(err);
        if(err.toString().includes("access_token")){
          dispatch(startLogout())
        }
        dispatch(requestFailed());
        dispatch(AddError(err));
      });
  };


}



// per v2 
export function getAvailableLocations(guid: any, date: any, seats_amount: number) {
  let params = {
    "Guid": guid,
    "date": date,
    "seats_amount": seats_amount
  }

  return (dispatch: any, getState: any) => {
    let authState: AuthState = (getState() as AppState).authReducer;

    dispatch(requestAvailableLocations());

    return LocationApi.GetAvailableLocations(params)
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          if (localStorage.getItem("DEBUG_MODE") == "true") {
            console.log(res);
          }

          dispatch(availableLocationsSuccess(res));
        }
      })
      .catch((err: any) => {
        console.log(err);
        if(err.toString().includes("access_token")){
          dispatch(startLogout())
        }
        dispatch(requestFailed());
        dispatch(AddError(err));
      });
  };


}