import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Label, Select, Header, Image, Modal, List, Checkbox, Loader, Input as UInput, Tab, Button as UIButton, Dimmer, Popup } from 'semantic-ui-react';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { Dropdown } from 'primereact/dropdown';
import Button from '../../../UI/Button/Button';
import { IntlContext } from '../../../../utils/IntlContext';
import moment from 'moment';
import { BaseReservation, Reservation } from '../../../../models/Reservation';
import _, { wrap } from 'lodash'
import { Common } from '../../../../utils/Common';
import { User } from '../../../../models/User';

import "./NewReservation_v1.scss";
import { getLocations } from '../../../../store/actions/backoffice/location';
import { locationsAreEqual } from 'history';
import { createGoogleCalendarEvent } from '../../../../utils/Calendar';


const NewReservationV1 = (props: any) => {

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    const [authGuid, setAuthGuid] = useState("");
    const [includeMyself, setIncludeMySelf] = useState(true);
    const [booker, setBooker] = useState<User | any>()
    const [date, setDate] = useState();
    const [open, setOpen] = React.useState(false);
    const { switchToLanguage, locale, calendar } = React.useContext(IntlContext);
    const [startDate, setStartDate] = useState<any>(tomorrow);
    const [formattedStartDate, setFormattedStartDate] = useState<any>(tomorrow);
    const [timeModalOpened, setTimeModalOpened] = useState(false);
    const [hour, setHour] = useState<any>();
    const [invalidDates, setInvalidDates] = useState<any>([]);
    const [endDate, setEndDate] = useState<any>(tomorrow);
    const [slot, setSlot] = useState<any>();

    const [officeSelected, setOfficeSelected] = useState<any>('');
    const [officeSelectedGuid, setOfficeSelectedGuid] = useState<any>('');

    const [officeOptions, setOfficeOptions] = useState<any>([]);

    const [motivationSelected, setMotivationSelected] = useState<any>('');
    const [motivationSelectedGuid, setMotivationSelectedGuid] = useState<any>('');
    const [motivationOptions, setMotivationOptions] = useState<any>([]);
    const [showMotivation, setShowMotivation] = useState(false);

    const [numberOfPerson, setNumberOfPerson] = useState(1);
    const [showNumberOfPerson, setShowNumberOfPerson] = useState(false);

    const [showLocation, setShowLocation] = useState(false);
    const [locationSelected, setLocationSelected] = useState<any>('');
    const [locationOptions, setLocationOptions] = useState<any>([]);

    const [showDesk, setShowDesk] = useState(false);
    const [deskSelected, setDeskSelected] = useState<any>('');
    const [deskOptions, setDeskOptions] = useState<any>([]);

    const [bookAllDay, setBookAllDay] = useState(true);

    const [userSearch, setUserSearch] = useState<any>();
    const [suggested, setSuggested] = useState<any>();
    const [owners, setOwners] = useState<any>([]);
    const [userSuggestions, setUserSuggestions] = useState<any>([]);
    const [_me, setMe] = useState<User | any>();

    const [showTags, setShowTags] = useState(false);
    const [tags, setTags] = useState<any>([]);

    const [linkToMapLocation, setLinkToMapLocation] = useState('');
    const [linkToMapOffice, setLinkToMapOffice] = useState('');

    const [alertBooking, setAlertBooking] = useState(false);

    const [reservation, setReservation] = useState<any>(
        new BaseReservation()
    );
    const [slots, setSlots] = useState<any>([])
    const [seatsAvailable, setSeatsAvailable] = useState(0)

    const [showBookButton, setShowBookButton] = useState(false);

    const [showModalTags, setShowModalTags] = useState(false);

    const [availableLocationsUpdated, setAvailableLocationsUpdated] = useState(false);

    const [openModalReviewBooking, setOpenModalReviewBooking] = useState(false);

    useEffect(() => {
        setStartDate(startDate);
        setOfficeOptions(props.listParentLocation);
        setLocationOptions([])
        setShowLocation(false)
        console.log("CITY_OPTIONS: ", officeOptions);
        setMotivationOptions(props.reasonList);
        console.log("MOTIVATION_OPTIONS: ", motivationOptions);
        console.log("USERS_LIST", props.usersState.items);
        setUserSuggestions(props.usersState.items)
        console.log("AUTH: " + props.auth.guid);
        setAuthGuid(props.auth.guid)
    }, [props.locationsList, props.reasonList, props.usersState, props.auth, props.listParentLocation])

    useEffect(() => {
        //props.getTimetables
        console.log("props.bookingState ", props.bookingState)
        console.log("props.bookingState.bookingSuccess ", props.bookingState.bookingSuccess)

        setSlots(props.bookingState?.slots)

    }, [props.bookingState])


    useEffect(() => {
        console.log("show state ----- ", showMotivation,
            showNumberOfPerson,
            showLocation,
            showDesk,
            showTags,
            officeSelected,
            motivationSelected,
            numberOfPerson,
            locationSelected,
            deskSelected,
            userSearch
        )
    }, [])

    useEffect(() => {

        if (officeSelected !== '' && numberOfPerson === 0 && locationSelected === '' && deskSelected === '' && motivationSelected === '') {
            setShowNumberOfPerson(true)
            setShowLocation(false)
            setShowDesk(false)
            setShowMotivation(false)
            setShowTags(false)
            setShowBookButton(false)
        } else if (officeSelected !== '' && numberOfPerson > 0 && locationSelected === '' && deskSelected === '' && motivationSelected === '') {
            setShowNumberOfPerson(true)
            //setShowLocation(true)
            setShowDesk(false)
            setShowMotivation(false)
            setShowTags(false)
            setShowBookButton(false)
        } else if (officeSelected !== '' && numberOfPerson > 0 && locationSelected !== '' && deskSelected === '' && motivationSelected === '') {
            setShowNumberOfPerson(true)
            //setShowLocation(true)
            setShowDesk(true)
            setShowMotivation(false)
            setShowTags(false)
            setShowBookButton(false)
        } else if (officeSelected !== '' && numberOfPerson > 0 && locationSelected !== '' && deskSelected !== '' && motivationSelected === '') {
            setShowNumberOfPerson(true)
            //setShowLocation(true)
            setShowDesk(true)
            setShowMotivation(true)
            setShowTags(false)
            setShowBookButton(false)
        }
        else if (officeSelected !== '' && numberOfPerson > 0 && locationSelected !== '' && deskSelected !== '' && motivationSelected !== '') {
            setShowNumberOfPerson(true)
            //setShowLocation(true)
            setShowDesk(true)
            setShowMotivation(true)
            setShowTags(true)
            setShowBookButton(true)
        }
        else if (officeSelected === '') {
            setShowNumberOfPerson(false)
            setShowLocation(false)
            setShowDesk(false)
            setShowMotivation(false)
            setShowTags(false)
            setShowBookButton(false)
        }
        else if (numberOfPerson === 0) {
            setShowNumberOfPerson(true)
            setShowLocation(false)
            setShowDesk(false)
            setShowMotivation(false)
            setShowTags(false)
            setShowBookButton(false)
        }
        else if (locationSelected === '') {
            setShowNumberOfPerson(true)
            setShowLocation(true)
            setShowDesk(false)
            setShowMotivation(false)
            setShowTags(false)
            setShowBookButton(false)
        }
        else if (deskSelected === '') {
            setShowNumberOfPerson(true)
            setShowLocation(true)
            setShowDesk(true)
            setShowMotivation(false)
            setShowTags(false)
            setShowBookButton(false)
        }
        else if (motivationSelected === '') {
            setShowNumberOfPerson(true)
            setShowLocation(true)
            setShowDesk(true)
            setShowMotivation(true)
            setShowTags(false)
            setShowBookButton(false)
        }

    }, [officeSelected, motivationSelected, numberOfPerson, locationSelected, deskSelected])


    useEffect(() => {
        let disabled = 0
        let companies: any = []
        companies.push(props.auth.selectedCompany)
        props.getParentLocation(disabled, companies)
    }, [])

    useEffect(() => {
        if (locationSelected !== undefined && locationSelected.guid !== undefined) {
            console.log("LOCATION SELECTED GUID: " + locationSelected.guid)
            console.log("LOCATION SELECTED MAP_LINK: " + locationSelected.map_file_name)
            if(locationSelected.map_file_name !== undefined && locationSelected.map_file_name !== null){
                setLinkToMapLocation(locationSelected.map_file_name)
            }else{
                setLinkToMapLocation('')
            }
        }
        
    }, [locationSelected])

    useEffect(() => {
        console.log("OFFICE_SELECTED_GUID: " + officeSelectedGuid);
        if (officeSelected !== undefined && officeSelected.guid !== undefined) {
            if(officeSelected.map_file_name !== undefined && officeSelected.map_file_name !== null){
                setLinkToMapOffice(officeSelected.map_file_name)
            }else{
                setLinkToMapOffice('')
            }
        }
        //let startDate_tmp: any = moment(startDate).format("DD-MM-YYYY HH:mm:ss");
        let startDate_tmp: any = moment(startDate).set('hour', 9).format("DD-MM-YYYY HH:mm:ss")
        setFormattedStartDate(startDate_tmp);
        console.log("START_DATE: " + startDate_tmp);
        console.log("NUMBER_OF_PERSON: " + numberOfPerson);
        if (officeSelected !== '' && numberOfPerson > 0 && officeSelectedGuid !== '') {
            props.getAvailableLocations(officeSelectedGuid, formattedStartDate, numberOfPerson)
        }

    }, [officeSelected, officeSelectedGuid, numberOfPerson])

    useEffect(() => {
        console.log("startDate ", startDate, " moment ", moment(startDate).set('hour', 9).format("DD-MM-YYYY HH:mm:ss"))

    }, [startDate])

    useEffect(() => {
        console.log("props.listLocationOptions ", props.listLocationOptions)
        if (props.listLocationOptions !== undefined) {
            if(props.listLocationOptions.length > 0){
                setShowLocation(true)
                setLocationOptions(props.listLocationOptions)
            }else{
                setShowLocation(false)
            }
        }

    }, [props.listLocationOptions])

    const openTimeModal = () => {
        //debugger
        setTimeModalOpened(true)
        setOpen(true)
        let string = moment(startDate, 'yyyy-MM-DD').format()
        //props.onGetTimetables(string.split('T')[0])
    }

    const dateChanged = (e: any) => {
        console.log("INVALID DATES", invalidDates)
        console.log("date changed e", e)
        setStartDate(e.value);
        setEndDate(e.value);
        let startDate_tmp: any = moment(e.value).format("DD-MM-YYYY HH:mm:ss");
        setFormattedStartDate(startDate_tmp);
        console.log("date formatted", formattedStartDate);

        getDailyTimetables(e.value)
        setHour(undefined)
        setSlot(undefined)

        setShowDesk(false)
        setShowMotivation(false)
        setShowTags(false)
        setShowBookButton(false)
        setLocationSelected('')
        setDeskSelected('')
        setLinkToMapLocation('')
        setLinkToMapOffice('')
    }



    const getDailyTimetables = (date?: any) => {
        let tmp = date ? date : startDate

        if (props.settings?.entrance.book_all_day || reservation.book_all_day) {
            let string = moment(tmp, 'yyyy-MM-DD').format()
            props.onGetTimetables(string.split('T')[0], reservation.location_guid, reservation.book_all_day)
            return true
        }

        return false
    }

    const onSelectedTime = () => {
        setOpen(false)
    }

    const onSelectChange = (e: any) => {
        if (e && e.value) {
            setUserSearch(e.value)
            setSuggested(e.value)
        } else {
            setUserSearch("")
            setSuggested("")
        }
    }

    const onSuggestionSelection = (e: any) => {
        console.log("USER_SELECTED: " + JSON.stringify(e.value), " props.auth --->", props.auth)
        let tags_tmp = tags;
        let tags_length = tags_tmp.length + 1;
        if (includeMyself) {
            tags_length = tags_length + 1;
        }
        if (tags_length <= numberOfPerson) {
            tags_tmp.push(e.value);
        } else {
            console.log("[NewReservationV1] Il numero di tag ha superato il numero di partecipanti");
            setShowModalTags(true);
        }
        setTags(tags_tmp);
        console.log("TAG_SAVED: " + JSON.stringify(tags[0]))
        setUserSearch('')
    }

    const suggestUsers = (event: any) => {
        if (event.query.length > 0) {
            props.onGetUsers({ page: 1, per_page: 10, filters: { fullname: { value: event.query } } })
        }
    }

    function itemTemplate(item: any) {
        return (
            <div >
                <h6>{item.fullname}</h6>
                <p>{item.email}</p>
            </div>
        );
    }

    const onSaveDate = () => {

    }

    const onCancelDate = () => {

    }

    const selectSlot = () => {
        if (slot) {
            setHour(slot.hour)
            setTimeModalOpened(false)
        } else {
            // props.onError()
        }

    }

    const cancelSlotSelection = () => {
        setSlot(undefined)
        setTimeModalOpened(false)
        setInvalidDates([])
    }

    const isDeskBusy = (slot: any) => {
        return seatsAvailable - slot.seats_busy <= 0
    }

    const onOfficeSelected = (e: any) => {
        console.log("OFFICE_SELECTED: ", e.value.name, e.value.guid)
        setOfficeSelected(e.value);
        setOfficeSelectedGuid(e.value.guid);

        setShowLocation(false)
        setShowDesk(false)
        setShowMotivation(false)
        setShowTags(false)
        setShowBookButton(false)

        setNumberOfPerson(1)
        setLocationSelected('')
        setDeskSelected('')
        setLinkToMapLocation('')
        setLinkToMapOffice('')

    }

    const onMotivationSelected = (e: any) => {
        console.log("MOTIVATION_SELECTED: ", e.value)
        setMotivationSelected(e.value);
        setMotivationSelectedGuid(e.value.guid)
    }

    const onNumberOfPersonSelected = (e: any) => {
        console.log("NUMBER_OF_PERSON: ", e.value)
        if (e.value < 200 && e.value > 0) {
            setNumberOfPerson(e.value);
        }
        setLocationSelected('')
        setDeskSelected('')
        setLinkToMapLocation('')
    }

    const onLocationSelected = (e: any) => {
        console.log("LOCATION_SELECTED: ", e.value)
        setLocationSelected(e.value);
        setDeskOptions(e.value.desks_properties);

        setShowDesk(false)
        setShowMotivation(false)
        setShowTags(false)
        setShowBookButton(false)

        setDeskSelected('')

    }

    const onDeskSelected = (e: any) => {
        console.log("DESK_SELECTED: ", e.value)
        setDeskSelected(e.value);


    }

    const clearTags = () => {
        setTags([])
    }

    const removeTag = (tag: any) => {

        console.log("TAGS: " + JSON.stringify(tags));

        let tags_tmp: any = [...tags];

        for (let i = 0; i < tags_tmp.length; i++) {
            if (tags_tmp[i].guid === tag.guid) {
                tags_tmp.splice(i, 1)
                break;
            }
        }

        console.log("TAGS new: " + tags_tmp);

        if (tags_tmp.length === 0) {
            clearTags()
        } else {
            setTags(tags_tmp);
        }
    }

    useEffect(() => {
        console.log("reservation ", reservation)
    }, [reservation])

    const onClickNewReservation = () => {
        if (includeMyself || tags.length > 0) {
            setOpenModalReviewBooking(true)
        } else {
            setAlertBooking(true)
        }
    }

    const confirmOnClickNewReservation = () => {
        console.log("STATO PRENOTAZIONE: ",
            "startDate", startDate,
            "officeSelected", officeSelected,
            "officeSelectedGuid", officeSelectedGuid,
            "motivationSelected", motivationSelected,
            "numberOfPerson", numberOfPerson,
            "locationSelected ", locationSelected,
            "deskSelected", deskSelected,
            "authGuid", authGuid,
            "includeMyself", includeMyself,
            "motivationSelectedGuid", motivationSelectedGuid,
            "tags", tags)

        //let reservation = new BaseReservation();
        let _reservation: any = { ...reservation }

        _reservation.book_all_day = false;
        _reservation.location_guid = deskSelected.guid;
        _reservation.booker_guid = authGuid;
        _reservation.include_myself = includeMyself ? 1 : 0

        //let _startDate: any = moment(startDate, 'yyyy-MM-DD HH:mm:ss').startOf('day').add(hour, 'hours').format()
        let _startDate: any = moment(startDate).set('hour', 9).format("YYYY-MM-DD HH:mm:ss")
        let _endDate: any = moment(startDate).set('hour', 19).format("YYYY-MM-DD HH:mm:ss")
        _reservation.booking_datetime_start = Common.FormatDateAndHourFE(_startDate);
        _reservation.booking_datetime_end = Common.FormatDateAndHourFE(_endDate);
        _reservation.booking_reason_guid = motivationSelectedGuid;
        _reservation.entrance_time = Common.FormatDateAndHourFE(_startDate);

        let tags_tmp: any = []
        let tags_email: any = []
        if (includeMyself) {
            tags_email.push({ email: props.auth.email })
        }
        for (var i in tags) {
            tags_tmp.push({ guid: tags[i].guid })
            tags_email.push({ email: tags[i].email })

        }

        _reservation.tags = tags_tmp;
        console.log("[NewReservationV1] Reservation: " + JSON.stringify(_reservation));
        console.log("reservation.booking_datetime_start: " + _reservation.booking_datetime_start);
        console.log("reservation.booking_datetime_end: " + _reservation.booking_datetime_end);
        console.log("tags_email: " + tags_email);
        console.log("motivationSelected.text: " + motivationSelected.text);
        console.log("locationSelected.name: " + locationSelected.name);

        props.onBooking(_reservation, tags_email, motivationSelected.text, locationSelected.name);

        setOpenModalReviewBooking(false)
        clearBookingData()

    }


    const clearBookingData = () => {
        setShowLocation(false)
        setShowNumberOfPerson(false)
        setShowDesk(false)
        setShowMotivation(false)
        setShowTags(false)
        setShowBookButton(false)

        setOfficeSelected('')
        setNumberOfPerson(1)
        setLocationSelected('')
        setDeskSelected('')
        setMotivationSelected('')
        setTags([])
        setStartDate(new Date())
    }


    const onCloseModalTags = () => {
        setShowModalTags(false);
    }

    const onChangeIncludeMyself = () => {
        if (tags.length == numberOfPerson) {
            setShowModalTags(true)
            setIncludeMySelf(false)
        } else {
            setIncludeMySelf(!includeMyself)
        }
    }

    const onCloseAlertBooking = () => {
        setAlertBooking(false)
    }

    const summaryOfReservation = () => {
        return (
            <>
                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "containerCol" : "containerRow"}>
                    <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="checkin.table.date" defaultMessage="Date" />:</div>
                    <label style={{ fontSize: '16px', marginTop: (Common.IsMobile() || Common.CheckMobileUrl()) ? '' : '.5em' }}>{formattedStartDate}</label>
                </div>
                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "containerCol" : "containerRow"}>
                    <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="reservations.office" defaultMessage="Office" />:</div>
                    <label style={{ fontSize: '16px', marginTop: (Common.IsMobile() || Common.CheckMobileUrl()) ? '' : '.5em' }}>{officeSelected.name}</label>
                </div>
                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "containerCol" : "containerRow"}>
                    <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="app.generic.participants" defaultMessage="Participants" />:</div>
                    <label style={{ fontSize: '16px', marginTop: (Common.IsMobile() || Common.CheckMobileUrl()) ? '' : '.5em' }}>{numberOfPerson}</label>
                </div>
                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "containerCol" : "containerRow"}>
                    <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="app.generic.locations" defaultMessage="Locations" />:</div>
                    <label style={{ fontSize: '16px', marginTop: (Common.IsMobile() || Common.CheckMobileUrl()) ? '' : '.5em' }}>{locationSelected.name}</label>
                </div>
                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "containerCol" : "containerRow"}>
                    <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="reservations.desk" defaultMessage="Desk" />:</div>
                    <label style={{ fontSize: '16px', marginTop: (Common.IsMobile() || Common.CheckMobileUrl()) ? '' : '.5em' }}>{deskSelected.name}</label>
                </div>
                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "containerCol" : "containerRow"}>
                    <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="location.motivations.motivation" defaultMessage="Motivation" />:</div>
                    <label style={{ fontSize: '16px', marginTop: (Common.IsMobile() || Common.CheckMobileUrl()) ? '' : '.5em' }}>{motivationSelected.text}</label>
                </div>
                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "containerCol" : "containerRow"}>
                    <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="app.generic.participants" defaultMessage="Participants" />:</div>
                    <label style={{ fontSize: '16px' }}>
                        {
                            tags?.length > 0 ? (tags?.map((user: any, i: number) => (
                                <div key={i} className="containerRow" style={{ fontSize: '16px', marginTop: (Common.IsMobile() || Common.CheckMobileUrl()) ? '' : '.5em' }}>
                                    {user.fullname}
                                </div>
                            ))) : (null)
                        }
                        {
                            includeMyself ? (
                                <div className="containerRow" style={{ fontSize: '16px', marginTop: (Common.IsMobile() || Common.CheckMobileUrl()) ? '' : '.5em' }}>
                                    {props.auth.name + " " + props.auth.surname}
                                </div>
                            ) : (null)
                        }
                    </label>
                </div>
            </>
        );
    }

    return (
        <div className="_flex h-100">
            <Tab.Pane style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "transparent" : "", border: (Common.IsMobile() || Common.CheckMobileUrl()) ? "none" : "" }}>
                <Dimmer active={props.loading} inverted>
                    <Loader size="large">
                        <FormattedMessage id="root.loading.title" defaultMessage="Loading" />
                    </Loader>
                </Dimmer>
                {(openModalReviewBooking) ?
                    ((Common.IsMobile() || Common.CheckMobileUrl())) ?
                        <>
                            <div className="container" style={{ width: '100%', paddingRight: '0.05em', paddingLeft: '0.05em', marginRight: 'auto', marginLeft: 'auto' }}>
                                <div className="labelContent">
                                    <div className="containerCol" style={{ marginLeft: '1em' }}>
                                        <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>
                                            <FormattedMessage id="app.generic.summary" defaultMessage="Summary" style={{ fontSize: '18px', marginBottom: '10px' }} />
                                        </div>
                                        {summaryOfReservation()}
                                    </div>
                                </div>
                            </div>
                            <div className="containerRow" style={{ marginTop: '20px' }}>
                                <UIButton loading={props.inlineLoading} className="w-100 cancelButtonGrey" style={{ backgroundColor: '#dcdcdc', borderRadius: '60px', marginRight: '10px' }}
                                    onClick={() => setOpenModalReviewBooking(false)}>
                                    <FormattedMessage id="app.generic.back" defaultMessage="Back" />
                                </UIButton>
                                <UIButton loading={props.inlineLoading} className="w-100 actionButton" style={{ backgroundColor: '#44ad8c', borderRadius: '60px' }}
                                    onClick={() => confirmOnClickNewReservation()}>
                                    <FormattedMessage id="button.reservation" defaultMessage="Reserve" />
                                </UIButton>
                            </div>
                        </>
                        :
                        <>
                            <Modal
                                open={openModalReviewBooking}
                                style={{ borderRadius: '20px', width: '40%' }}
                            >
                                <Modal.Header>
                                    <FormattedMessage id="app.generic.summary" defaultMessage="Summary" />
                                </Modal.Header>
                                <Modal.Content>
                                    {summaryOfReservation()}
                                </Modal.Content>
                                <Modal.Actions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <UIButton loading={props.inlineLoading} className="w-45" style={{ backgroundColor: '#dcdcdc', borderRadius: '60px', marginRight: '10px' }}
                                        onClick={() => setOpenModalReviewBooking(false)}>
                                        <FormattedMessage id="app.generic.back" defaultMessage="Back" />
                                    </UIButton>
                                    <UIButton loading={props.inlineLoading} className="w-45" style={{ backgroundColor: '#44ad8c', borderRadius: '60px' }}
                                        onClick={() => confirmOnClickNewReservation()}>
                                        <FormattedMessage id="button.reservation" defaultMessage="Reserve" />
                                    </UIButton>
                                </Modal.Actions>
                            </Modal>
                        </>
                    : <div className="container" style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE", width: '100%', paddingRight: '0.05em', paddingLeft: '0.05em', marginRight: 'auto', marginLeft: 'auto' }} >
                        {/*Select per selezionare la data e l'ora */}
                        <div className="containerElement">
                            <FormattedMessage id="app.wsmanager.location.booking.datetime_selection" defaultMessage="Select Date & Time">
                                {(placeholder: string) => (
                                    <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "labelContent" : "labelContentWeb"} style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                        <Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px', borderRadius: '20px', background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                            <Icon className="marginIconLeft" name='clock outline' size='large' />
                                            <div className="separator"></div>
                                            <div className='containerRow'>{placeholder}
                                                {(props.auth.company === 'Techedge') ?
                                                    <FormattedMessage id="app.generic.info_booking_techedge" defaultMessage="The date of the booking must be done at least three days before and up to two weeks after the date">
                                                        {(placeholder: string) => (
                                                            <Popup content={placeholder} position='bottom center' trigger={<Icon style={{ color: '#44ad8c', marginLeft: '0.3em' }} name='info' />} />
                                                        )}
                                                    </FormattedMessage>
                                                    : ''
                                                }
                                            </div>
                                        </Label>
                                        <div style={{ backgroundColor: 'white', position: 'relative', width: (Common.IsMobile() || Common.CheckMobileUrl()) ? '100%' : '45%', display: 'flex', flexDirection: 'column', background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE", justifyContent: (Common.IsMobile() || Common.CheckMobileUrl()) ? "center" : "", alignItems: (Common.IsMobile() || Common.CheckMobileUrl()) ? "center" : "" }}>
                                            <div className="calendar">
                                                <Calendar
                                                    locale={calendar}
                                                    value={startDate}
                                                    onChange={dateChanged}
                                                    inline={true}
                                                    showWeek={false}
                                                    disabledDates={invalidDates}

                                                >
                                                </Calendar>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </FormattedMessage>
                        </div>


                        {/*Select per selezionare la ufficio */}
                        <div className="containerElement">
                            <FormattedMessage id="reports.locations.office" defaultMessage="Select office">

                                {

                                    (placeholder: string) => (
                                        <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "labelContent" : "labelContentWeb"} style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                            <Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', borderRadius: '20px', marginTop: '10px', background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                <Icon className="marginIconLeft" name='building outline' size='large' />
                                                <div className="separator"></div>
                                                <div style={{ marginRight: '5px' }}>{placeholder}</div>
                                            </Label>
                                            <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"} style={{ fontSize: '1.2em' }}>
                                                <Dropdown
                                                    value={officeSelected}
                                                    options={officeOptions}
                                                    className="my-2 rounded-input w-100"
                                                    onChange={onOfficeSelected}
                                                    optionLabel="name"
                                                    placeholder={placeholder}
                                                />
                                            </div>
                                            <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"}>
                                                {
                                                    (linkToMapOffice != '') ?
                                                        <div style={{ margin: '10px' }}>
                                                            <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? 'actionButton' : 'OK green-bg px-4 w-40 rounded-input my-3'} style={{ padding: '5px' }}>
                                                                <a className='acolorlink' href={linkToMapOffice} target="_blank"><FormattedMessage id="app.generic.maps" defaultMessage="Download map" /></a>
                                                            </div>
                                                        </div>
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    )}
                            </FormattedMessage>
                        </div>




                        {/*Select per selezionare il numero di partecipanti */
                            showNumberOfPerson === true ?
                                (<div className="containerElement">
                                    <FormattedMessage id="app.generic.participants" defaultMessage="Participants">
                                        {(placeholder: string) => (
                                            <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "labelContent" : "labelContentWeb"} style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                <Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', borderRadius: '20px', marginTop: '10px', background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                    <Icon className="marginIconLeft" name='add user' size='large' />
                                                    <div className="separator"></div>
                                                    <div>{placeholder}</div>
                                                </Label>
                                                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"} style={{ fontSize: '1.4em' }}>
                                                    {/*<InputNumber id="minmax" value={numberOfPerson} onChange={onNumberOfPersonSelected}
                                                    mode="decimal" min={1} max={100}
                                                    className="my-2 rounded-input w-100" />*/}
                                                    <InputNumber id="horizontal" value={numberOfPerson}
                                                        
                                                        onChange={onNumberOfPersonSelected} mode="decimal"
                                                        showButtons buttonLayout="horizontal" style={{ width: '100%' }}
                                                        decrementButtonClassName="decrementButton" decrementButtonIcon="pi pi-minus"
                                                        incrementButtonClassName="incrementButton" incrementButtonIcon="pi pi-plus"
                                                        min={1} max={100}
                                                        className="inputNumber"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </FormattedMessage>
                                </div>) : (null)
                        }

                        {/*Select prenotazione giornaliera con anchq eventuale scelta fascia oraria */}
                        {/*
                        showNumberOfPerson === true ?
                        (<div className="containerElement">
                            <FormattedMessage id="app.wsmanager.location.booking.datetime_selection" defaultMessage="Select Date & Time">
                                {(placeholder: string) => (
                                    <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "labelContent" : "labelContentWeb"}>
                                        <Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px', borderRadius: '20px' }}>
                                            <Icon className="marginIconLeft" name='clock outline' size='large' />
                                            <div className="separator"></div>
                                            <div style={{ marginRight: '20px' }}>{placeholder}</div>
                                        </Label>
                                        <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"}>
                                        <div className="containerRow">
                                            <FormattedMessage id="location.entrance.book_all_day" defaultMessage="All day booking" />
                                            <Checkbox className="my-3"
                                                onChange={() => setBookAllDay(!bookAllDay)}
                                            //disabled={props.settings?.entrance.book_all_day}
                                            //onChange={e => set(e.checked, 'book_all_day')} 
                                            //checked={reservation.book_all_day}
                                            >
                                            </Checkbox>
                                        </div>
                                        { (bookAllDay) ?
                                                <div className="containerRow">
                                                    <FormattedMessage id="app.wsmanager.location.booking.time_selection" defaultMessage="Select time slot" />
                                                    <UInput
                                                        className="w-30 rounded-input text-center"
                                                        onClick={(e: any) => { openTimeModal() }}
                                                        loading={props.bookingState?.loading}
                                                        value={hour ? hour + ":00" : ""}
                                                        readOnly={true}
                                                        placeholder='00:00' />
                                                </div>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                )}
                            </FormattedMessage>
                        </div>) : (null)
                                        */}

                        {/*Select per selezionare la location */
                            showLocation === true ?
                                (<div className="containerElement">
                                    <FormattedMessage id="reports.locations.select" defaultMessage="Select Locations">
                                        {(placeholder: string) => (
                                            <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "labelContent" : "labelContentWeb"} style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                <Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', borderRadius: '20px', marginTop: '10px', background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                    <Icon className="marginIconLeft" name='numbered list' size='large' />
                                                    <div className="separator"></div>
                                                    <div style={{ marginRight: '5px' }}>{placeholder}</div>
                                                </Label>
                                                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"} style={{ fontSize: '1.2em' }}>
                                                    <Dropdown
                                                        value={locationSelected}
                                                        options={locationOptions}
                                                        className="my-2 rounded-input w-100"
                                                        onChange={onLocationSelected}
                                                        optionLabel="name"
                                                        placeholder={placeholder}
                                                    />
                                                </div>
                                                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"}>
                                                    {
                                                        (linkToMapLocation != '') ?
                                                            <div style={{ margin: '10px' }}>
                                                                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? 'actionButton' : 'OK green-bg px-4 w-40 rounded-input my-3'} style={{ padding: '5px' }}>
                                                                    <a className='acolorlink' href={linkToMapLocation} target="_blank"><FormattedMessage id="app.generic.maps" defaultMessage="Download map" /></a>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </FormattedMessage>
                                </div>) : (null)
                        }

                        {/*Select per selezionare la postazione */

                            showDesk === true ?
                                (<div className="containerElement">
                                    <FormattedMessage id="app.wsmanager.location.booking.desk_selection" defaultMessage="Select desk">
                                        {(placeholder: string) => (
                                            <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "labelContent" : "labelContentWeb"} style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                <Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', borderRadius: '20px', marginTop: '10px', background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                    <Icon className="marginIconLeft" name='desktop' size='large' />
                                                    <div className="separator"></div>
                                                    <div style={{ marginRight: '5px' }}>{placeholder}</div>
                                                </Label>
                                                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"} style={{ fontSize: '1.2em' }}>
                                                    <Dropdown
                                                        value={deskSelected}
                                                        options={deskOptions}
                                                        className="my-2 rounded-input w-100"
                                                        onChange={onDeskSelected}
                                                        optionLabel="name"
                                                        placeholder={placeholder}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </FormattedMessage>
                                </div>) : (null)
                        }
                        {/*Select per selezionare la motivazione */
                            showMotivation === true ?
                                (<div className="containerElement">
                                    <FormattedMessage id="app.wsmanager.location.booking.motivation_selection" defaultMessage="Select motivation">
                                        {(placeholder: string) => (
                                            <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "labelContent" : "labelContentWeb"} style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                <Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', borderRadius: '20px', marginTop: '10px', background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                    <Icon className="marginIconLeft" name='edit outline' size='large' />
                                                    <div className="separator"></div>
                                                    <div>{placeholder}</div>
                                                </Label>
                                                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"} style={{ fontSize: '1.2em' }}>
                                                    <Dropdown
                                                        value={motivationSelected}
                                                        options={motivationOptions}
                                                        className="my-2 rounded-input w-100"
                                                        onChange={onMotivationSelected}
                                                        optionLabel="text"
                                                        placeholder={placeholder}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </FormattedMessage>
                                </div>) : (null)
                        }

                        {/*Select per taggare i colleghi */}
                        {
                            showTags === true ?
                                (<div className="containerElement">
                                    <FormattedMessage id="app.wsmanager.location.booking.users_selection" defaultMessage="Search names">
                                        {(placeholder: string) => (
                                            <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "labelContent" : "labelContentWeb"} style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                {(includeMyself && numberOfPerson === 1) ? "" :
                                                    <><Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', borderRadius: '20px', marginTop: '10px', background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                                        <Icon className="marginIconLeft" name='tags' size='large' />
                                                        <div className="separator"></div>
                                                        <div style={{ marginRight: '5px' }}>{placeholder}</div>
                                                    </Label>
                                                        <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"}>
                                                            <FormattedMessage id="biohazard.query.filter" defaultMessage="Search User">
                                                                {(filterPlaceholder: string) => (
                                                                    <AutoComplete
                                                                        className="_flex mr-3 "
                                                                        value={userSearch}
                                                                        dropdown={true}
                                                                        placeholder={filterPlaceholder}
                                                                        field="fullname"
                                                                        onChange={onSelectChange}
                                                                        onSelect={onSuggestionSelection}
                                                                        suggestions={userSuggestions}
                                                                        completeMethod={suggestUsers}
                                                                        onDropdownClick={suggestUsers}
                                                                        itemTemplate={itemTemplate}
                                                                    />
                                                                )}
                                                            </FormattedMessage>
                                                        </div></>}
                                                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"}>
                                                    <div className="containerRow">
                                                        <Checkbox style={{ marginRight: '.8em' }} size='large' className="my-3" onChange={onChangeIncludeMyself} checked={includeMyself} />
                                                        <FormattedMessage style={{ fontSize: '16px' }} id="app.wsmanager.location.booking.include_me" defaultMessage="Include Myself" />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </FormattedMessage>
                                    {
                                        !props.loading && tags.length > 0 ? (
                                            <div className="containerTags">
                                                <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100" : "w-45"}>

                                                    {
                                                        tags.map((user: any, i: number) => (
                                                            <div key={i} className="mycard">
                                                                <div className="contentCard">
                                                                    <i className="icon-cv19-user" style={{ fontSize: '20px' }}></i>
                                                                    <h5 className="fontSize">{user.fullname}</h5>
                                                                    <i className="icon-cv19-close input-icon right" onClick={(e: any) => removeTag(user)}></i>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }



                                                </div>
                                            </div>
                                        ) : <div className="mycard"></div>

                                    }
                                </div>) : (null)
                        }

                        {
                            showBookButton ? (
                                <div className="containerElement">
                                    <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "labelContentWeb"} style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "" : "#EEEFEE" }}>
                                        <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "containerRow" : "w-45 containerRow"}>
                                            <UIButton loading={props.inlineLoading} className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100 cancelButtonGrey" : "w-45"} style={{ backgroundColor: '#dcdcdc', borderRadius: '60px', marginRight: '10px' }}
                                                onClick={clearBookingData}>
                                                <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" />
                                            </UIButton>
                                            <UIButton loading={props.inlineLoading} className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "w-100 actionButton" : "w-45"} style={{ backgroundColor: '#44ad8c', borderRadius: '60px' }}
                                                onClick={onClickNewReservation}>
                                                <FormattedMessage id="button.reservation" defaultMessage="Booking" />
                                            </UIButton>
                                        </div>
                                    </div>
                                </div>
                            ) : (null)

                        }

                        <Modal
                            open={showModalTags}
                        >
                            <Modal.Header>
                                <FormattedMessage id="app.generic.notice" defaultMessage="Notice" />
                            </Modal.Header>
                            <Modal.Content>
                                <FormattedMessage id="app.generic.too_many_tags" defaultMessage="The number of tags has exceeded the number of attendees" />
                            </Modal.Content>
                            <Modal.Actions>
                                <UIButton loading={props.inlineLoading} style={{ marginTop: '1%', width: '30%', boxShadow: 'rgba(71, 71, 71, 0.596) 0 0 10px', background: 'transparent linear-gradient(90deg, rgba(127, 127, 127, 1) 0%, rgba(71, 71, 71, 0.596) 100%) 0% 0% no-repeat padding-box', color: 'white', borderRadius: '5rem', paddingTop: '1.3em', paddingBottom: '1.3em', marginRight: '10px' }}
                                    onClick={onCloseModalTags}>
                                    <FormattedMessage id="button.close" defaultMessage="Close" />
                                </UIButton>
                            </Modal.Actions>
                        </Modal>

                        <Modal
                            open={alertBooking}
                        >
                            <Modal.Header>
                                <FormattedMessage id="app.generic.notice" defaultMessage="Notice" />
                            </Modal.Header>
                            <Modal.Content>
                                <FormattedMessage id="app.generic.alert_booking" defaultMessage="Include yourself or tag at least one person to continue" />
                            </Modal.Content>
                            <Modal.Actions>
                                <UIButton loading={props.inlineLoading} style={{ marginTop: '1%', width: '30%', boxShadow: 'rgba(71, 71, 71, 0.596) 0 0 10px', background: 'transparent linear-gradient(90deg, rgba(127, 127, 127, 1) 0%, rgba(71, 71, 71, 0.596) 100%) 0% 0% no-repeat padding-box', color: 'white', borderRadius: '5rem', paddingTop: '1.3em', paddingBottom: '1.3em', marginRight: '10px' }}
                                    onClick={onCloseAlertBooking}>
                                    <FormattedMessage id="button.close" defaultMessage="Close" />
                                </UIButton>
                            </Modal.Actions>
                        </Modal>

                        {/*(timeModalOpened) ?
                <div style={{ width: '50%' }}>
                    <Modal
                        open={open}
                    >
                        <Modal.Header>
                            <FormattedMessage id="app.wsmanager.location.booking.timetables" defaultMessage="Timetables Available" />
                        </Modal.Header>
                        <Modal.Content>
                        {console.log("props.bookingState ", props.bookingState)}
                        {
                            
                            props.bookingState?.loading ?
                                <div className="h-100 align-items-center _flex">
                                    <Loader active inline='centered' />
                                </div>
                                :
                                slots ?
                                    <div className="_flex h-100 flex-direction-column">
                                        <div className="slots_wrapper">
                                            {
                                                slots.map(
                                                    (_slot: any, i: number) => (
                                                        <div className="detail-form align-items-center" key={i} onClick={(e: any) => { !isDeskBusy(_slot) ? setSlot(_slot) : setSlot(undefined) }}>

                                                            <Row>
                                                                <Col xs="7" className="justify-content-start">
                                                                    <Col xs="1">
                                                                        {_slot.hour === slot?.hour ? <div className="circle lightest-green-bg"><i className="icon-cv19-circle-check green"></i></div> : ""}
                                                                    </Col>
                                                                    <p className={`slothour ${!isDeskBusy(_slot) ? 'available' : ''}`}>{`${_slot.hour}:00`}</p>
                                                                </Col>
                                                                <Col xs="5">
                                                                    <div className={`ui button rounded-input ${!isDeskBusy(_slot) ? 'green-bg' : ''}`}>
                                                                        {
                                                                            isDeskBusy(_slot) ?
                                                                                <FormattedMessage id="app.wsmanager.location.booking.busy" defaultMessage="busy" />
                                                                                :
                                                                                <span>
                                                                                    {`${seatsAvailable - _slot.seats_busy} `}
                                                                                    {
                                                                                        seatsAvailable - _slot.seats_busy > 1 ?
                                                                                            <FormattedMessage id="app.wsmanager.location.detail.seats" defaultMessage="Seats" />
                                                                                            :
                                                                                            <FormattedMessage id="app.wsmanager.location.detail.seat" defaultMessage="Seat" />
                                                                                    }
                                                                                </span>
                                                                        }
                                                                    </div>

                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                        
                                    </div>
                                    :
                                    ""
                        }
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                btntype="OK detail-bg px-4 w-25 rounded-input my-3"
                                //clicked={(e: any) => { setOpen(false) }}
                                clicked={(e: any) => { cancelSlotSelection() }}
                            >
                                <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button
                                btntype="OK green-bg px-4 w-25 rounded-input my-3"
                                //clicked={(e: any) => { onSelectedTime() }}
                                clicked={(e: any) => {
                                    selectSlot()
                                }}
                            >
                                <FormattedMessage id="app.generic.save" defaultMessage="Save" />
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </div>
                :
                <></>
            */}


                    </div>}
            </Tab.Pane>
        </div>
    )
}

export default NewReservationV1