import { Ability, AbilityBuilder } from "@casl/ability";
import configureStore from "../../store/store";
import { User } from "../../models/User";
import { APP } from "../../interfaces/SwitchState";
import { AuthState } from "../../interfaces/AuthState";
import { setCaslStatus } from "../../store/actions/backoffice/app";

// Defines how to detect object's type
type Actions = "create" | "read" | "update" | "delete" | "manage";
type Subjects =
  | "Location"
  | "Biohazard"
  | "User"
  | "Reports"
  | "Reservation"
  | "Log"
  | "CheckInReport"
  | "ContactReports"
  | "Settings"
  | "ReservationV1";

type AppAbility = Ability<[Actions, Subjects]>;

export const CASL_STATUS: any = {
  INITIALIZED: 'INITIALIZED'
}

const ability = new Ability<[Actions, Subjects]>();
let currentAuth: AuthState;
let activeApp: string
let caslInitialized: boolean
export const store = configureStore();

currentAuth = store.getState().authReducer
activeApp = store.getState().app.activeApp;
caslInitialized = store.getState().app.caslInitialized

const setAbility = () => {
  currentAuth = store.getState().authReducer;
  activeApp = store.getState().app.activeApp;
  caslInitialized = store.getState().app.caslInitialized
  ability.update(defineRulesFor(currentAuth.auth, activeApp));
  if (!caslInitialized && currentAuth.isUserAuthenticated) {
    store.dispatch(setCaslStatus(true))
  }
}

const defineRulesFor = (user: User, activeApp: string) => {
  const { can, rules } = new AbilityBuilder<AppAbility>();
  if(user != null){
      for (var i in user.roles) {
      let app = user.roles[i].app;
      let roles = user.roles[i].roles;

      for (var x in roles) {
        if (app === APP.COVID19 && app === activeApp) {
          if (roles[x] === User.ADMIN) {
            can("manage", "Location");
            can("read", "Biohazard");
            can("read", "Log");
            can("read", "Reports");
            can("manage", "User");
            can("read", "CheckInReport");
            can("read", "ContactReports");
            can("manage", "Settings");
          }
        } else if (app == APP.WSMANAGER && app === activeApp) {
          if (roles[x] === User.ADMIN) {
            can("manage", "Location");
            can("manage", "User");
            can("read", "Reports");
            can("read", "Log");
            can("read", "CheckInReport");
            can("manage", "Settings");
          } else if (roles[x] === User.COMMON_USER) {
            can("update", "Reservation");
            //can("read", "CheckInReport");
            //can("read", "Location");
          } else if (roles[x] === User.BOOKER) {
            can("create", "Reservation");
            can("update", "Reservation");
            //can("read", "CheckInReport");
          } else if (roles[x] === User.MOBILE_USER) {
            can("create", "ReservationV1");
            can("update", "Reservation");
            //can("read", "CheckInReport");
          } else if (roles[x] === User.MODERATOR) {
            can("manage", "Reservation");
            //can("read", "CheckInReport");
          }
        }
      }
    }
  }
  

  return rules;
};

setAbility()

store.subscribe(() => {
  setAbility()
});




export default ability;
