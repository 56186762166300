import React from 'react';
import { connect } from 'react-redux';
import { startLogout, getCompanies, switchCompany } from '../../store/actions/auth';
import { injectIntl } from 'react-intl';
import Header from '../../components/backoffice/Header/Header';
import { switchApp } from '../../store/actions/backoffice/app';

const HeaderContainer = (props: any) => {

    return (
        props.isUserAuthenticated ?
            <Header onGetCompanies={props.getCompanies} onSwitchCompany={props.switchCompany} loadingCompanies={props.loadingCompanies} onLogout={props.logout} auth={props.auth} onSetApp={props.switchApp} activeApp={props.activeApp} />
            :
            <div></div>
    );
};

const mapStateToProps = (state: any) => ({
    isUserAuthenticated: state.authReducer.isUserAuthenticated,
    auth: state.authReducer.auth,
    loadingCompanies: state.authReducer.loadingCompanies,
    activeApp: state.app.activeApp
});

const mapDispatchToProps = (dispatch: any) => ({
    logout: () => dispatch(startLogout()),
    switchApp: (app: string) => dispatch(switchApp(app)),
    getCompanies: () => dispatch(getCompanies()),
    switchCompany: (company: string) => dispatch(switchCompany(company))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(HeaderContainer));
