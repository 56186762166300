import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'semantic-ui-react';

import HeaderBar from "../HeaderBar/HeaderBar";
import Card from "../Card/Card";
import Button from '../../UI/Button/Button';

import './Home.scss';
import NewReservation from '../Card/NewReservation/NewReservation';
import { LOCAL_STORAGE_AUTH_KEY } from '../../../store/localStorage';
import { wait } from '@testing-library/react';
import NewReservation_v1 from '../Card/NewReservation/NewReservation_v1';
import LocationReservation from '../../backoffice/Tabs/WsManager/LocationBooking/LocationReservation';
import SelfCertification from '../Card/SelfCertification/SelfCertification';
import MyReservation from '../Card/MyReservation/MyReservation';

const Home = (props: any) => {

    console.log("Home Component")
    console.log("HOME PROPS", props)
    console.log("HOME PROPS AUTH", props.auth)
    console.log("[Home Component] USERS LIST", props.usersList)
    console.log("HOME PROPS AUTH", props.auth)
    console.log("[Home Component] LOCATIONS LIST", props.locationsList)
    console.log("[Home Component] RESERVATIONS LIST", props.reservationsList)
    console.log("[Home Component] REASON LIST", props.reasonList)
    console.log("[Home Component] RESERVATION CREATE LIST", props.reservationCreate)
    console.log("[Home Component] RESERVATION DELETE LIST", props.reservationDelete)
    console.log("[Home Component] CERTIFICATION LIST DONE", props.acceptCertificationDone)
    console.log("[Home Component] CONFIGURATION DONE", props.acceptCertificationDone)
    console.log("[Home Component] USER INFO", localStorage.getItem("userInfo"))

    const [roles, setRoles] = useState({
        isCovid: false,
        isWsman: false
    })

    const [toShow, setToShow] = useState({
        qrCode: true,
        qrCodeSuccess: false,
        qrCodeError: false,
        newReservationModal: false,
        listReservations: false,
        reservationCreateCorrect: false,
        reservationCreateFailed: false,
        reservationDeleteCorrect: false,
        reservationDeleteFailed: false,
        certificationView: false,
        newReservationV1: false,
        myReservation: false,
    })

    const [certificationDone, setCertificationDone] = useState<Boolean>(true)
    const [configuration, setConfiguration] = useState<Boolean>()

    const [header, setHeader] = useState({
        title: "QrCode",
        back: false
    })

    const [elementToShow, setElementToShow] = useState('qrcode')

    const [colleagues, setColleagues] = useState<any[]>([])
    const handleAddColleagues = (tags: string[]) => {
        let collObject = []
        for (let i = 0; i < tags.length; i++) {
            collObject.push(tags[i])
        }
        setColleagues(collObject)
    }
    const onSetColleagues = () => {
        setColleagues([])
    }

    if (!certificationDone && ((props.isCertificationDone && props.isCertificationDone.length == 0) || !props.isCertificationDone)) {
        setCertificationDone(true)
    }

    if (certificationDone && (props.isCertificationDone && props.isCertificationDone.length > 0)) {
        setCertificationDone(false)
    }

    if (!configuration && props.configurationDone) {
        setConfiguration(props.configurationDone)
    }

    console.log("[Home Component] isCertificationDone", props.isCertificationDone, !props.isCertificationDone, certificationDone)


    var userInfo: any = null;
    if (localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) != null && localStorage.getItem(LOCAL_STORAGE_AUTH_KEY) != undefined) {
        userInfo = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
    }
    else {
        userInfo = null;
    }

    useEffect(() => {

        if (!props.configurationDone) {
            props.configuration()
        }

        console.log("[Home] --- props.isCertificationDone", props.isCertificationDone)
        console.log("[Home] --- props.self_certification", props.configurationDone?.self_certification)
        if (!props.isCertificationDone && props.configurationDone?.self_certification) {
            props.handleIsCertificationDone()
        }


        console.log("[Home] Loading", props.reservationsList)
        let isCovid = false;
        let isWsman = false;
        if (userInfo != null && userInfo != undefined) {
            console.log(["HOME - USER INFO "], userInfo)
            for (let role of JSON.parse(userInfo).roles) {
                if (role.app == "COVID") {
                    isCovid = true;
                }
                else if (role.app == "WSMAN") {
                    isWsman = true;
                }
                else {
                    isWsman = false;
                    isCovid = false;
                }
            }
        }
        setRoles({
            isCovid: isCovid,
            isWsman: isWsman
        });

        if (props.checkinDone != null) {
            console.log("[Home] checkinDone", props.checkinDone)

            if (props.checkinDone.Status === "OK") {
                setToShow({
                    qrCode: true,
                    qrCodeSuccess: true,
                    qrCodeError: false,
                    newReservationModal: false,
                    listReservations: false,
                    reservationCreateCorrect: false,
                    reservationCreateFailed: false,
                    reservationDeleteCorrect: false,
                    reservationDeleteFailed: false,
                    certificationView: false,
                    newReservationV1: false,
                    myReservation: false,
                });
                setHeader({
                    title: "QrCode",
                    back: true
                });
            }
            else {
                setToShow({
                    qrCode: true,
                    qrCodeSuccess: true,
                    qrCodeError: true,
                    newReservationModal: false,
                    listReservations: false,
                    reservationCreateCorrect: false,
                    reservationCreateFailed: false,
                    reservationDeleteCorrect: false,
                    reservationDeleteFailed: false,
                    certificationView: false,
                    newReservationV1: false,
                    myReservation: false,

                });
                setHeader({
                    title: "QrCode",
                    back: true
                });
            }
        }



    }, [props.checkinDone, props.loading, props.configurationDone, props.isCertificationDone])

    const handleBackClick = () => {
        setToShow({
            qrCode: true,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: false,
            newReservationV1: false,
            myReservation: false,

        });
        setHeader({
            title: "QrCode",
            back: false
        });
    }

    const applyTagsCertification = () => {
        setToShow({
            qrCode: false,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: true,
            newReservationV1: false,
            myReservation: false,

        });
        props.onClickCertificationList();
    }

    const handleColleaguesClick = () => {
        setToShow({
            qrCode: false,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: false,
            newReservationV1: false,
            myReservation: false,

        });
        setHeader({
            title: "Colleagues",
            back: true
        });

        // props.getColleagues()
        props.getUserList(1, 10, {});
    }

    const handleNewReservationClick = () => {
        console.log("NEW RES")
        setToShow({
            qrCode: true,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: true,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: false,
            newReservationV1: false,
            myReservation: false,

        });
        setHeader({
            title: "QrCode",
            back: false
        });
        props.getLocations()
        props.getColleagues()
        props.getReasons();
        props.getUserList(1, 10, {});
    }

    const handleListReservationsClick = () => {
        if (roles.isWsman) {
            setToShow({
                qrCode: false,
                qrCodeSuccess: false,
                qrCodeError: false,
                newReservationModal: false,
                listReservations: true,
                reservationCreateCorrect: false,
                reservationCreateFailed: false,
                reservationDeleteCorrect: false,
                reservationDeleteFailed: false,
                certificationView: false,
                newReservationV1: false,
                myReservation: false,
            });
            setHeader({
                title: "ReservationList",
                back: true
            });
            props.getReasons();
            props.getReservations()
            props.getColleagues()
            props.getUserList(1, 10, {});

        }
    }

    const handleCreareReservation = (params: any) => {
        props.createReservation(params)
    }

    const handleUpdateReservation = (params: any) => {
        props.updateReservation(params)
    }

    const handleDeleteReservation = (params: any) => {
        props.deleteReservation(params)
    }

    const inputForm = () => {
        console.log("CLEAR")
    }
    const handleCloseModal = () => {
        setToShow({
            qrCode: true,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: false,
            newReservationV1: false,
            myReservation: false,
        })
    }

    const handleOnClickCertificationList = () => {

        if (!certificationDone && ((props.isCertificationDone && props.isCertificationDone.length == 0) || !props.isCertificationDone)) {
            setCertificationDone(true)
        }

        if (certificationDone && (props.isCertificationDone && props.isCertificationDone.length > 0)) {
            setCertificationDone(false)
        }
        setToShow({
            qrCode: true,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: true,
            newReservationV1: false,
            myReservation: false,
        });
        props.onClickCertificationList();


    }

    const handleHideCertificationList = () => {

        setToShow({
            qrCode: true,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: false,
            newReservationV1: false,
            myReservation: false,
        });
        props.onClickCertificationList();
    }

    const handleAcceptCertification = () => {

        props.onClickAcceptCondition()
        props.handleIsCertificationDone()
        if (!certificationDone && ((props.isCertificationDone && props.isCertificationDone.length == 0) || !props.isCertificationDone)) {
            setCertificationDone(true)
        }

        if (certificationDone && (props.isCertificationDone && props.isCertificationDone.length > 0)) {
            setCertificationDone(false)
        }
        setToShow({
            qrCode: true,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: false,
            newReservationV1: false,
            myReservation: false,
        });
    }


    const handleClickNewReservation = () => {
        console.log("cliccato HOME handleClickNewReservation")

        setToShow({
            qrCode: false,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: false,
            newReservationV1: true,
            myReservation: false,
        });
        props.getLocations();
        props.getReasons();
        props.getUserList(1, 10, {});
        
        setElementToShow('book')
    }

    const handleClickCertification = () => {
        console.log("cliccato HOME handleClickCertification")

        setToShow({
            qrCode: true,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: true,
            newReservationV1: false,
            myReservation: false,
        });

        setElementToShow('certification')
    }

    const handleClickQrCode = () => {
        console.log("cliccato HOME handleClickQrCode ")
        setToShow({
            qrCode: true,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: false,
            newReservationV1: false,
            myReservation: false,
        });
        setElementToShow('qrcode')
    }

    const handleClickMyReservation = () => {
        console.log("cliccato HOME handleClickMyReservation ")
        setToShow({
            qrCode: false,
            qrCodeSuccess: false,
            qrCodeError: false,
            newReservationModal: false,
            listReservations: false,
            reservationCreateCorrect: false,
            reservationCreateFailed: false,
            reservationDeleteCorrect: false,
            reservationDeleteFailed: false,
            certificationView: false,
            newReservationV1: false,
            myReservation: true,
        });
        props.onGetReservations(props.isModerator,{ page: 1, per_page: props.reservationsTotalItems });
        setElementToShow('myreservations')
    }

    const renderShow = () => {
        switch (elementToShow) {
            case 'qrcode': {
                return (
                    <Card
                        toShow={toShow}
                        onScan={(location: string, tags: any[]) => props.onScan(location, tags)}
                        getUserList={props.getUserList}
                        usersList={props.usersList}
                        applyTags={handleBackClick}
                        handleListReservationsClick={handleListReservationsClick}
                        reservationsList={props.reservationsList}
                        roles={roles}
                        reasonList={props.reasonList}
                        handleOnClickCertificationList={handleOnClickCertificationList}
                        updateReservation={(params: any) => handleUpdateReservation(params)}
                        deleteReservation={(params: any) => handleDeleteReservation(params)}
                        closeModal={handleCloseModal}
                        certificationListDone={props.certificationListDone}
                        onClickAcceptCondition={handleAcceptCertification}
                        acceptCertificationDone={props.acceptCertificationDone}
                        checkinDone={props.checkinDone}
                        isCertificationDone={props.isCertificationDone}
                        certificationDone={certificationDone}
                        configurationDone={configuration}
                        inlineLoading={props.inlineLoading}

                        onGetUsers={props.onGetUsers}
                        usersState={props.usersState}
                        presetColleagues={colleagues}
                        addColleagues={(coll: any) => handleAddColleagues(coll)}
                        onSetColleagues={onSetColleagues}
                    >
                    </Card>
                )
            }
            case 'certification': {

                return (

                    props.certificationDone && (props.configurationDone && props.configurationDone.self_certification) ?
                        <Card
                            toShow={toShow}
                            onScan={(location: string, tags: any[]) => props.onScan(location, tags)}
                            getUserList={props.getUserList}
                            usersList={props.usersList}
                            applyTags={handleClickCertification}
                            handleListReservationsClick={handleListReservationsClick}
                            reservationsList={props.reservationsList}
                            roles={roles}
                            reasonList={props.reasonList}
                            handleOnClickCertificationList={handleOnClickCertificationList}
                            updateReservation={(params: any) => handleUpdateReservation(params)}
                            deleteReservation={(params: any) => handleDeleteReservation(params)}
                            closeModal={handleCloseModal}
                            certificationListDone={props.certificationListDone}
                            onClickAcceptCondition={handleAcceptCertification}
                            acceptCertificationDone={props.acceptCertificationDone}
                            checkinDone={props.checkinDone}
                            isCertificationDone={props.isCertificationDone}
                            certificationDone={certificationDone}
                            configurationDone={configuration}
                            inlineLoading={props.inlineLoading}

                            onGetUsers={props.onGetUsers}
                            usersState={props.usersState}
                        >
                        </Card>

                        : <SelfCertification
                            toShow={toShow}
                            onClickCertificationList={handleOnClickCertificationList}
                            certificationListDone={props.certificationListDone}
                            onClickAcceptCondition={handleAcceptCertification}
                            acceptCertificationDone={props.acceptCertificationDone}
                            checkinDone={props.checkinDone}
                            isCertificationDone={props.isCertificationDone}
                            certificationDone={certificationDone}
                            configurationDone={configuration}
                            inlineLoading={props.inlineLoading}
                        ></SelfCertification>
                )

            }
            case 'book': {
                return (<div style={{ position: "relative" }}>
                    <NewReservation_v1
                        toShow={toShow}
                        locationsList={props.locationsList}
                        reasonList={props.reasonList}
                        onGetUsers={props.onGetUsers}
                        usersState={props.usersState}
                        bookingState={props.bookingState}
                        onGetTimetables={props.getTimetables}
                        auth={props.auth}

                        onClickCertificationList={handleOnClickCertificationList}
                        onClickHideCertificationList={handleHideCertificationList}
                        isCertificationDone={props.isCertificationDone}
                        acceptCertificationDone={props.acceptCertificationDone}
                        certificationDone={certificationDone}
                        handleClickCertificationBack={handleClickCertification}
                        configurationDone={configuration}
                        getAvailableLocations={props.getAvailableLocations}
                        getParentLocation={props.getParentLocation}
                        listLocationOptions={props.listLocationOptions}
                        onBooking={props.onBooking}
                        listParentLocation={props.listParentLocation}

                    ></NewReservation_v1>
                </div>)
            }
            case 'myreservations': {
                return(<div style={{ position: "relative" }}>
                        <MyReservation 
                            bookingState={props.bookingState}
                            onGetReservations={props.onGetReservations} 
                            reservationsList={props.reservationsList} 
                            onDeleteReservation = {props.onDeleteReservation}  
                            reservationsTotalItems={props.reservationsTotalItems}
                        />
                    </div>
                )
            }
            default: {
                return (
                    <Card
                        toShow={toShow}
                        onScan={(location: string, tags: any[]) => props.onScan(location, tags)}
                        getUserList={props.getUserList}
                        usersList={props.usersList}
                        applyTags={handleBackClick}
                        handleListReservationsClick={handleListReservationsClick}
                        reservationsList={props.reservationsList}
                        roles={roles}
                        reasonList={props.reasonList}
                        handleOnClickCertificationList={handleOnClickCertificationList}
                        updateReservation={(params: any) => handleUpdateReservation(params)}
                        deleteReservation={(params: any) => handleDeleteReservation(params)}
                        closeModal={handleCloseModal}
                        certificationListDone={props.certificationListDone}
                        onClickAcceptCondition={handleAcceptCertification}
                        acceptCertificationDone={props.acceptCertificationDone}
                        checkinDone={props.checkinDone}
                        isCertificationDone={props.isCertificationDone}
                        certificationDone={certificationDone}
                        configurationDone={configuration}
                        inlineLoading={props.inlineLoading}

                        onGetUsers={props.onGetUsers}
                        usersState={props.usersState}
                        presetColleagues={colleagues}
                        addColleagues={(coll: any) => handleAddColleagues(coll)}
                        onSetColleagues={onSetColleagues}
                    >
                    </Card>
                )
            }
        }
    }

    return (
        <div className="homeContainer">
            <HeaderBar
                title="QrCode"
                config={header}
                onClickBack={handleBackClick}
                toShow={toShow}
                onClickLogout={props.onClickLogout}

                onClickCertificationList={handleOnClickCertificationList}
                onClickHideCertificationList={handleHideCertificationList}
                isCertificationDone={props.isCertificationDone}
                acceptCertificationDone={props.acceptCertificationDone}
                certificationDone={certificationDone}
                handleClickCertificationBack={handleClickCertification}

                configurationDone={configuration}
                handleClickNewReservationBack={handleClickNewReservation}
                handleClickQrCodeBack={handleClickQrCode}
                handleClickMyReservation={handleClickMyReservation}
                activeApp={props.activeApp}
            >

            </HeaderBar>


            {renderShow()}


            {elementToShow !== 'certification' ?
                <div className="buttonSection">
                    {toShow.qrCodeSuccess ?
                        <Button btntype={"actionButton"} clicked={handleBackClick}>
                            <FormattedMessage id="button.close" defaultMessage="" />
                        </Button> : null
                    }
                    {toShow.qrCode && !toShow.qrCodeSuccess && roles.isCovid ?
                        <div>
                            <Button btntype={"tagButton"} clicked={handleColleaguesClick}>
                                <FormattedMessage id="button.tag" defaultMessage="" />
                                <div className="roundIcon">
                                    <i className="icon-cv19-tag"></i>
                                </div>
                            </Button>
                            <div className="w-100" style={{marginTop: '10px'}}>
                            {
                                    colleagues?.map((user: any, i: number) => (
                                        <div key={i} className="mycard">
                                            <div className="contentCard" style={{height:'30px'}}>
                                                <i className="icon-cv19-user" style={{fontSize:'12px'}}></i>
                                                <h5 className="fontSize" style={{fontSize:'16px'}}>{user.fullname}</h5>
                                            </div>
                                        </div>
                                    ))
                            }
                            </div>
                        </div>
                        : null
                    }
                    {/*{toShow.qrCode && roles.isWsman ?
                    <div>
                        <Button btntype={"actionButton"} clicked={handleNewReservationClick}>
                            <FormattedMessage id="button.newreservation" className="buttonText" defaultMessage="" />
                        </Button>
                    </div> : null
                }*/}
                </div>
                :
                null}

            {/*{<NewReservation
                toShow={toShow}
                inputForm={inputForm}
                usersList={props.usersList}
                getUserList={props.getUserList}
                locationsList={props.locationsList}
                isEdit={false}
                reservationSelected=""
                createReservation={(params: any) => handleCreareReservation(params)}
                reasonList={props.reasonList}
                closeModal={handleCloseModal}
            ></NewReservation>} 
            {props.loading ? <div className="fullScreenSpinner"><Loader active /></div> : null}*/}

        </div >
    )

};

export default Home