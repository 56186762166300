import { LocationState } from "../../../interfaces/Location";
import { LocationApi } from "../../../api/LocationsApi";
import { AddError, AddSuccess } from "../toast";
import { LocationAction } from "../../../models/actions/LocationAction";
import { HTTP_STATUS, Api } from "../../../api/Api";
import { Location } from "../../../models/Location";
import { AppState } from "../../../interfaces/AppState";
import { LocationUtils } from "../../../utils/LocationUtils";
import { startLogout } from "../auth";
import { BookingApi } from "../../../api/BookingApi";
import { AuthState } from "../../../interfaces/AuthState";
import { SettingsAction } from "../../../models/actions/SettingsAction";
import { UserLog } from "../../../interfaces/UserLogState";
import { UsersLog } from "../../../utils/UsersLog";
import { createUsersLog } from "./usersLog";

export const GET_LOCATION = "GET_LOCATION";
export const REQUEST_LOCATION = "REQUEST_LOCATION";
export const SET_TREE_DATA = "SET_TREE_DATA";
export const ON_NODE_CHANGE = "ON_NODE_CHANGE";
export const REQUEST_FAILED = "REQUEST_FAILED";
export const ON_NODE_CHANGE_ERROR = "ON_NODE_CHANGE_ERROR";
export const ON_GET_TIMEZONES_REQUEST: string = "ON_GET_TIMEZONES_REQUEST";
export const ON_GET_TIMEZONES_SUCCESS: string = "ON_GET_TIMEZONES_SUCCESS";
export const ON_GENERIC_ERROR: string = "ON_GENERIC_ERROR";

//per v2
export const REQUEST_AVAILABLE_LOCATION = "REQUEST_AVAILABLE_LOCATION";
export const REQUEST_PARENT_LOCATION = "REQUEST_PARENT_LOCATION";
export const GET_AVAILABLE_LOCATION = "GET_AVAILABLE_LOCATION";
export const GET_PARENT_LOCATION = "GET_PARENT_LOCATION";
export const GET_MAP_LOCATION = "GET_MAP_LOCATION";
export const REQUEST_MAP_LOCATION = "REQUEST_MAP_LOCATION";
export const DOWNLOAD_TEMPLATE: string = "DOWNLOAD_TEMPLATE";

const requestAvailableLocations = () => {
  return { type: REQUEST_AVAILABLE_LOCATION };
};
const requestParentLocation = () => {
  return { type: REQUEST_PARENT_LOCATION };
};

const requestLocation = () => {
  return { type: REQUEST_LOCATION };
};

export const requestFailed = () => {
  return { type: REQUEST_FAILED };
};

const requestOnNodeChange = (treeData: any, node?: any, path?: any) => {
  return LocationAction.OnNodeChangeRequest(
    ON_NODE_CHANGE,
    treeData,
    node,
    true,
    path
  );
};

const nodeChangeSuccess = (treeData: any, node?: any, path?: any) => {
  return LocationAction.OnNodeChangeSuccess(
    ON_NODE_CHANGE,
    treeData,
    node,
    path
  );
};

const nodeChangeError = (treeData: any, node?: any, path?: any) => {
  return LocationAction.OnNodeChangeError(
    ON_NODE_CHANGE_ERROR,
    treeData,
    node,
    path
  );
};

const nodeChangeTrashSuccess = (
  treeData: any,
  path: any,
  location?: Location
) => {
  return LocationAction.OnNodeTrashSuccess(
    ON_NODE_CHANGE,
    treeData,
    location,
    path
  );
};

const locationSuccess = (res: any) => {
  return LocationAction.GetLocationSuccess(GET_LOCATION, res);
};

const availableLocationsSuccess = (res: any) => {
  console.log("availableLocationsSuccess res", res)
  return LocationAction.GetLocationSuccess(GET_AVAILABLE_LOCATION, res);
  //return { type: GET_AVAILABLE_LOCATION, res };
};
const parentLocationSuccess = (res: any) => {
  return { type: GET_PARENT_LOCATION, res };
};

const handleChangeSuccess = (treeData: any) => {
  return LocationAction.SetTreeDataSuccess(SET_TREE_DATA, treeData);
};

export function getTimezones(data?: any) {
  console.log("[---  GET TIMEZONES ---]", data);

  return (dispatch: any, getState: any) => {
    let state: AuthState = (getState() as AppState).authReducer;
    dispatch(LocationAction.OnGetTimezonesRequest());
    //if (data) data.company_code = state.auth.selectedCompany
    LocationApi.GetTimezonesList(data)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        // let items: any[] = response.items;
        // let timezones: string[] = items.map(value => value.timezone.replaceAll("/", "") );
        // console.log("[GET TIMEZONES RES - LOCATIONS FOR TEST] ", timezones);
        dispatch(LocationAction.OnGetTimezonesSuccess(response.items));
      })
      .catch((error) => {
        //console.log(TIMEZONES_ACTION + "TIMEZONES LIST", error);
        dispatch(AddError(error));
        dispatch(LocationAction.OnGenericError());
      });
  };
}

export function getLocations(disabled: boolean) {
  return (dispatch: any, getState: any) => {
    let authState: AuthState = (getState() as AppState).authReducer;
    dispatch(requestLocation());
    return LocationApi.GetLocations({ disabled: disabled ? 1 : 0, companies: [authState?.auth?.selectedCompany] })
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          if (localStorage.getItem("DEBUG_MODE") == "true") {
            console.log(res);
          }

          dispatch(locationSuccess(res));
        }
      })
      .catch((err: any) => {
        console.log(err);
        if(err.toString().includes("access_token")){
          dispatch(startLogout())
        }
        dispatch(requestFailed());
        dispatch(AddError(err));
      });
  };
}

export const add = (location: Location, treeData: any[], path: any) => {
  //SetUp UserLog Variable
  // let userLog = new UserLog({
  //   action_guid: UsersLog.ADD_LOCATION_ACTION,
  //   fe_view_guid: UsersLog.LOCATIONS_PAGE_VIEW,
  //   be_api_guid: UsersLog.ADD_LOCATION_API,
  //   description: "Add new Location"
  // })

  return (dispatch: any, getState: LocationState) => {
    dispatch(requestOnNodeChange(treeData, location, path));
    LocationApi.CreateLocation(LocationApi.Request(location))
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        if (response.guid) {
          location.guid = response.guid;
          location.estimated_distance_time = response.default_edt
          location.risk_percentage = response.default_risk
        }
        dispatch(AddSuccess("app.operation.successful"));
        dispatch(nodeChangeSuccess(treeData, location, path));
        //createUsersLog(userLog);
      })
      .catch((error: any) => {
        dispatch(AddError(error));
        dispatch(requestFailed());
      });
  };
};

export const update = (
  location: Location,
  newLocation: Location,
  treeData: any[],
  path: any,
  deskUpdate: boolean
) => {
  return (dispatch: any, getState: LocationState) => {
    let tmploc = { ...newLocation };
    dispatch(requestOnNodeChange(treeData, location, path));
    let desks: any[] = [];
    if (!deskUpdate) {
      delete tmploc["desks_properties"];
    }
    LocationApi.UpdateLocation(tmploc)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response) => {
        dispatch(AddSuccess("app.operation.successful"));

        if (response && response.desk_guid) {
          for (let i in newLocation.desks_properties) {
            if (!newLocation.desks_properties[parseInt(i)].guid) {
              newLocation.desks_properties[parseInt(i)].available = 0
              newLocation.desks_properties[parseInt(i)].guid = response.desk_guid;
              break;
            }
          }
        }
        dispatch(nodeChangeSuccess(treeData, newLocation, path));
      })
      .catch((error: any) => {
        dispatch(AddError(error));
        dispatch(nodeChangeSuccess(treeData, location, path));
        dispatch(requestFailed());
      });
  };
};

export const trash = (location: Location, treeData: any[], path: any) => {
  return (dispatch: any, getState: any) => {

    dispatch(requestOnNodeChange(treeData, location, path));
    let state: LocationState = (getState() as AppState).location;
    treeData = state.treeData;
    LocationApi.TrashLocation(location)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        dispatch(AddSuccess("app.operation.successful"));
        dispatch(nodeChangeTrashSuccess(treeData, path, location));
      })
      .catch((error: any) => {
        dispatch(AddError(error));
        location.loading = false;

        for (var i in location.desks_properties) {
          if (location.desks_properties[parseInt(i)].guid === location.deskLoading) {
            location.desks_properties[parseInt(i)].deleted = 0;
          }
        }
        dispatch(nodeChangeError(treeData, location, path));
        dispatch(requestFailed());
      });
  };
};

export const print = (data?: any) => {
  return (dispatch: any, getState: any) => {
    let state: LocationState = (getState() as AppState).location;
    dispatch(requestLocation());
    let locations: any = data;

    if (!locations || locations.length === 0) {
      dispatch(AddError({ message: "Error" }));
      dispatch(requestFailed());
      return;
    }
    // let locations:any[] = location ? [location] : [...state.locations]
    LocationApi.PrintQrs(locations)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        if (response.qrcode) {
          window.open(response.qrcode);
          dispatch(handleChangeSuccess(state.treeData));
          dispatch(AddSuccess("app.operation.successful"));
        } else {
          dispatch(requestFailed());
          // dispatch(AddError());
        }
      })
      .catch((error: any) => {
        dispatch(requestFailed());
        dispatch(AddError(error));
      });
  };
};

export function uploadLocations(file: File, disabled: boolean) {
  return (dispatch: any, getState: any) => {
    dispatch(requestLocation());

    let formdata = new FormData();
    formdata.append("", file);
    LocationApi.UploadLocations(formdata)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        dispatch(getLocations(disabled));
        dispatch(AddSuccess("app.operation.successful"));
      })
      .catch((error: any) => {
        console.log("LOCATIONS UPLOAD ERROR", error);
        dispatch(AddError(error));
        dispatch(requestFailed());
      });
  };
}

export const handleChange = (treeData: any) => {
  return (dispatch: any, getState: LocationState) => {
    dispatch(handleChangeSuccess(treeData));
  };
};

export const onMapSubmit = (guid: any,image: any, format: any) =>{
  return (dispatch: any, getState: any) => {
    dispatch(requestLocation());
    let params={"Guid": guid, "image": image, "format": format}
    LocationApi.UpdateMap(params).then((response: any) => {
      if (response.status === HTTP_STATUS.OK) {
        return response.json();
      }
      else {let unauthorized = false;
        let error = "";
        return Api.IsAuthorized(response)
          .then((text: any) => {
            error = text;
          })
          .catch((err: string) => {
            error = err;
            unauthorized = true;
          })
          .finally(() => {
            if (unauthorized) {
              dispatch(startLogout());
            }
            throw new Error(error);
          });
        }
    }).then((response: any) => {
      //il caricamento della mappa è effettuabile solo da admin, che vede tutte le locations.
      //per tale motivo viene passato disabled=true
      dispatch(getLocations(true));
      dispatch(AddSuccess("app.operation.successful"));
    }).catch((error:any) => {
      console.log("MAP UPDATE ERROR", error);
      dispatch(AddError(error));
      dispatch(requestFailed());
    });
  }

};

export const getMap = (guid: any) => {
  return (dispatch: any, getState: any) => {
    //dispatch(requestLocation());
    let params={"Guid": guid}
    LocationApi.GetMap(params).then((response: any) => {
      if (response.status === HTTP_STATUS.OK) {
        return response.json();
      }
      else {let unauthorized = false;
        let error = "";
        return Api.IsAuthorized(response)
          .then((text: any) => {
            error = text;
          })
          .catch((err: string) => {
            error = err;
            unauthorized = true;
          })
          .finally(() => {
            if (unauthorized) {
              dispatch(startLogout());
            }
            throw new Error(error);
          });
        }
    }).then((response: any) => {
      window.open(response.map_link)
      dispatch(AddSuccess("app.operation.successful"));
    }).catch((error:any) => {
      console.log("MAP GET ERROR", error);
      dispatch(AddError(error));
      dispatch(requestFailed());
    })
  }
}

export const downloadTemplate = () => {
  return (dispatch: any, getState: any) => {
    LocationApi.DownloadTemplate()
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        console.log("link template", response);
        window.open(response.link);
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(AddError(error));
      });
  };
};
