import React, { useState, useEffect, SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Input, Icon, Loader } from 'semantic-ui-react'
import "./Colleagues.scss"
import Button from '../../../UI/Button/Button';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.css';
import { AutoComplete } from 'primereact/autocomplete';
import {Label} from 'semantic-ui-react';

const Colleagues = (props: any) => {
    const [userSearch, setUserSearch] = useState<any>();
    const [suggested, setSuggested] = useState<any>();
    const [userSuggestions, setUserSuggestions] = useState<any>([]);
    const [tags, setTags] = useState<any>([]);
    const [list, setList] = useState<any>([]);
    const [totalItems, setTotalItems] = useState();
    const [selectedCounter, setSelectedCounter] = useState(0)

    useEffect(() => {

        console.log("[Colleagues] Use Effect", props.usersList)


        if (props.usersList?.items && props.usersList.items.length > 0) {

            setTotalItems(props.usersList.total_items)



            const mergedList = [...props.usersList.items]

            let preset = [...tags]

            if (!preset || preset.length === 0) {
                preset = props.presetColleagues.concat(tags.filter((item: any) => props.presetColleagues.indexOf(item) < 0))
            }
            setTags(preset)
            setSelectedCounter(preset.length)

            for (let i = 0; i < mergedList.length; i++) {
                const element = mergedList[i];
                for (let j = 0; j < preset.length; j++) {
                    const coll = preset[j];
                    if (element.guid === coll) {
                        element.checked = true
                    }
                }
            }


            setList(mergedList)

        }

        setUserSuggestions(props.usersState.items)

    }, [props.usersList, props.usersState]);

    console.log("[Colleagues] Selected", tags)
    
    const applyTags = () => {
        props.addColleagues(tags)
        props.applyTags()
        setList([])
    }

    const onSelectChange = (e: any) => {
        if (e && e.value) {
            setUserSearch(e.value)
            setSuggested(e.value)
        } else {
            setUserSearch("")
            setSuggested("")
        }
    }

    const onSuggestionSelection = (e: any) => {
        console.log("USER_SELECTED: "+JSON.stringify(e.value))
        let tags_tmp = tags;
        tags_tmp.push(e.value);
        setTags(tags_tmp);
        console.log("TAG_SAVED: "+JSON.stringify(tags[0]))
        setUserSearch('')
    }

    const suggestUsers = (event: any) => {
        if (event.query.length > 0) {
            props.onGetUsers({ page: 1, per_page: 10, filters: { fullname: { value: event.query } } })
        }
    }

    function itemTemplate(item: any) {
        return (
            <div >
                <h6>{item.fullname}</h6>
                <p>{item.email}</p>
            </div>
        );
    }

    const clearTags = () => {
        setTags([])
    }

    const removeTag = (tag: any) => {
        console.log("TAGS: " + JSON.stringify(tags));

        let tags_tmp: any = [...tags];

        for(let i = 0; i < tags_tmp.length; i++ ){
            if(tags_tmp[i].guid === tag.guid){
                tags_tmp.splice(i, 1)
                break; 
            }
        }

        console.log("TAGS new: " + tags_tmp);

        if (tags_tmp.length === 0) {
            clearTags()
        } else {
            setTags(tags_tmp);
        }
    }

    return (
        <div className="containerElement">
                <FormattedMessage id="input.filterName" defaultMessage="Filter by name">
                    {(placeholder: string) => (
                        <div className="labelContent">
                            <Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', borderRadius: '20px', marginTop: '10px' }}>
                                <Icon className="marginIconLeft" name='tags' size='large' />
                                <div className="separator"></div>
                                <div style={{ marginRight: '5px' }}>{placeholder}</div>
                            </Label>
                            <div className="w-100">
                                <FormattedMessage id="biohazard.query.filter" defaultMessage="Search User">
                                    {(filterPlaceholder: string) => (
                                        <AutoComplete
                                            className="_flex mr-3 "
                                            value={userSearch}
                                            dropdown={true}
                                            placeholder={filterPlaceholder}
                                            field="fullname"
                                            onChange={onSelectChange}
                                            onSelect={onSuggestionSelection}
                                            suggestions={userSuggestions}
                                            completeMethod={suggestUsers}
                                            onDropdownClick={suggestUsers}
                                            itemTemplate={itemTemplate}
                                        />      
                                    )}
                                </FormattedMessage> 
                            </div>
                            <div className="w-100" style={{marginTop: '10px'}}>
                            {
                                    tags?.map((user: any, i: number) => (
                                        <div key={i} className="mycard">
                                            <div className="contentCard">
                                                <i className="icon-cv19-user" style={{fontSize:'20px'}}></i>
                                                <h5 className="fontSize">{user.fullname}</h5>
                                                <i className="icon-cv19-close input-icon right" onClick={(e: any) => removeTag(user)}></i>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <Button clicked={applyTags} btntype={"actionButton"}><FormattedMessage id="button.apply" defaultMessage="" /></Button>
                        </div>
                    )}
                </FormattedMessage>
                
            </div>
        
    )
};

export default Colleagues;

/*
import React, { useState, useEffect, SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Input, Icon, Loader } from 'semantic-ui-react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import "./Colleagues.scss"
import Button from '../../../UI/Button/Button';
import { InputText } from 'primereact/inputtext';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.css';

const Colleagues = (props: any) => {

    const [colleagues, setColleagues] = useState<any>([])
    const [list, setList] = useState<any>([])
    const [selectedCounter, setSelectedCounter] = useState(0)
    const [globalFilter, setGlobalFilter] = useState(null)
    const [page, setPage] = useState<any>(0)
    const [filter, setFilter] = useState<string>("");
    const [totalItems, setTotalItems] = useState()
    const [typing, setTyping] = useState<any>()

    const limit = 10;

    useEffect(() => {

        console.log("[Colleagues] Use Effect", props.usersList)


        if (props.usersList?.items && props.usersList.items.length > 0) {

            setTotalItems(props.usersList.total_items)



            const mergedList = [...props.usersList.items]

            let preset = [...colleagues]

            if (!preset || preset.length === 0) {
                preset = props.presetColleagues.concat(colleagues.filter((item: any) => props.presetColleagues.indexOf(item) < 0))
            }
            setColleagues(preset)
            setSelectedCounter(preset.length)

            for (let i = 0; i < mergedList.length; i++) {
                const element = mergedList[i];
                for (let j = 0; j < preset.length; j++) {
                    const coll = preset[j];
                    if (element.guid === coll) {
                        element.checked = true
                    }
                }
            }


            setList(mergedList)

        }

    }, [props.usersList]);



    // if (!totalItems && props.usersList.total_items) {
    //     setTotalItems(props.usersList.total_items)
    // }
    console.log("[Colleagues] List", list)
    console.log("[Colleagues] Selected", colleagues)
    console.log("[Colleagues] Page", page)
    console.log("[Colleagues] totalItems", totalItems)


    const onCheckboxChange = (index: number) => {

        console.log("[Colleagues] onChange", index, list)
        if (list[index].checked != undefined) {
            let tempArray = [...list];
            tempArray[index].checked = !tempArray[index].checked
            setList(tempArray)
        }
        else {
            let tempArray = [...list];
            tempArray[index].checked = true
            setList(tempArray)
        }

        if (list[index].checked) {
            let newSelectedCounter = selectedCounter + 1
            setSelectedCounter(newSelectedCounter)
            let newSelected: any = []
            newSelected = [...colleagues, list[index].guid]
            setColleagues(newSelected)
        }
        else {
            let newSelectedCounter = selectedCounter - 1
            setSelectedCounter(newSelectedCounter)
            let colleaguesIndex = colleagues.indexOf(list[index].guid)
            let newSelected: any[] = [...colleagues]
            if (colleaguesIndex != -1)
                newSelected.splice(colleaguesIndex, 1)
            setColleagues(newSelected)
        }
    }

    const applyTags = () => {
        props.addColleagues(colleagues)
        props.applyTags()
        setList([])
    }


    const onUserInput = (e: any) => {
        e.persist()
        console.log("[Colleagues] User Input", e.target.value)
        setFilter(e.target.value)

        if (typing) {
            clearTimeout(typing)
        }
        
        let _typing
        if(props.tagging_mode != "all"){
            _typing = setTimeout(() => {
                props.getUserList(page / limit + 1, limit, { fullname: { value: e.target.value },  user_type: {value: props.tagging_mode}})
            }, 500)
        } else {
            _typing = setTimeout(() => {
                props.getUserList(page / limit + 1, limit, { fullname: { value: e.target.value }})
            }, 500)
        }

        setTyping(_typing)

    }

    const statusBodyTemplate = (rowData: any, info: any) => {
        return (
            <div key={rowData.guid} className="colleagueContainer">
                <div className="colleagueWrapper">
                    <div className="colleagueInfo">{rowData.name + " " + rowData.surname}</div>
                    <div className="colleagueInfo"><small>{rowData.email}</small></div>
                    <div className="colleagueInfo"><small>{rowData.user_type}</small></div>
                </div>
                <div className="colleagueCheckbox">
                    <Checkbox className="roundCheckbox"
                        onChange={() => onCheckboxChange(info.rowIndex)}
                        checked={rowData.checked}
                    ></Checkbox>
                </div>
            </div>)
    }

    const renderHeader = () => {
        return (
            <div>
                <div className="searchBar">
                    <FormattedMessage id="input.filterName">
                        {(placeholder: any) =>
                            <InputText className="searchBar" type="search" onInput={onUserInput} placeholder={placeholder} />
                        }
                    </FormattedMessage>
                </div>
                <div className="colleaguesSelected">
                    <span>{selectedCounter} <FormattedMessage id="colleagues.selected" defaultMessage="" /></span>
                </div>
            </div>
        )
    }

    const setPageIndex = (e: any) => {
        setPage(e.first)

        if (filter && filter != "") {
            props.getUserList(e.first / limit + 1, limit, { fullname: { value: filter } })
        }
        else {
            props.getUserList(e.first / limit + 1, limit, {})
        }

    }

    const header = renderHeader();
    return (
        <div style={{ height: '100%' }}>
            <DataTable header={header} value={list} paginator={true} lazy={true} first={page} onPage={setPageIndex} rows={limit} totalRecords={totalItems} globalFilter={globalFilter}>
                <Column body={statusBodyTemplate} />
            </DataTable>
            <Button clicked={applyTags} btntype={"actionButton"}><FormattedMessage id="button.apply" defaultMessage="" /></Button>
        </div>
    )
};

export default Colleagues;
*/