import { HTTP_STATUS, Api } from "../../../api/Api";
import { AddError } from "../toast";
import { CommonApi } from "../../../api/CommonApi";
import { AppState } from "../../../interfaces/AppState";
import { startLogout } from "../auth";
import { ReportAction } from "../../../models/actions/ReportAction";
import { BookingAction } from "../../../models/actions/BookingAction";

export function getReports(...params: any) {
  return (dispatch: any, getState: any) => {
    let [guids, date_start, date_end] = params;

    let appState = (getState() as AppState).app;
    let authState = (getState() as AppState).authReducer;
    dispatch(ReportAction.OnGenericRequest());
    return CommonApi.GetReports({
      date_start: date_start,
      date_end: date_end,
      guids: guids,

    })
      .then((response:any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res:any) => {
        if (res && res.Locations) {
          console.log(res);
          // dispatch(AddSuccess("app.generic.successful"));
          dispatch(ReportAction.OnGenericSuccess(res.Locations));
        }
      })
      .catch((err:any) => {
        console.log(err);
        if(err.toString().includes("access_token")){
          dispatch(startLogout())
        }
        dispatch(ReportAction.OnGenericError());
        dispatch(AddError(err));
      });
  };
}

const switchAppModeOnExportData = (activeApp: string, parameters: any) => {
  console.log("[--- ACTIVE APP ---] ", activeApp)
  switch(activeApp){
    case "COVID":
      return CommonApi.GetCovidTrackerUsageReport(parameters); 
    default: 
      return CommonApi.GetWorkspaceUsageReport(parameters);// By default is WSMAN
  }
}

export const exportData = (...params: any) => {
  if(localStorage.getItem("DEBUG_MODE") == "true"){
    console.log("[EXPORT REPORT DATA] ", params)
  }
  let [isModerator, activeApp, data] = params;
  return (dispatch: any, getState: any) => {
    let userState = (getState() as AppState).authReducer;
    let guid = userState.auth.guid;
    let parameters: any = {};
    
    let companies = [];
    companies.push(userState.auth.selectedCompany);
    parameters.companies = companies;
    //parameters.companies = userState.auth.selectedCompany

    if (!isModerator) {
      //parameters.user_guid = guid;
    }

    if (data) {
      //parameters = data;
      parameters.date_start = data.startDate;
      parameters.date_end = data.endDate;
      parameters.guids = data.locations;
    }


    if(localStorage.getItem("DEBUG_MODE") == "true"){
      console.log("[EXPORT REPORT DATA - PARAMETERS] ", parameters)
    }
    dispatch(ReportAction.OnGenericExportRequest());

    if(localStorage.getItem("DEBUG_MODE") == "true"){
      console.log("[EXPORT REPORT DATA - PARAMETERS] ", parameters)
    }
    dispatch(BookingAction.OnExportRequest());


      return switchAppModeOnExportData(activeApp, parameters)
      .then((response:any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res:any) => {
        if (res) {
          dispatch(ReportAction.OnGenericExportSuccess(res));
          if(localStorage.getItem("DEBUG_MODE") == "true"){
            console.log("[REPORT RESULTS] ", res);
          }

          dispatch(BookingAction.OnExportSuccess());
          if(localStorage.getItem("DEBUG_MODE") == "true"){
            console.log("[REPORT RESULTS] ", res);
          }
        }
      })
      .catch((err:any) => {
        console.log(err);
        dispatch(ReportAction.OnGenericError());
        dispatch(BookingAction.OnExportRequest());
        dispatch(AddError(err));
      }); 
  }
}

export const getLocationReports = (...params: any) => {
  return (dispatch: any, getState: any) => {
    let [guids, date_start, date_end] = params;

    let appState = (getState() as AppState).app;
    let authState = (getState() as AppState).authReducer;
    dispatch(ReportAction.OnGenericRequest());
    return CommonApi.GetLocationReports({
      date_start: date_start,
      date_end: date_end,
      guids: guids,
      companies:[authState.auth.selectedCompany]
    })
      .then((response:any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res:any) => {
        if (res && res.Locations) {
          console.log(res);
          // dispatch(AddSuccess("app.generic.successful"));
          dispatch(ReportAction.OnGenericSuccess(res.Locations));
        }
      })
      .catch((err:any) => {
        console.log(err);
        dispatch(ReportAction.OnGenericError());
        dispatch(AddError(err));
      });
  };
};
