import { Action } from "./Action";
import { Location } from "../Location";
import { LocationUtils } from "../../utils/LocationUtils";
import { ON_GENERIC_ERROR, ON_GET_TIMEZONES_REQUEST, ON_GET_TIMEZONES_SUCCESS, ON_NODE_CHANGE } from "../../store/actions/backoffice/location";

export class LocationAction extends Action {
  response: any;

  constructor(type: string, response?: Location[]) {
    super(type, response);
    this.response = response;
  }

  static GetLocationSuccess = (type: string, response: any[]) => {


    let locations = LocationUtils.GetTreeDataFromFlatData(response);
    
    for (var i in response) {
      
      if (
        response[i].parent_location_guid === null &&
        response[i].guid === "0"
      ) {
        response.splice(parseInt(i), 1);
        break;
      }
    }
    return {
      type: type,
      response: { treeData: locations, list: response },
    };
  };

  static OnNodeChangeRequest = (
    type: string,
    treeData: any[],
    node: any,
    loading?: boolean,
    path?: any
  ) => {
    let newData = [];
    newData = LocationUtils.SetNode(treeData, node, loading, path);

    return {
      type: type,
      response: newData,
    };
  };

  static OnNodeChangeSuccess = (
    type: string,
    treeData: any,
    node: any,
    path?: any
  ) => {
    let newData = LocationUtils.SetNode(treeData, node, false, path);
    return {
      type: type,
      response: newData,
    };
  };
  static OnNodeChangeError = (
    type: string,
    treeData: any,
    node: any,
    path?: any
  ) => {
    let newData = LocationUtils.SetNode(treeData, node, false, path);
    return {
      type: type,
      response: newData,
    };
  };

  static OnNodeTrashSuccess = (
    type: string,
    treeData: any,
    node: any,
    path?: any
  ) => {
    let newData = { ...treeData };

    if (node.deleted === 1) {
      newData = LocationUtils.RemoveNode(treeData, path);
      return {
        type: type,
        response: newData,
      };
    } else {
      let desks:any[] = node.desks_properties
      for(var i in desks){
        if(desks[i].deleted){
          desks.splice(parseInt(i), 1)
        }
      }

      node.desks_properties = desks

      return LocationAction.OnNodeChangeSuccess(
        ON_NODE_CHANGE,
        treeData,
        node,
        path
      );
    }
  };

  static SetTreeDataSuccess = (type: string, response: any) => {
    return {
      type: type,
      response: response,
    };
  };

  static OnGetTimezonesRequest = (payload?: any) => {
    return new LocationAction(ON_GET_TIMEZONES_REQUEST, payload).toJSON();
  };

  static OnGetTimezonesSuccess = (payload?: any) => {
    return new LocationAction(ON_GET_TIMEZONES_SUCCESS, payload).toJSON();
  };

  static OnGenericError = (payload?: any) => {
    return new LocationAction(ON_GENERIC_ERROR, payload).toJSON();
  };

}
