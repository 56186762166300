import React, { useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Icon } from 'semantic-ui-react';
import HomeTracker from '../../tracker/Home/Home';
import NewReservation from '../../tracker/Card/NewReservation/NewReservation';
import logo from '../../../assets/imgs/backoffice/logo-no-claim.png';
import "./SideBar.scss";
import { FormattedMessage } from 'react-intl';
import ModalLogout from '../ModalLogout/ModalLogout';
import { APP } from '../../../interfaces/SwitchState';

const NavigationBar = (props: any) => {
    const [visibleSideBarMenu, setVisibleSideBarMenu] = useState(props.visible);
    const [visibleQrCode, setVisibleQrCode] = useState(props.visible);
    const [openLogoutModal, setOpenLogoutModal] = useState(false);

    useEffect(() => {
        console.log("props.configurationDone SIDEBAR ", props.configurationDone)
    }, [props.configurationDone])

    const handleClickQrCode = () => {
        setVisibleSideBarMenu(false)
        setVisibleQrCode(true)
        return <HomeTracker setVisibleQrCode={visibleQrCode} />
    }

    const handleClickCertification = () => {
        setVisibleSideBarMenu(false)
        return <HomeTracker setVisibleQrCode={false} />
    }

    const handleClickNewReservation = () => {
        setVisibleSideBarMenu(false)
        return <HomeTracker setVisibleQrCode={false} />
    }

    const onClick = (name: any) => {
        console.log("name ", name)

        switch (name) {
            case 'book': {
                props.handleClickNewReservation(true)
                setVisibleSideBarMenu(false)
                break;
            }
            case 'pencil': {
                props.handleClickCertification(true)
                setVisibleSideBarMenu(false)
                break;
            }
            case 'qrcode': {
                props.handleClickQrCode(true)
                setVisibleSideBarMenu(false)
                break;
            }
            case 'myreservations': {
                props.handleClickMyReservation(true)
                setVisibleSideBarMenu(false)
                break;
            }
            default: {
                setVisibleSideBarMenu(false)
                break;
            }
        }

    }

    const onCloseModalLogout = () => {
        setOpenLogoutModal(false);
    }

    return (
        <div>
            <Icon name="bars" size="large" onClick={() => setVisibleSideBarMenu(true)} />
            {
                (visibleSideBarMenu) ?
                    <div>
                        <div>
                            <Sidebar visible={visibleSideBarMenu} baseZIndex={1000000} onHide={() => setVisibleSideBarMenu(false)}>
                                <img src={logo} className="logo" />
                                <div className="menuSidebar">
                                    <div className="line"></div>
                                    <div className="itemMenu" onClick={() => onClick('qrcode')}>
                                        <Icon className="iconColor" name="qrcode" size="large" />
                                        <label className="label">
                                            <FormattedMessage id='app.tab.qrcode' defaultMessage="QRcode" />
                                        </label>
                                    </div>
                                    <div className="line"></div>
                                    {props.configurationDone?.self_certification &&
                                        <>
                                            <div className="itemMenu" onClick={() => onClick('pencil')}>
                                                <Icon className="iconColor" name="pencil" size="large" />
                                                <label className="label">
                                                    <FormattedMessage id='app.tab.certification' defaultMessage="Self-Certification" />
                                                </label>
                                            </div>
                                            <div className="line"></div>
                                        </>
                                    }
                                    {(props.activeApp === APP.WSMANAGER) && (process.env.REACT_APP_MOBILE_BOOKING === "true") ?
                                        <div>
                                            <div className="itemMenu" onClick={() => onClick('book')}>
                                                <Icon className="iconColor" name="book" size="large" />
                                                <label className="label">
                                                    <FormattedMessage id='app.tab.booking' defaultMessage="Booking" />
                                                </label>
                                            </div>
                                            <div className="line"></div>
                                        </div>
                                        : ""}
                                    {(props.activeApp === APP.WSMANAGER) ?
                                        <div>
                                            <div className="itemMenu" onClick={() => onClick('myreservations')}>
                                                <Icon className="iconColor" name="bookmark" size="large" />
                                                <label className="label">
                                                    <FormattedMessage id='app.tab.reservations' defaultMessage="My Reservation" />
                                                </label>
                                            </div>
                                            <div className="line"></div>
                                        </div>
                                    : ""}
                                    <div className="logout" onClick={() => { setOpenLogoutModal(true); setVisibleSideBarMenu(false) }}>
                                        <Icon className="iconColor" name="power off" size="large" />
                                        <label className="label">Log out</label>
                                    </div>
                                </div>
                            </Sidebar>
                        </div>
                    </div>
                    :
                    ""
            }

            <ModalLogout openLogoutModal={openLogoutModal} onClickLogout={props.onClickLogout} onCloseModalLogout={onCloseModalLogout} />
        </div>
    )
};

export default NavigationBar;