import { BookingApi } from "../../../api/BookingApi";
import { BookingState } from "../../../interfaces/BookingState";
import { HTTP_STATUS, Api } from "../../../api/Api";
import { BookingAction } from "../../../models/actions/BookingAction";
import { AddError, AddSuccess, ChangePage } from "../toast";
import { startLogout } from "../auth";
import { BaseReservation, Reservation } from "../../../models/Reservation";
import { AppState } from "../../../interfaces/AppState";
import { User } from "../../../models/User";
import { AppAction } from "../../../models/actions/AppAction";

const googleCalendarConfig = {
  "apiKey": process.env.REACT_APP_GOOGLE_API_KEY,
  "clientId": process.env.REACT_APP_GOOGLE_CLIENTID,
  "scope": "https://www.googleapis.com/auth/calendar",
  "discoveryDocs": ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
}

export const getTimetables = (
  booking_datetime: string,
  location_guid: string,
  book_all_day?: boolean
) => {
  return (dispatch: any, getState: any) => {
    dispatch(BookingAction.OnGenericRequest());
    return BookingApi.GetTimetables({
      booking_datetime: booking_datetime,
      location_guid: location_guid,
      book_all_day: book_all_day ? book_all_day : false,
    })
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          console.log(res);
          dispatch(BookingAction.OnSlotsSuccess(res.slots));
        }
      })
      .catch((err: any) => {
        console.log(err);
        if(err.toString().includes("access_token")){
          dispatch(startLogout())
        }
        dispatch(BookingAction.OnGenericError());
        dispatch(AddError(err));
      });
  };
};

export const exportData = (params: any) => {
  console.log("[BOOKING EXPORT DATA] ", params);
  //let [isModerator, data] = params;
  return (dispatch: any, getState: any) => {
    dispatch(BookingAction.OnExportRequest());
    return BookingApi.GetBookingReport(params)
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          dispatch(BookingAction.OnExportSuccess());
          console.log("[BOOKING RESULTS] ", res);
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(BookingAction.OnGenericError());
        dispatch(BookingAction.OnExportSuccess());
        dispatch(AddError(err));
      });
  }
}

export const getReservations = (...params: any) => {

  console.log("[BOOKING GET RESERVATIONS PARAMS] ", params)
  let [isModerator, data] = params;
  return (dispatch: any, getState: any) => {
    let userState = (getState() as AppState).authReducer;
    let guid = userState.auth.guid;
    let parameters: any = {};

    let tabChange = getState().app.tabChange;

    if (data) {

      // parameters.status_id = status_id;
      parameters = data;

    }

    parameters.company_code = userState.auth.selectedCompany



    if (!isModerator) {
      parameters.user_guid = guid;
    }

    dispatch(BookingAction.OnGenericRequest());
    return BookingApi.GetReservations(parameters)
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          dispatch(BookingAction.OnGetReservationsSuccess(res));
          if (tabChange) {
            dispatch(AppAction.SwitchTabSuccess())
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
        if(err.toString().includes("access_token")){
          dispatch(startLogout())
        }
        dispatch(BookingAction.OnGenericError());
        dispatch(AddError(err));
      });
  };
};

export const changePage = (pageIndex: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(BookingAction.OnGenericRequest());
    dispatch(ChangePage("Pagina Cambiata")); //pageIndex.toString()
  }
}

export const getReservation = (guid: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(BookingAction.OnGenericRequest());
    BookingApi.GetReservation(guid)
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          console.log(res);
          // dispatch(AddSuccess("app.generic.successful"));
          dispatch(BookingAction.OnGetReservationSuccess(res));
        }
      })
      .catch((err: any) => {
        console.log(err);
        if(err.toString().includes("access_token")){
          dispatch(startLogout())
        }
        dispatch(BookingAction.OnGenericError());
        dispatch(AddError(err));
      });
  };
};

export const onBooking = (reservation: BaseReservation, email_array: any, motivation: string, location: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(BookingAction.OnGenericRequest());
    BookingApi.OnBooking(reservation)
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          if (localStorage.getItem("oauth2"))
            console.log("CREATE EVENT ON CALENDAR...")
          if (process.env.REACT_APP_MOBILE_BOOKING === "true")
            createGoogleCalendarEvent(reservation.booking_datetime_start, reservation.booking_datetime_end, email_array, motivation, location);
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          console.log(res);
          dispatch(AddSuccess("app.operation.successful"));
          dispatch(BookingAction.OnGenericSuccess());
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(BookingAction.OnGenericError());
        dispatch(AddError(err));
      });
  };
};

export const updateReservation = (reservation: Reservation, isModerator: boolean, data?: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(BookingAction.OnGenericRequest());
    if (reservation.booking_datetime_start.includes("T")) {
      reservation.booking_datetime_start = reservation.booking_datetime_start.replace(
        "T",
        " "
      );
    }
    if (reservation.booking_datetime_end.includes("T")) {
      reservation.booking_datetime_end = reservation.booking_datetime_end.replace(
        "T",
        " "
      );
    }
    if (reservation.entrance_time.includes("T")) {
      reservation.entrance_time = reservation.entrance_time.replace("T", " ");
    }

    BookingApi.UpdateReservation(reservation)
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          console.log(res);
          dispatch(AddSuccess("app.operation.successful"));
          // dispatch(BookingAction.OnGenericSuccess());
          dispatch(getReservations(isModerator, data));
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(BookingAction.OnGenericError());
        dispatch(AddError(err));
      });
  };
};

export const approveReservations = (guids: any[], isModerator: boolean, data?: any) => {
  return (dispatch: any, getState: any) => {
    let bookingState = (getState() as AppState).booking;
    dispatch(BookingAction.OnGenericRequest());
    dispatch(BookingAction.OnApproveReject(guids, bookingState.list));

    BookingApi.ApproveReservations(guids)
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          console.log("[RESERVATION APPROVED] ", res);
          dispatch(AddSuccess("app.operation.successful"));
          // dispatch(BookingAction.OnGenericSuccess());
          dispatch(getReservations(isModerator, data));
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(BookingAction.OnGenericError());
        dispatch(BookingAction.OnApproveRejectError(guids, bookingState.list));
        dispatch(AddError(err));
      });
  };
};

export const rejectReservations = (guids: any[], isModerator: boolean, data?: any) => {
  return (dispatch: any, getState: any) => {
    let bookingState = (getState() as AppState).booking;
    dispatch(BookingAction.OnGenericRequest());
    dispatch(BookingAction.OnApproveReject(guids, bookingState.list));

    BookingApi.RejectReservation(guids)
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          console.log(res);
          dispatch(AddSuccess("app.operation.successful"));
          // dispatch(BookingAction.OnGenericSuccess());
          dispatch(getReservations(isModerator, data));
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(BookingAction.OnGenericError());
        dispatch(BookingAction.OnApproveRejectError(guids, bookingState.list));
        dispatch(AddError(err));
      });
  };
};

export const deleteReservation = (reservation: Reservation, isModerator: boolean, data?: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(BookingAction.OnGenericRequest());
    BookingApi.DeleteReservation(reservation)
      .then((response: any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        if (res) {
          console.log(res);
          dispatch(AddSuccess("app.operation.successful"));
          // dispatch(BookingAction.OnGenericSuccess());
          dispatch(getReservations(isModerator, data));
        }
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(BookingAction.OnGenericError());
        dispatch(AddError(err));
      });
  };
};

const createGoogleCalendarEvent = (startDate: string, endDate: string, email_array: any, motivation: string, location: string) => {
  console.log("CREATE EVENT ON CALENDAR 3...",startDate, endDate, email_array, motivation, location)
  const script = document.createElement('script');
  script.src = 'https://apis.google.com/js/api.js';
  document.body.appendChild(script);
  script.onload = (): void => {
    window['gapi'].load('client:auth2', () => { initClient(startDate, endDate, email_array, motivation, location) });
  };
}

const initClient = (startDate: string, endDate: string, email_array: any, motivation: string, location: string) => {
  var gapi = window['gapi'];
  let oauth2_token = ""
  let oauth2: any = localStorage.getItem("oauth2")
  if (oauth2) {
    oauth2 = JSON.parse(oauth2)
    oauth2_token = oauth2.oauth2_token
  }
  gapi.client.init(googleCalendarConfig)
  gapi.client.setToken({ access_token: oauth2_token })
  var summary = 'Booking at ' + location + ' for ' + motivation
  var description = 'Booking at ' + location + ' for ' + motivation
  var timeZone = 'Europe/Paris'
  const event = {
    summary: summary,
    description: description,
    start: {
      dateTime: new Date(startDate),
      timeZone: timeZone,
    },
    end: {
      dateTime: new Date(endDate),
      timeZone: timeZone,
    },
    attendees: email_array,
    guestsCanSeeOtherGuests: false
  }
  gapi.client.load('calendar', 'v3', function () {
    var request = {
      path: 'https://www.googleapis.com/calendar/v3/calendars/primary/events',
      method: "POST",
      params: { sendUpdates: "all" },
      body: event
    }
    try {
      gapi.client.request(request).execute(() => {
      })
    } catch (error) {
      console.log(error)
    }
  })

}

