import React, { useEffect, useState } from 'react';
import { Modal, Button as UIButton } from 'semantic-ui-react';
import Button from '../Button/Button';
import { FormattedMessage } from 'react-intl';

const ModalLogout = (props: any) => {

    const onSave = () => {
        props.onClickLogout();
        props.onCloseModalLogout();
    }

    const onCancel = () => {
        props.onCloseModalLogout();
    }

    return (
        <Modal
            open={props.openLogoutModal}
        >
            <Modal.Header>
                <FormattedMessage id="app.generic.notice" defaultMessage="Notice" />
            </Modal.Header>
            <Modal.Content>
                <div style={{fontSize: '18px'}}>
                <FormattedMessage id="app.generic.logout" defaultMessage="Are you sure you want to quit the application?" />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div className="containerButton" style={{marginBottom: '10px'}}>
                    <UIButton loading={props.inlineLoading} style={{marginTop: '1%', width: '100%', boxShadow: 'rgba(71, 71, 71, 0.596) 0 0 10px', background: 'transparent linear-gradient(90deg, rgba(127, 127, 127, 1) 0%, rgba(71, 71, 71, 0.596) 100%) 0% 0% no-repeat padding-box', color: 'white', borderRadius: '5rem', paddingTop: '1.3em', paddingBottom: '1.3em', marginRight: '10px'}}
                        onClick={() => onCancel()}>
                        <FormattedMessage id="app.generic.no" defaultMessage="No" />
                    </UIButton>
                    <UIButton loading={props.inlineLoading} style={{marginTop: '1%', width: '100%', boxShadow: '#27bbcc 0 0 10px', background: 'transparent linear-gradient(90deg, #44ad8c 0%, #27bbcc 100%) 0% 0% no-repeat padding-box', color: 'white', borderRadius: '5rem', paddingTop: '1.3em', paddingBottom: '1.3em'}}
                        onClick={() => onSave()}>
                        <FormattedMessage id="app.generic.yes" defaultMessage="Yes" />
                    </UIButton>
                </div>
            </Modal.Actions>
        </Modal>
    )
}

export default ModalLogout;

/*
style="margin: 0;margin-top: 1%;width: 100%;box-shadow: #27bbcc 0 0 10px;background: transparent linear-gradient(90deg, #44ad8c 0%, #27bbcc 100%) 0% 0% no-repeat padding-box;color: white;border-radius: 5rem;padding-top: 1.3em;padding-bottom: 1.3em;"
*/