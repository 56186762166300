import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'semantic-ui-react';

import SideBar from '../../UI/SideBar/SideBar';
import ModalLogout from '../../UI/ModalLogout/ModalLogout';

import "./HeaderBar.scss";
import { ImFileExcel } from 'react-icons/im';

const HeaderBar = (props: any) => {

    console.log("[HEADER]", props.certificationDone, props.configurationDone)

    const [openLogoutModal, setOpenLogoutModal] = useState(false)

    const backDimConst = window.innerHeight * 0.05
    const backDim = {
        height: backDimConst + "px",
        width: backDimConst + "px"
    }
    const title: any = {
        QrCode: <FormattedMessage id="headerTitle.qrCode" defaultMessage="" />,
        Colleagues: <FormattedMessage id="headerTitle.colleagues" defaultMessage="" />,
        ReservationList: <FormattedMessage id="qrCard.reservation" defaultMessage="" />
    }

    const handleOnClickCertificationList = () => {
        props.onClickCertificationList();
    }

    const handleClickNewReservation = () => {
        props.handleClickNewReservationBack()
        console.log("cliccato handleClickNewReservation")
    }

    const handleClickCertification = () => {
        props.handleClickCertificationBack()
        console.log("cliccato handleClickCertification")
    }

    const handleClickQrCode = () => {
        props.handleClickQrCodeBack()
        console.log("cliccato handleClickQrCode ")
    }

    const handleClickMyReservation = () => {
        props.handleClickMyReservation()
        console.log("cliccato handleClickMyReservation ")
    }


    // const title = props.config.title === 1 ? <FormattedMessage id="headerTitle.qrCode" defaultMessage="" /> : <FormattedMessage id="headerTitle.colleagues" defaultMessage="" />
    const backButton = props.config.back ?
        <Icon onClick={props.onClickBack} circular inverted fitted name="arrow left" />
        :
        <div style={{ marginRight: '20%' }}>
            <SideBar onClickLogout={props.onClickLogout}
                handleClickNewReservation={handleClickNewReservation}
                handleClickCertification={handleClickCertification}
                handleClickQrCode={handleClickQrCode}
                handleClickMyReservation={handleClickMyReservation}
                activeApp={props.activeApp}
                configurationDone={props.configurationDone}
            />
        </div>

    const onCloseModalLogout = () =>{
        setOpenLogoutModal(false);
    }

    return (
        <div className="headerContainer">
            {backButton}
            <div className="headerRight">
                {/*props.certificationDone && (props.configurationDone && props.configurationDone.self_certification) ?

                    <div className="headerRightButton">
                        <i className="icon-cv19-edit" onClick={handleOnClickCertificationList}></i>
                    </div>
                    :  null*/}
                <div className="headerTitle">{title[props.config.title]}</div>
                <div className="headerRightButton">
                    <i className="icon-cv19-logout"
                        onClick={() => setOpenLogoutModal(true)}></i>
                </div>
            </div>
            <ModalLogout openLogoutModal={openLogoutModal} onClickLogout={props.onClickLogout} onCloseModalLogout={onCloseModalLogout}/>
        </div>
    )
};

export default HeaderBar;