import React, { useState, useEffect } from 'react'
import { Tab, Dropdown, Icon, Loader } from 'semantic-ui-react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../../../UI/Button/Button';
import { Button as SUIButton } from 'semantic-ui-react';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { useMediaQuery } from 'react-responsive';


import './Log.scss';
import { GetDateAndTimeByLocale, GetDateFormatByLocale, GetCalendarDateFormatByLocale } from '../../../../../utils/localization/supported-languages';
import { IntlContext } from '../../../../../utils/IntlContext';


const LogTab = (props: any) => {
  const { locale, calendar } = React.useContext(IntlContext);
  const [startDate, setStartDate] = useState<any>(moment().startOf('day').format('YYYY-MM-DD HH:mm').replace("T", " "))
  const [endDate, setEndDate] = useState<any>(moment().endOf('day').format('YYYY-MM-DD HH:mm').replace("T", " "))
  const [_startDate, _setStartDate] = useState(moment().startOf('day').toDate())
  const [_endDate, _setEndDate] = useState(moment().endOf('day').toDate())
  const [table, setTable] = useState<DataTable | undefined>()
  const [firstIndex, setFirstIndex] = useState(0)
  const [perPage, setPerPage] = useState<any>(10)

  const { formatMessage } = props.intl;

  const [orderBy, setOrderBy] = useState<any>()
  const [filters, setFilters] = useState<any>()

  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  useEffect(() => {
    // console.log("USERS", props.users)
    // console.log("LOADING USERS", props.loading)
    if (enableSearchButton()) {
      props.onGetData(startDate, endDate, (firstIndex / perPage) + 1, perPage)
    }
  }, [props.auth?.selectedCompany])


  const onSearch = () => {
    console.log("DATES", startDate, endDate)

    props.onGetData(startDate, endDate, (firstIndex / perPage) + 1, perPage)
  }

  const setDates = (e: any, type: string) => {



    switch (type) {
      case 'start':
        _setStartDate(e.value)
        setStartDate(moment(e.value).format('YYYY-MM-DD HH:mm').replace("T", " "))
        break
      case 'end':
        _setEndDate(e.value)
        setEndDate(moment(e.value).format('YYYY-MM-DD HH:mm').replace("T", " "))
        break
    }

    console.log("DATES", startDate, endDate)

  }

  const enableSearchButton = () => {
    return endDate && startDate
  }

  const onExport = () => {
    props.onExportData({ page: (firstIndex / perPage) + 1, per_page: perPage, created_at_end: endDate, created_at_start: startDate })
    // console.log("[LOG DATA EXPORT DONE]");
    // onSearch()    
    // console.log("[DATA UPLOADED]");
  }

  const headerName = (field: string) => {
    return formatMessage({ id: "log." + field })
  }

  const dateTemplate = (rowData: any, column: any) => {
    return GetDateAndTimeByLocale(locale, rowData[column.field])
  }

  const onPage = (event: any) => {
    setFirstIndex(event.first)
    props.onGetData(startDate, endDate, (event.first / perPage) + 1, perPage)
  }



  return (
    <Tab.Pane className="Logs">
      <div>
        <div className="_flex flex-direction-row justify-content-between my-3">
          <div className={(isMobile) ? "wrapped" : "_flex"}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline', marginRight: '2em' }}>
              <label><FormattedMessage id="log.query.start" defaultMessage="Select start date" /></label>
              <FormattedMessage id="log.query.start" defaultMessage="Select start date">
                {(placeholder: string) => (
                  <Calendar selectionMode="single" className="rounded-input mr-3" placeholder={placeholder} readOnlyInput={true} showTime={true} value={_startDate} onChange={(e: any) => setDates(e, 'start')} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} maxDate={moment().startOf('day').toDate()}></Calendar>
                )}
              </FormattedMessage>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
              <label><FormattedMessage id="log.query.end" defaultMessage="Select end date" /></label>
              <FormattedMessage id="log.query.end" defaultMessage="Select end date">
                {(placeholder: string) => (
                  <Calendar selectionMode="single" className="rounded-input mr-3" placeholder={placeholder} readOnlyInput={true} showTime={true} value={_endDate} onChange={(e: any) => setDates(e, 'end')} dateFormat={GetCalendarDateFormatByLocale(locale)} locale={calendar} minDate={moment().startOf('day').toDate()}></Calendar>
                )}
              </FormattedMessage>
            </div>
            <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
              <Button
                btntype="OK green-bg px-4 w-100 rounded-input"
                clicked={onSearch}
                loading={props.loading}
                disabled={!enableSearchButton()}>
                <FormattedMessage id="app.generic.search" defaultMessage="Search" />
              </Button>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
            <SUIButton onClick={onExport} basic loading={props.loading_export}><FormattedMessage id="app.generic.export" defaultMessage="Export Results" /></SUIButton>
          </div>

        </div>

        <DataTable
          value={props.data}
          paginator={true}
          rows={perPage}
          autoLayout
          lazy={true}
          totalRecords={props.total_items}
          onPage={onPage}
          loading={props.loading}
          first={firstIndex}
          id="biohazard-table"
          ref={(el: any) => { setTable(el); }}
        >

          <Column field="user" header={headerName('user')} />
          <Column field="user_guid" header={headerName('user_guid')} />
          <Column field="action" header={headerName('action')} />
          <Column field="created_at" header={headerName('created_at')} body={dateTemplate} />
          <Column field="updated_at" header={headerName('updated_at')} body={dateTemplate} />
          <Column field="table" header={headerName('table')} />
          <Column field="object_guid" header={headerName('object_guid')} />


        </DataTable>

      </div>
    </Tab.Pane >
  )
}

export default injectIntl(LogTab)