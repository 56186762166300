import { Api, HTTP_STATUS } from "../../../api/Api";
import { BookingApi } from "../../../api/BookingApi";
import { CheckInAction } from "../../../models/actions/CheckInAction";
import { startLogout } from "../auth";

export const getCheckInData = (params: any) => {
    console.log("[CHECKIN DATA] ", params);
    if(params.orderBy !== undefined){
      if(params.orderBy === "user.name"){
        params.orderBy = "name"
      }
      if(params.orderBy === "user.surname"){
        params.orderBy = "surname"
      }
      if(params.orderBy === "user.email"){
        params.orderBy = "email"
      }
    }
    if(params.filters !== undefined){
      if(params.filters["user.name"] !== undefined){ 
        params.filters.name = params.filters["user.name"] 
        delete params.filters["user.name"]
      }
      if(params.filters["user.surname"] !== undefined){
        params.filters.surname = params.filters["user.surname"] 
        delete params.filters["user.surname"]
      }
      if(params.filters["user.email"] !== undefined){
        params.filters.email = params.filters["user.email"] 
        delete params.filters["user.email"]
      }
    }
    
    let parameters: any = {};
    parameters.page = params.page
    parameters.per_page = params.per_page
    parameters.order = params.order
    parameters.orderBy = params.orderBy
    parameters.filters = params.filters
    parameters.date_start = params.startDate
    parameters.date_end = (params.endDate != null) ? params.endDate : params.startDate
    console.log("[CHECKIN API DATA PARAMETERS] ", parameters)
    return (dispatch: any, getState: any) => {
        dispatch(CheckInAction.OnGenericRequest());
        return BookingApi.GetCheckInReport(parameters)
            .then((response:any) => {
              console.log("RES", response);
              if (response.status === HTTP_STATUS.OK) {
                return response.json();
              } else {
                let unauthorized = false;
                let error = "";
                return Api.IsAuthorized(response)
                  .then((text: any) => {
                    error = text;
                  })
                  .catch((err: string) => {
                    error = err;
                    unauthorized = true;
                  })
                  .finally(() => {
                    if (unauthorized) {
                      dispatch(startLogout());
                    }
                    throw new Error(error);
                  });
              }
            })
            .then((res:any) => {
              if (res) {
                let response:any = [];
                res.items.forEach((item:any) => {
                    let userItem:any = {};
                    userItem.user = item.user;
                    userItem.date = item.date;
                    userItem.bookingFlag = item.booking_flag.toString();
                    userItem.location = item.location;
                    response.push(userItem);
                });
                res.items = response;
                console.log("[SUCCESS ON GET CHECKIN DATA] ", res);
                dispatch(CheckInAction.OnGetCheckInSuccess(res));
              }
            })
            .catch((err:any) => {
              console.log("[ERROR ON GET CHECKIN DATA] ", err);
              if(err.toString().includes("access_token")){
                dispatch(startLogout())
              }
              dispatch(CheckInAction.OnGenericError());
            });
        };
}

export const exportData = (params: any) => {
    //console.log("[CHECKIN EXPORT DATA] ", params);
    if(params.orderBy !== undefined){
      if(params.orderBy === "user.name"){
        params.orderBy = "name"
      }
      if(params.orderBy === "user.surname"){
        params.orderBy = "surname"
      }
      if(params.orderBy === "user.email"){
        params.orderBy = "email"
      }
    }
    if(params.filters !== undefined){
      if(params.filters["user.name"] !== undefined){ 
        params.filters.name = params.filters["user.name"] 
        delete params.filters["user.name"]
      }
      if(params.filters["user.surname"] !== undefined){
        params.filters.surname = params.filters["user.surname"] 
        delete params.filters["user.surname"]
      }
      if(params.filters["user.email"] !== undefined){
        params.filters.email = params.filters["user.email"] 
        delete params.filters["user.email"]
      }
    }
    let parameters: any = {};
    parameters.page = params.page
    parameters.per_page = params.per_page
    parameters.filters = params.filters
    parameters.order = params.order
    parameters.orderBy = params.orderBy
    parameters.date_start = params.startDate
    parameters.date_end = (params.endDate != null) ? params.endDate : params.startDate
    //console.log("[CHECKIN EXPORT API DATA PARAMETERS] ", parameters)
    return (dispatch: any, getState: any) => {
        dispatch(CheckInAction.OnGenericRequest());
        return BookingApi.GetCheckInReportExport(parameters)
            .then((response:any) => {
                console.log("RES", response);
                if (response.status === HTTP_STATUS.OK) {
                return response.json();
                } else {
                let unauthorized = false;
                let error = "";
                return Api.IsAuthorized(response)
                    .then((text: any) => {
                    error = text;
                    })
                    .catch((err: string) => {
                    error = err;
                    unauthorized = true;
                    })
                    .finally(() => {
                    if (unauthorized) {
                        dispatch(startLogout());
                    }
                    throw new Error(error);
                    });
                }
            })
            .then((res:any) => {
                dispatch(CheckInAction.OnGenericSuccess(res));
            })
            .catch((err:any) => {
                //console.log("[ERROR ON EXPORT CHECKIN REPORT] ", err);
                dispatch(CheckInAction.OnGenericError());
            });
        };
  }