import React, { useState, useEffect } from 'react';

import QrCode from './QrCode/QrCode';
import Colleagues from './Colleagues/Colleagues';

import "./Card.scss";
import Reservations from './Reservations/Reservations';
import { User } from '../../../models/User';

const Card = (props: any) => {

    const [reservations, setReservations] = useState<any>([])
    console.log("[Card]", props.certificationListDone)

    useEffect(() => {
        console.log("IN CARD", props.usersList)
        if (props.toShow.qrCodeSuccess) {
            props.onSetColleagues()
        }
    }, [props.toShow, props.usersList, props.reservationsList])

    const handleScan = (location: string) => {
        props.onScan(location, props.presetColleagues)
    }

    const cardBody = props.toShow.qrCode ?
        <QrCode
            toShow={props.toShow}
            roles={props.roles}
            handleOnClickCertificationList={props.handleOnClickCertificationList}
            onScan={(location: string) => handleScan(location)}
            certificationListDone={props.certificationListDone}
            acceptCertificationDone={props.acceptCertificationDone}
            onClickAcceptCondition={props.onClickAcceptCondition}
            checkinDone={props.checkinDone}
            isCertificationDone={props.isCertificationDone}
            certificationDone={props.certificationDone}
            configurationDone={props.configurationDone}
            inlineLoading={props.inlineLoading}
        >
        </QrCode> :
        <Colleagues
            presetColleagues={props.presetColleagues}
            usersList={props.usersList}
            getUserList={props.getUserList}
            addColleagues={props.addColleagues}
            applyTags={props.applyTags}
            //tagging_mode={props.configurationDone.tagging_mode}

            onGetUsers={props.onGetUsers}
            usersState={props.usersState}
        >
        </Colleagues>

    return (
        <div className="cardContainer" >
            {cardBody}

            {/* {
                colleagues.length > 0 ?

                    <div className="colleagueSelected">
                        {
                            colleagues.map((user: User, index: number) => (
                                <div>{user.name} {user.surname}</div>
                            ))
                        }
                    </div>
                    :
                    <></>
            } */}

        </div>
    )

};

export default Card