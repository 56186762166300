import React, { useState, useEffect } from 'react';
import AppRouter from './routing/AppRouter';
import packageJson from '../package.json';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import Button from '../src/components/UI/Button/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import { AppState } from './interfaces/AppState';

import './App.scss'
import ToastContainer from './containers/ToastContainer';
import HeaderContainer from './containers/backoffice/HeaderContainer';
import { Common } from './utils/Common';
import 'bootstrap/dist/css/bootstrap.min.css';
import ability from './utils/permissions/ability';


(global as any).appVersion = packageJson.version

const App = (props: any) => {
  const [visible, setVisible] = useState(false)
  const [serviceWorkerUpdate, setServiceWorkerUpdate] = useState(false)
  const [showInstallMessageIOS, setShowInstallMessageIOS] = useState(false)
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator);

  useEffect(() => {
    const rules = localStorage.getItem('rules');
    if (rules) ability.update(JSON.parse(rules));
    console.log('rules: ', rules)

    // Checks if should display install popup notification:
    //&& !isInStandaloneMode()
    if (isIos() && !isInStandaloneMode()) {
      setShowInstallMessageIOS(true);
    }
  }, [])

  useEffect(() => {
    if (props.serviceWorkerReducer !== undefined) {
      console.log('serviceWorkerUpdate: ', props.serviceWorkerReducer.serviceWorkerUpdated)
      if (props.serviceWorkerReducer.serviceWorkerUpdated === true) {
        console.log('serviceWorkerUpdate: ', props.serviceWorkerReducer.serviceWorkerUpdated)
        setServiceWorkerUpdate(props.serviceWorkerReducer.serviceWorkerUpdated)
        setVisible(true)
      }
    }
  }, [props.serviceWorkerReducer])

  const onHide = () => {
    setVisible(false)
    window.location.reload();
    window.location.href = window.location.href;
  }

  const updateServiceWorker = () => {
    window.location.reload()
    let registrationWaiting = props.serviceWorkerReducer.serviceWorkerRegistration.waiting

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' })

      registrationWaiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          window.location.reload();
          window.location.href = window.location.href;
        }
      })
    }
    window.location.reload();
    window.location.href = window.location.href;
    onHide()
  }

  console.log("WORKSPACE MANAGER V" + packageJson.version)
  //console.log("visible: ", visible)
  //console.log("serviceWorkerUpdate: ", serviceWorkerUpdate)

  const onClose = () => {
    setShowInstallMessageIOS(false);
  }

  return (
    <div className="App flex flex-direction-column w-100">
      {/* <CacheBuster /> */}
      <ToastContainer />
      {(Common.IsMobile() || Common.CheckMobileUrl()) ? <></> : <HeaderContainer />}
      <AppRouter />
      {serviceWorkerUpdate ? (
        visible &&
        <Dialog
          header={<FormattedMessage id="app.generic.new_version_available" defaultMessage="New version available!" />}
          visible={true}
          position={'center'}
          modal
          style={{ display: 'flex', justifyContent: 'center' }}
          footer={<Button btntype="green-bg rounded-input" clicked={updateServiceWorker}><FormattedMessage id="app.generic.update" defaultMessage="Update" /></Button>}
          onHide={onClose}
        >
          <div style={{ fontSize: '12px' }}>
            <FormattedMessage id="app.generic.updating_form" defaultMessage="A new version of website is available, please update" />
          </div>
        </Dialog>
      ) : null}
      {
        showInstallMessageIOS && !props.isUserAuthenticated && !props.loading &&
        <Dialog
          header={<FormattedMessage id="app.generic.notice" defaultMessage="Notice" />}
          visible={true}
          position={'bottom'}
          modal
          style={{ display: 'flex', justifyContent: 'center' }}
          footer={<Button btntype="green-bg rounded-input" clicked={onClose} style={{ textSize: '50px' }}><FormattedMessage id="button.close" defaultMessage="Close" /></Button>}
          onHide={onClose}
        >
          <div style={{ fontSize: '12px' }}>
            <FormattedMessage id="app.generic.installIOS_part1" defaultMessage={"Install this webapp on your Iphone: tap"} />
            <i className="icon-cv19-share" />
            <FormattedMessage id="app.generic.installIOS_part2" defaultMessage={"and then Add to homescreen"} />
          </div>
        </Dialog>
      }

    </div>
  );

}

const mapStateToProps = (state: AppState) => ({
  serviceWorkerReducer: state.serviceWorkerReducer,
  isUserAuthenticated: state.authReducer.isUserAuthenticated,
  loading: state.authReducer.loading
});

function mapDispatchToProps(dispatch: any) {

}

export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(injectIntl(App));
