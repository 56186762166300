import { Api } from "./Api";
import { BaseLocation } from "../models/Location";
import { BaseReservation, Reservation } from "../models/Reservation";

export class BookingApi {
  static BASE_ENDP: string = "/booking";
  static BOOKING_LIST: string = BookingApi.BASE_ENDP + "/list";
  static CREATE: string = BookingApi.BASE_ENDP + "/create";
  static UPDATE: string = BookingApi.BASE_ENDP + "/update";
  static TRASH: string = BookingApi.BASE_ENDP + "/delete";
  static APPROVE: string = BookingApi.BASE_ENDP + "/approve";
  static REJECT: string = BookingApi.BASE_ENDP + "/reject";
  static SLOTS: string = BookingApi.BASE_ENDP + "/slots";
  static BOOKING_EXPORT: string = BookingApi.BASE_ENDP + "/export";
  static CHECKIN_REPORT: string = BookingApi.BASE_ENDP + "/checkin/report";
  static CHECKIN_REPORT_EXPORT: string = BookingApi.BASE_ENDP + "/checkin/report/export";

  static GetBookingReport =  (params: any) => {
    return Api.Post(BookingApi.BOOKING_EXPORT, params);
  };

  static GetTimetables = (params: any) => {
    return Api.Post(BookingApi.SLOTS, params);
  };

  static GetCheckInReport = (params: any) => {
    return Api.Post(BookingApi.CHECKIN_REPORT, params);
  };

  static GetCheckInReportExport = (params: any) => {
    return Api.Post(BookingApi.CHECKIN_REPORT_EXPORT, params);
  };

  static GetReservations = (params: any) => {
    
    if (params && params.filters && params.filters.booker_name) {
      params.filters.fullname = params.filters.booker_name;
    } else if (params && params.filters && !params.filters.booker_name) {
      delete params.filters["fullname"];
    }
    return Api.Post(BookingApi.BOOKING_LIST, params);
  };

  static GetReservation = (guid: any) => {
    return Api.Post(BookingApi.BASE_ENDP, { guid: guid });
  };

  static OnBooking = (reservation: any) => {
    let res = { ...reservation };
    delete res["book_all_day"];
    return Api.Post(BookingApi.CREATE, res);
  };

  static UpdateReservation = (reservation: Reservation) => {
    let res = {
      guid: reservation.guid,
      booking_datetime_start: reservation.booking_datetime_start,
      booking_datetime_end: reservation.booking_datetime_end,
      booking_reason: reservation.booking_reason_guid,
      entrance_time: reservation.entrance_time,
      tags: reservation.tags,
    };

    return Api.Put(BookingApi.UPDATE, res);
  };

  static DeleteReservation = (reservation: Reservation) => {
    return Api.Delete(BookingApi.TRASH, { guid: [reservation.guid] });
  };

  static ApproveReservations = (reservations: Reservation[]) => {
    console.log("[API - APPROVE RESERVATION] ", reservations);
    let guids: any[] = [];
    for (var i in reservations) {
      guids.push(reservations[i].guid);
    }
    return Api.Put(BookingApi.APPROVE, { guid: guids });
  };

  static RejectReservation = (reservations: Reservation[]) => {
    let guids: any[] = [];
    for (var i in reservations) {
      guids.push(reservations[i].guid);
    }
    return Api.Put(BookingApi.REJECT, { guid: guids });
  };
  
}
