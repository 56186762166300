import React, { useState, useEffect } from 'react'
import { Tab, Dropdown, Icon, Loader } from 'semantic-ui-react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../../../UI/Button/Button';
import { Button as SUIButton } from 'semantic-ui-react';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';


import './ContactReports.scss';
import { GetDateAndTimeByLocale, GetCalendarDateFormatByLocale } from '../../../../../utils/localization/supported-languages';
import { IntlContext } from '../../../../../utils/IntlContext';
import { AutoComplete } from 'primereact/autocomplete';


const ContactReportsTab = (props: any) => {


  const [startDate, setStartDate] = useState(moment().startOf('day').format('YYYY-MM-DD').split("T")[0])
  const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD').split("T")[0])
  const [guid, setGuid] = useState("")
  const [range, setRange] = useState([moment().startOf('day').toDate(), moment().endOf('day').toDate()])
  const [user, setUser] = useState(undefined)
  const [eventFilter, setEventFilter] = useState<any>()
  const [userSearch, setUserSearch] = useState<any>()
  const [table, setTable] = useState<DataTable | undefined>()
  const { formatMessage } = props.intl;
  const moreOptions: any[] = [{ label: 'app.generic.sendmail', value: 'mail' }]
  const { locale, calendar } = React.useContext(IntlContext);


  useEffect(() => {
    // console.log("USERS", props.users)
    // console.log("LOADING USERS", props.loading)
    if (props.users) {

    }
  }, [props.users])


  const onReload = () => {
    props.onGetData(guid, startDate, endDate)
  }

  const onSearch = () => {
    props.onGetData(guid, startDate, endDate)
  }
  const setDates = (e: any) => {
    console.log("RANGE", e)
    setRange(e.value)

    if (e.value.length > 1) {
      let tmp: any = e.value[0]
      setStartDate(moment(tmp).format('YYYY-MM-DD').split("T")[0])
      let tmp2: any = e.value[1]
      console.log("TMP", tmp, tmp2)
      setEndDate(moment(tmp2).format('YYYY-MM-DD').split("T")[0])


    }
  }

  const enableSearchButton = () => {
    return endDate && startDate && guid !== ""
  }

  const enableExportButton = () => {
    return endDate && startDate && guid !== ""
  }


  const moreOptionsTemplate = (option: any) => {
    return (
      <FormattedMessage id={option.label} defaultMessage={option.label} />
    )
  }

  const sendMail = (mail: string) => {
    window.location.href = 'mailto:' + mail;
  }

  const actionTemplate = (rowData: any, column: any) => {
    return <div>
      {rowData.loading ?
        <Loader active inline size="mini" />
        :
        <FormattedMessage id="app.generic.more" defaultMessage="More">
          {(placeholder: string) => (
            <PrimeDropdown options={moreOptions} placeholder={placeholder}
              onChange={(e: any) => {
                switch (e.value) {
                  case 'mail':
                    sendMail(rowData.email)
                    break
                }
              }}
              appendTo={document.body}
              itemTemplate={moreOptionsTemplate}

            />
          )}
        </FormattedMessage>

      }
    </div>;
  }

  const selectUserTemplate = (option: any) => {
    console.log("OPTION", option)
    return (
      <div key={option.guid}>
        <div>{option.name} {option.surname}</div>
        <div><small style={{ fontSize: '.7em', color: 'gray' }}>{option.email}</small></div>
      </div>
    )
  }

  const onExport = () => {
    props.onExportData({ startDate: startDate, endDate: endDate, guid: guid });
  }


  const onSelectChange = (e: any) => {

    if (e && e.value) {
      setUserSearch(e.value)
      setUser(e.value)
      setGuid(e.value.guid)
    } else {
      setUser(undefined)
      setGuid("")
      setUserSearch("")
    }
  }

  const headerName = (field: string) => {
    return formatMessage({ id: "biohazard.table." + field })
  }


  const dateTemplate = (rowData: any, column: any) => {
    if (!rowData || !rowData[column.field]) return 'NONE'
    return GetDateAndTimeByLocale(locale, rowData[column.field])

  }

  const getFilterPlaceholder = (field: string) => {
    return formatMessage({ id: "biohazard.filters." + field })
  }


  const onEventFilter = (event: any) => {
    let filter = moment(event.value, 'YYYY-MM-DD').format().split("T")[0]
    table?.filter(filter, 'event_timestamp', 'contains')
    setEventFilter(event.value)
  }

  const calendarFilter =
    <Calendar inputClassName="filterCalendar" inputStyle={{ width: '100%', borderRadius: '0px' }} selectionMode="single" placeholder={getFilterPlaceholder('event_timestamp')} style={{ width: '100%' }} value={eventFilter} onChange={onEventFilter} appendTo={document.body} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} />

  const suggestUsers = (event: any) => {
    props.onSearchUsers({ 'fullname': { value: event.query } })
  }

  function itemTemplate(item: any) {
    return (
      <div >
        {/* <b><label>{item.fullname}</label></b>
          <span>{item.email}</span> */}
        <h6>{item.fullname}</h6>
        <p>{item.email}</p>
      </div>
    );
  }

  return (
    <Tab.Pane className="Contacts">
      <div>
        <div className="_flex flex-direction-row justify-content-between my-3">

          <div className="_flex">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
              <label><FormattedMessage id="biohazard.query.select" defaultMessage="Select User" /></label>
              <FormattedMessage id="biohazard.query.select" defaultMessage="Select User">
                {(placeholder: string) => (
                  <FormattedMessage id="biohazard.query.filter" defaultMessage="Search User">
                    {(filterPlaceholder: string) => (
                      <div className="_flex">
                        {/* <PrimeDropdown
                        dataKey="guid"
                        value={user}
                        optionLabel="fullname"
                        options={props.users}
                        onChange={onSelectChange}
                        filter={true}
                        placeholder={placeholder}
                        filterPlaceholder={filterPlaceholder}
                        filterBy="fullname"
                        showClear={true}
                        style={{ width: '17em' }}
                        filterMatchMode="startsWith"
                        className="mr-3 rounded-input query"
                      /> */}

                        <AutoComplete
                          className="_flex mr-3 "
                          value={userSearch}
                          dropdown={true}
                          placeholder={filterPlaceholder}
                          field="fullname"
                          onChange={onSelectChange}
                          suggestions={props.users}
                          completeMethod={suggestUsers}
                          onDropdownClick={suggestUsers}
                          itemTemplate={itemTemplate}
                        />
                      </div>
                    )}
                  </FormattedMessage>
                )}
              </FormattedMessage>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
              <label><FormattedMessage id="biohazard.query.range" defaultMessage="Select a range of dates" /></label>
              <FormattedMessage id="biohazard.query.range" defaultMessage="Select a range of dates">
                {(placeholder: string) => (
                  <Calendar selectionMode="range" className="rounded-input mr-3" placeholder={placeholder} readOnlyInput={true} value={range} onChange={(e: any) => setDates(e)} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)}></Calendar>
                )}
              </FormattedMessage>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
              <Button
                btntype="OK green-bg px-4 w-100 rounded-input"
                clicked={onSearch}
                loading={props.loading}
                disabled={!enableSearchButton()}>
                <FormattedMessage id="app.generic.search" defaultMessage="Search" />
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <SUIButton onClick={onExport} basic loading={props.loading} disabled={!enableExportButton()}><FormattedMessage id="app.generic.export" defaultMessage="Export Results" /></SUIButton>
          </div>

        </div>

        <DataTable
          value={props.data}
          paginator={true}
          rows={10}
          autoLayout
          loading={props.loading}
          id="biohazard-table"
          ref={(el: any) => { setTable(el); }}
          sortMode="multiple"
        >

          <Column
            field="name"
            header={headerName('name')}
            filter={true}
            filterPlaceholder={getFilterPlaceholder('name')}
            sortable={true}
          />

          <Column
            field="surname"
            header={headerName('surname')}
            filter={true}
            filterPlaceholder={getFilterPlaceholder('surname')}
            sortable={true}
          />

          <Column
            field="email"
            header={headerName('email')}
            filter={true}
            filterPlaceholder={getFilterPlaceholder('email')}
            sortable={true}
          />

          <Column
            field="location"
            header={headerName('location')}
            filter={true}
            filterPlaceholder={getFilterPlaceholder('location')}
            sortable={true}
          />

          <Column
            field="event_timestamp"
            header={headerName('event_timestamp')}
            body={dateTemplate}
            filter={true}
            filterElement={calendarFilter}
            sortable={true}
          />

          <Column body={actionTemplate} header={headerName('actions')} style={{ textAlign: 'center', width: '8em' }} />

        </DataTable>

      </div>
    </Tab.Pane >
  )
}

export default injectIntl(ContactReportsTab)