import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash'
import { Button as UIButton, Icon, Label, Tab } from 'semantic-ui-react'
import { Common } from '../../../../utils/Common';
import "./SelfCertification.scss";
import "../Card.scss";
import { Row, Col } from 'react-bootstrap';

const SelfCertification = (props: any) => {

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        console.log("props.acceptCertificationDone ", props.acceptCertificationDone)
    }, [props.acceptCertificationDone])

    useEffect(() => {
        console.log("props.configurationDone ", props.configurationDone)
    }, [props.configurationDone])

    

    useEffect(() => {
        console.log("props.certificationListDone ", props.certificationListDone)
    }, [props.certificationListDone])

    useEffect(() => {
        console.log("props.isCertificationDone ", props.isCertificationDone)
    }, [props.isCertificationDone])

    const handleOnClickCertificationList = () => {
        props.onClickCertificationList();
    }


    return (

        <div className="cardContainer" >
            <div className="qrCardContainer">

                <Tab.Pane style={{ background: (Common.IsMobile() || Common.CheckMobileUrl()) ? "transparent" : "", border: (Common.IsMobile() || Common.CheckMobileUrl()) ? "none" : "" }}>
                    <div className="container" style={{ width: '100%', paddingRight: '0.05em', paddingLeft: '0.05em', marginRight: 'auto',marginLeft: 'auto'}}>

                        <div className="containerElement">


                            <div className={(Common.IsMobile() || Common.CheckMobileUrl()) ? "labelContent" : "labelContentWeb"}>
                                <Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', borderRadius: '20px', marginTop: '10px' }}>

                                    {props.isCertificationDone?.length > 0 ? (
                                        <div >
                                            <Icon className="marginIconLeft" name='check' size='large' />

                                            <div style={{ margin: '1.2em' }}>
                                                <p>
                                                    <small>
                                                        <FormattedMessage id="app.tab.certification.done" defaultMessage="Self-Certification"></FormattedMessage>
                                                    </small>
                                                </p>
                                            </div>

                                        </div>

                                    ) :
                                        (
                                            <div className="qrCardParagraph">
                                                <Icon className="marginIconLeft" name='pencil alternate' size='large' />
                                                <div style={{ margin: '1.2em' }}>
                                                    <p>
                                                        <small>
                                                            Dichiaro di non essere venuto a contatto con persone positive al covid-19 negli ultimi 14 giorni;
                                                            Dichiaro di non presentare i sintomi riconducibili al virus covid-19 definiti dal Ministero della salute.
                                                    </small>
                                                    </p>
                                                </div>

                                                <UIButton loading={props.inlineLoading} className="actionButton" onClick={props.onClickAcceptCondition}><FormattedMessage id="qrCard.certificationAccept" defaultMessage="" /></UIButton>
                                            </div>

                                        )}

                                </Label>
                            </div>

                        </div>
                    </div>
                </Tab.Pane>

            </div>

        </div>

    )
}

export default SelfCertification