import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
//import QrReader from 'react-qr-reader'
import { QrReader } from '@blackbox-vision/react-qr-reader';
import scanImage from '../../../../assets/imgs/tracker/qrCodeScan.svg';
import acquiredCheck from '../../../../assets/imgs/tracker/acquiredCheck.svg';
import scanError from '../../../../assets/imgs/tracker/errorScan.svg';
import acquiredHalfCheck from '../../../../assets/imgs/tracker/acquiredHalfCheck.svg';
import Button from '../../../UI/Button/Button';
import { Button as UIButton } from 'semantic-ui-react'

import "./QrCode.scss";
import { Icon } from 'semantic-ui-react';

export const ViewFinder = (props:any) => {
    return(
        <div style={{top: '0px',left: '0px',boxSizing: 'border-box',border: '50px solid rgba(0, 0, 0, 0.3)',
            boxShadow: 'rgb(255 0 0 / 50%) 0px 0px 0px 5px inset',
            position: 'absolute',
            width: '100%',
            height: '100%', backgroundColor: 'rgba(52, 52, 52, 0)'}} 
            />
    )
}

const QrCode = (props: any) => {
    const [scanning, setScanning] = useState(false);

    console.log("[QR CARD]", props)
    console.log("[QR CARD] acceptCertificationDone", props.acceptCertificationDone)
    console.log("[QR CARD] certificationDone", props.certificationDone)
    console.log("[QR CARD] certificationListDone", props.certificationListDone)
    console.log("[QR CARD] checkinDone", props.checkinDone)
    console.log("[QR CARD] configurationDone", props.configurationDone)
    console.log("[QR CARD] isCertificationDone", props.isCertificationDone)
    console.log("[QR CARD] roles", props.roles)
    console.log("[QR CARD] toShow", props.toShow)

    const toggleQrScan = () => {
        if (props.toShow.qrCodeSuccess) {
            setScanning(scanning)
            return
        }
        if ((!props.isCertificationDone || (props.isCertificationDone && props.isCertificationDone.length === 0)) && props.certificationDone && (props.configurationDone && props.configurationDone.self_certification)) {
            props.handleOnClickCertificationList()
        } else {
            if (!props.toShow.qrCodeSuccess) {
                setScanning(!scanning)
            }
        }
    }

    const handleScan = (data: any) => {
        //if (data) {
            console.log("SCANNED", data)
            setScanning(false)
            props.onScan(data)
        //}
    }
    const handleError = (err: any) => {
        console.log('ERROR SCAN',err)
        setScanning(false)
    }

    const imgSrc = props.toShow.qrCodeSuccess && !props.toShow.qrCodeError ? acquiredCheck :
        !props.toShow.qrCodeSuccess && !props.toShow.qrCodeError ? scanImage :
            scanError;
{/*<QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}

        />
*/}
    const qrCodeReader = scanning && !(props.toShow.certificationView && props.certificationListDone) ?
    
    <QrReader
            scanDelay={300}
            constraints={ {facingMode: 'environment' }}
            ViewFinder={ViewFinder}
            //style={{width:'100%'}}
            onResult={(result: any, error: any) => {
                if (!!result) {
                    handleScan(result.text);
                  }
        
                  if (!!error) {
                    console.log('ERROR SCAN',error)
                  }
            }}
            videoStyle={{mixBlendMode: 'overlay', width: '100%',height: '100%'}}
            videoContainerStyle={{position:'sticky'}}
      />: props.toShow.certificationView && props.certificationListDone ? null :
            <img onClick={toggleQrScan} src={imgSrc} style={{ width: '100%' }} />


    const title = props.toShow.certificationView && props.certificationListDone ? ""
        : props.toShow.qrCodeSuccess && !props.toShow.qrCodeError && !props.toShow.reservationCreateCorrect
            && !props.toShow.reservationDeleteCorrect && !props.toShow.reservationDeleteFailed
            && !props.toShow.reservationCreateFailed ? <FormattedMessage id="qrCard.success" defaultMessage="" /> :
            props.toShow.qrCodeSuccess && props.toShow.reservationCreateCorrect && !props.toShow.qrCodeError ? <FormattedMessage id="qrCard.successCreate" defaultMessage="" /> :
                props.toShow.qrCodeSuccess && props.toShow.reservationDeleteCorrect && !props.toShow.qrCodeError ? <FormattedMessage id="qrCard.successDelete" defaultMessage="" /> :
                    props.toShow.qrCodeSuccess && props.toShow.reservationDeleteFailed && !props.toShow.qrCodeError ? <FormattedMessage id="qrCard.failedDelete" defaultMessage="" /> :
                        props.toShow.qrCodeSuccess && props.toShow.reservationCreateFailed && !props.toShow.qrCodeError ? <FormattedMessage id="qrCard.failedCreate" defaultMessage="" /> :

                            !props.toShow.qrCodeSuccess && !props.toShow.qrCodeError ? <FormattedMessage id="qrCard.title" defaultMessage="" /> :
                                <FormattedMessage id="qrCard.error" defaultMessage="" />;

    const subtitle = props.toShow.certificationView && props.certificationListDone ? props.certificationListDone[0].text :
        props.certificationDone && (props.configurationDone && props.configurationDone.self_certification) && !(props.toShow.qrCodeSuccess && props.toShow.qrCodeError) ? <FormattedMessage id="qrCard.paragraph_no_cert" defaultMessage="" /> :
            !props.toShow.qrCodeSuccess && !props.toShow.qrCodeError ? <FormattedMessage id="qrCard.paragraph" defaultMessage="" /> :
                props.toShow.qrCodeSuccess && props.toShow.qrCodeError ? props.checkinDone.Error : null


    const qrCardBody = props.toShow.qrCode ?
        <div className="cardBody" >
            <div >
                <div onClick={toggleQrScan}>
                    {qrCodeReader}
                </div>
                <p className="qrCardTitle">{title}</p>
                <p className="qrCardParagraph">{subtitle}</p>
                {props.toShow.certificationView && props.certificationListDone ?
                    <UIButton loading={props.inlineLoading} className="actionButton" onClick={props.onClickAcceptCondition}><FormattedMessage id="qrCard.certificationAccept" defaultMessage="" /></UIButton>
                    : null
                }
            </div>
            {/* {!props.toShow.qrCodeError && props.roles.isWsman ?
                (
                    <div className="reservationSection" onClick={props.handleListReservationsClick}
                    >
                        <hr />
                        <div className="reservationButton">
                            <p className="reservationTitle">{reservationTitle}</p>
                            <Icon onClick={props.onClickBack} fitted name="arrow right" />
                        </div>
                    </div>
                ) : null} */}
        </div> : null
    return (
        <div className="qrCardContainer">
            {qrCardBody}
        </div>
    )
};

export default QrCode