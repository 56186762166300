import React, { useState, useEffect } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import './ImportModal.scss';
import { Modal, Header } from 'semantic-ui-react';
import Button from '../../UI/Button/Button';
import Dropzone from 'react-dropzone'
import imageIcon from '../../../assets/imgs/backoffice/csvicon.png';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Link } from 'react-router-dom';
import { Import } from '../../../models/Import';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ImDownload, ImLink, ImSpinner8 } from "react-icons/im";
import { IoCheckmarkCircleOutline, IoCloseCircleOutline } from "react-icons/io5";


const ImportModal = (props: any) => {
    if(localStorage.getItem("DEBUG_MODE") == "true"){
        console.log("MODAL PROPS", props)
    }
    const [file, setFile] = useState<File | undefined>(props.file)
    const { formatMessage } = props.intl;

    const [table, setTable] = useState<DataTable | undefined>()
    const [firstIndex, setFirstIndex] = useState(0)
    const [perPage, setPerPage] = useState<any>(3)
    const [filter, setFilter] = useState("")

    const types: any = {
        USERS: 'users',
        LOCATIONS: 'locations'
    }


    useEffect(() => {
        if (props.modalOpen) {
            getOnGoingTasks()

            if(props.headerText == "users.import")
                setFilter(types.USERS)
            else
                setFilter(types.LOCATIONS)

            props.onGetImports({ filter: "users", page: (firstIndex / perPage) + 1, per_page: perPage})
        }

    }, [props.modalOpen])


    const getOnGoingTasks = () => {
        let type = props.headerText === 'users.import' ? types.USERS : types.LOCATIONS
        props.onGetActiveTasks({ type: type })
    }

    const getTasksLabel = () => {
        let message: string = ''
        let activeMessage: string = formatMessage({ id: 'import.status.active' })
        if (props.settings && props.settings.tasks) {
            let tasks = props.settings.tasks
            let nTask = 0
            for (var i in tasks) {
                if (tasks[i].status_id === Import.StatusCodes.ACTIVE) {
                    nTask++
                }
            }
            message = `${nTask} ${(nTask > 1 ? 'Tasks' : 'Task')} ${activeMessage}`
        }
        return message
    }

    const checkForActiveTasks = () => {
        if (props.settings && props.settings.tasks) {
            
            let tasks = props.settings.tasks
            for (var i in tasks) {
                if (tasks[i].status_id === Import.StatusCodes.ACTIVE) {
                    return true
                }
            }
        }

        return false
    }

    const onChange = (files: File[]) => {
        if (files && files[0]) {
            setFile(files[0])
        }
    }

    const onSave = () => {
        if (file) {
            console.log("[IMPORTMODAL - UPLOAD FILE]", file)
            props.onFileChange(file)
            setFile(undefined)
        }
    }

    const headerName = (field: string) => {
        return formatMessage({ id: "import.table." + field })
      }

      const onPage = (event: any) => {
        setFirstIndex(event.first)
        //props.onGetImportData()
    
        props.onGetImports({ page: (event.first / perPage) + 1, per_page: perPage, filter: filter})
    }

    const outputLinkTemplate = (data:any) => {
        //<a href={data.output_file}><i className="pi pi-download" ></i></a>
        return (
            <div>
                { (data.output_file != "") ? 
                    <a href={data.output_file}><ImDownload/></a>
                :
                <ImDownload/>
                }
            </div>
        );  
    }

    const inputLinkTemplate = (data:any) => {
            //console.log("[input] Column Data =========> ", data)
            return (
                <div>
                    { (data.input_file != "") ? 
                        <a href={data.input_file}><ImDownload/></a>
                    :
                    <ImDownload/>
                    }
                </div>
            );    
    }

    const statusTemplate = (data:any) => {
        //console.log("Column Data =========> ", data)
        let icon:any = null
            switch(data.status){
                case "14":
                    icon = <IoCheckmarkCircleOutline className="green"/>
                    break;
                case "12":
                    icon = <ImSpinner8 className="yellow"/>             
                    break;
                case "13":
                    icon = <ImSpinner8 className="blue"/>
                    break;
                case "15":
                    icon = <IoCloseCircleOutline className="red"/>
                    break;
                default: 
                    break;
            }
            return (
                <div>
                    {/* <i className={icon} ></i> */}
                    {icon}
                </div>
            );    
    }

    return (
        <Modal
            open={props.modalOpen}
            onClose={() => props.onCloseModal()}
            basic
            size='small'
            className="ImportModal"
            centered={true}
        >
            <Header><FormattedMessage id={props.headerText} defaultMessage="Import from File" /></Header>
            <Modal.Content>
                <h3><FormattedMessage id="import.title" defaultMessage="Upload CSV" /></h3>
                {
                    (checkForActiveTasks()) ?

                        <ProgressBar className="my-2" animated={true} label={getTasksLabel()} striped={true} variant="success" now={100} />
                        :
                        ""

                }
                <Dropzone onDrop={acceptedFiles => onChange(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="_flex flex-direction-row justify-content-center align-items-center">
                                    <img src={imageIcon} className="image-icon" alt="test" />
                                    {!file ?
                                        <p><FormattedMessage id="import.message" defaultMessage="Drag and drop some files here, or click to select files" /></p>
                                        :
                                        <p>{file.name}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </Dropzone>

                {(props.headerText == "users.import") ?
                <DataTable  
                    value={props.imports?.list}
                    totalRecords={props.imports?.total_items} 
                    id="import-table"                     
                    paginator={true} 
                    rows={perPage} 
                    autoLayout  
                    lazy={true}
                    loading={props.imports?.imports_loading} 
                    first={firstIndex}
                    ref={(el: any) => { setTable(el); }} 
                    sortMode="multiple"
                    onPage={onPage}
                    className="mt-3"
                    >

                    <Column
                        field="start"
                        header={headerName('start')}
                    />

                    <Column
                        field="end"
                        header={headerName('end')}
                    />

                    <Column
                        field="status"
                        body={statusTemplate}
                        header={headerName('state')}
                    />

                    <Column
                        field="input_file"
                        body={inputLinkTemplate}
                        header={headerName('input')}
                    />  

                    <Column
                        field="output_file"
                        body={outputLinkTemplate}
                        header={headerName('output')}
                    /> 

                </DataTable>
                :
                null }

            </Modal.Content>
            <Modal.Actions>
                <div className="_flex justify-content-between">
                    <Button btntype="green-bg" clicked={props.downloadTemplate} >
                        <div style={{display: 'flex', flexDirection:'row'}}>
                            <i className="icon-cv19-upload mr-3"></i>
                            <FormattedMessage id="app.generic.download_template" defaultMessage="DOWNLOAD TEMPLATE" />
                        </div>
                    </Button>
                    <div className="_flex">
                        <Button btntype="detail-bg" clicked={props.onCloseModal} >   <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" /></Button>
                        <Button btntype="green-bg" clicked={onSave} loading={props.importLoading}>   <FormattedMessage id="app.generic.save" defaultMessage="Save" /></Button>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    )

}

export default injectIntl(ImportModal)  