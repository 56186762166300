import { Route } from "../models/Route";

const RouteConfig = [
    Route.DefaultRoute(),
    Route.LoginRoute(),
    Route.ForgotPasswordRoute(),
    Route.ResetPasswordRoute(),
    Route.NotFoundRoute(),

  ];

  export default RouteConfig