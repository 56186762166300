import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../../interfaces/AppState';
import UsersTab from '../../../components/backoffice/Tabs/Common/Users/Users';
import { getUsers, updateUser, createUser, deleteUser, uploadUsers, getRoles, resetPassword, downloadTemplate } from '../../../store/actions/backoffice/users';
import { UsersProps } from '../../../interfaces/Users';
import { User } from '../../../models/User';
import { UserAction } from '../../../models/actions/UserAction';
import { getActiveTasks, getImports } from '../../../store/actions/backoffice/settings';



const UsersTabContainer = (props: any) => {

    useEffect(() => {
        props.getUsers({ per_page: 10, page: 0 })
        //props.downloadTemplate({ per_page: 10, page: 0 })
    }, [props.authState?.auth?.selectedCompany])

    const onUserChange = (data: any, action: string) => {
        switch (action) {
            case UserAction.CREATE_USER:
                props.onUserCreate(data)
                break
            case UserAction.UPDATE_USER:
                props.onUserUpdate(data)
                break
            case UserAction.DELETE_USER:
                props.onUserDelete(data)
                break
            case UserAction.GET_USERS:
                props.getUsers(data)
                break
        }
    }


    return (
        <div className="h-100">
            <UsersTab
                usersState={props.usersState}
                auth={props.authState?.auth}
                onGetRoles={props.onGetRoles}
                userRoles={props.roles}
                onUserChange={onUserChange}
                onUsersResetPassword={props.onUsersResetPassword}
                onGetActiveTasks={props.getActiveTasks}
                settings={props.settings}
                onImportFile={(file: File, data?: any) => {props.onUsersUpload(file, data)}} //{props.onUsersUpload}
                loading={props.loading}
                closeModal={props.closeModal}
                activeApp={props.activeApp}
                onGetImports={(data: any) => {props.onGetImports(data) }}
                imports={props.settings}
                downloadTemplate = {() => {props.downloadTemplate()}}
            />
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    getActiveTasks: (data?: any) => { dispatch(getActiveTasks(data)) },
    getUsers: (data?: any) => { dispatch(getUsers(data)) },
    onUserUpdate: (user: User) => { dispatch(updateUser(user)) },
    onUserCreate: (user: User) => { dispatch(createUser(user)) },
    onUserDelete: (user: User) => { dispatch(deleteUser(user)) },
    onUsersUpload: (file: File, filterData?:any) => { dispatch(uploadUsers(file, filterData)) },
    onGetRoles: () => { dispatch(getRoles()) },
    onUsersResetPassword: (users: User[]) => { dispatch(resetPassword(users)) },
    onGetImports: (data: any) => { dispatch(getImports(data)) },
    downloadTemplate: () => { dispatch(downloadTemplate()) }
})

const mapStateToProps = (state: AppState) => ({
    loading: state.users.loading,
    usersState: state.users,
    authState: state.authReducer,
    closeModal: state.users.closeModal,
    roles: state.users.roles,
    activeApp: state.app.activeApp,
    settings: state.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UsersTabContainer))
