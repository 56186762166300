import { BaseModel } from "./Base";
import { User } from "./User";

export class BaseReservation extends BaseModel {
  // user_guid: string;
  location_guid: string;
  booker_guid: string;
  include_myself: number;
  booking_datetime_start: string;
  booking_datetime_end: string;
  booking_reason_guid: any;
  entrance_time: string;
  tags: any[];

  // seats:number

  constructor(data?: any) {
    super();

    // this.user_guid = "";
    this.location_guid = "";
    this.booker_guid = "";
    this.booking_datetime_start = "";
    this.booking_datetime_end = "";
    this.entrance_time = "";
    this.booking_reason_guid = "";
    this.include_myself = 1;
    this.tags = [];
    // this.seats = 0

    if (data) {
      this.set(data);
    }
  }
}

export class Reservation extends BaseReservation {
  loading: boolean;
  desk_name: string;
  location_name: string;
  guid: string;
  book_all_day: boolean;

  constructor(data?: any) {
    super(data);
    this.guid = "";
    this.desk_name = "";
    this.location_name = "";
    this.loading = false;
    this.book_all_day = false;
    if (data) {
      this.set(data);
    }
  }
}

export const ReservationStatusNames: any = {
  1: "reservations.status.pending",
  2: "reservations.status.approved",
  3: "reservations.status.rejected",
  4: "reservations.status.more_info",
  5: "reservations.status.cancelled",
  6: "reservations.status.no_show",
  7: "reservations.status.checked_in",
  8: "reservations.status.checkout"
};

export const ReservationStatusIcons: any = {
  1: "icon-cv19-clock",
  2: "icon-cv19-circle-check",
  3: "icon-cv19-x-altx-alt",
  4: "icon-cv19-warning",
  5: "icon-cv19-trash",
  6: "icon-cv19-clock",
  7: "icon-cv19-checkedin",
  8: "icon-cv19-checkout"
};

export const ReservationStatusClasses: any = {
  1: "pending",
  2: "approved",
  3: "rejected",
  4: "info",
  5: "cancelled",
  6: "cancelled_no_show",
  7: "checkedin",
  8: "checkout"
};
