import { Api } from "./Api";
import { BaseLocation } from "../models/Location";

export class LocationApi {
  //per v1 e v2
  static BASE_ENDP: string = "/location";
  static LOCATIONS: string = "/list";
  static CREATE_LOCATION: string = "/create";
  static UPDATE_LOCATION: string = "/update";
  static TRASH_LOCATION: string = "/delete";
  static PRINT_QRS: string = "/qrcode";
  static UPLOAD: string = "/upload_full";
  static TIMEZONES: string = "/timezone/list";
  static UPDATE_MAP: string = "/map/put";
  static GET_MAP: string = "/map/get";
  static DOWNLOAD_TEMPLATE: string = "/location/upload_full/template"
  ////////////////////////////////
  
  //solo per V2
  static AVAILABLE_LOCATION: string = "/location/available_locations";
  static PARENT_LOCATION: string = "/location/parent_locations";
  static MAP_LOCATION: string = "/location/map/get";

  static GetAvailableLocations = (params?:any) => {
    return Api.Post(LocationApi.AVAILABLE_LOCATION, params )
  }

  static GetParentLocation = (params?:any) => {
    return Api.Post(LocationApi.PARENT_LOCATION, params )
  }

  static GetMapLocation = (params?:any) => {
    return Api.Post(LocationApi.MAP_LOCATION, params )
  }

  static DownloadTemplate = () => {
    return Api.Post(LocationApi.DOWNLOAD_TEMPLATE, {});
  };

  ///////////////////////////////////
  // per v1 e v2:

  static GetLocations = (params?:any) => {
    return Api.Post(LocationApi.BASE_ENDP + LocationApi.LOCATIONS, params);
  };

  static GetTimezonesList = (params?:any) => {
    return Api.Post(LocationApi.TIMEZONES, params);
  };

  static CreateLocation = (location: any) => {
    return Api.Post(
      LocationApi.BASE_ENDP + LocationApi.CREATE_LOCATION,
      location
    );
  };

  static UpdateLocation = (location: any) => {
    return Api.Put(
      LocationApi.BASE_ENDP + LocationApi.UPDATE_LOCATION,
      LocationApi.Request(location)
    );
  };

  static TrashLocation = (location: any) => {
    return Api.Delete(
      LocationApi.BASE_ENDP + LocationApi.TRASH_LOCATION,
      LocationApi.DeleteRequest(location)
    );
  };

  static PrintQrs = (locations: any[]) => {
    return Api.Post(LocationApi.BASE_ENDP + LocationApi.PRINT_QRS, {
      Guid_List: locations,
    });
  };

  static UploadLocations = (formData: FormData) => {
    return Api.UploadFile(LocationApi.BASE_ENDP + LocationApi.UPLOAD, formData);
  };

  static UpdateMap = (params?:any) => {
    return Api.Post(LocationApi.BASE_ENDP + LocationApi.UPDATE_MAP, params);
  }

  static GetMap = (params?: any) =>{
    return Api.Post(LocationApi.BASE_ENDP + LocationApi.GET_MAP, params);
  }

  static Request = (data: any) => {
    console.log("[SAVE LOCATION] ", data)
    let locationRequest: any = {
      guid: data.guid,
      name: data.name,
      company_code: data.company_code,
      risk_percentage: data.risk_percentage,
      parent_location_guid: data.parent_location_guid,
      estimated_distance_time: data.estimated_distance_time,
      desk: data.desk,
      deleted: data.deleted,
      available: data.available,
      seats_total: data.seats_total,
      seats_available: data.seats_available,
      seats_busy: data.seats_busy,
      timezone_code: data.timezone_code
    };
    if (data.desks_properties && data.desks_properties.length > 0) {
      locationRequest.desks_properties = [];
      for (var i in data.desks_properties) {
        locationRequest.desks_properties.push(
          LocationApi.Request(data.desks_properties[i])
        );
      }
    }

    return locationRequest;
  };
  static DeleteRequest = (data: any) => {
    let locationRequest: any = {
      guid: data.guid,
      deleted: data.deleted,
    };


    if (data.desks_properties && data.desks_properties.length > 0) {
      locationRequest.desks_properties = [];
      for (var i in data.desks_properties) {
        locationRequest.desks_properties.push(
          LocationApi.DeleteRequest(data.desks_properties[i])
        );
      }
    }

    return locationRequest;
  };
}
