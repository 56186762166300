import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LUActions } from '../../../../../utils/LocationUtils';
import Button from '../../../../UI/Button/Button';
import { Button as UIButton, Icon, Dropdown, Search, Loader, Dimmer } from 'semantic-ui-react';
import Input from '../../../../UI/Input/Input';
import { Input as UInput } from 'semantic-ui-react';
import { Location } from '../../../../../models/Location';
import { Slider } from 'primereact/slider';
import { Row, Col } from 'react-bootstrap';
import { BaseReservation, Reservation } from '../../../../../models/Reservation';
import { AuthState } from '../../../../../interfaces/AuthState';
import { AutoComplete } from 'primereact/autocomplete';
import { User } from '../../../../../models/User';
import _ from 'lodash'
import "./LocationReservation.scss";
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { IntlContext } from '../../../../../utils/IntlContext';
import { Common } from '../../../../../utils/Common';
import { Checkbox } from 'primereact/checkbox';
import { GetDateAndTimeByLocale } from '../../../../../utils/localization/supported-languages';
import { IoCloseOutline } from 'react-icons/io5';






const LocationReservation = (props: any) => {

    const [reservation, setReservation] = useState<any>(
        new BaseReservation()
    )
    const [motivations, setMotivations] = useState([])
    const [chosenMotivation, setChosenMotivation] = useState<any>()
    const [owners, setOwners] = useState<any>([])
    const [userSuggestions, setUserSuggestions] = useState<any>([])
    const [suggested, setSuggested] = useState<any>()
    const [suggesting, setSuggesting] = useState<any>()
    const [startDate, setStartDate] = useState<any>(new Date())
    const [endDate, setEndDate] = useState<any>(new Date())
    const [time, setTime] = useState<any>("00:00")
    const [dateModalOpened, setDateModalOpened] = useState(false)
    const [timeModalOpened, setTimeModalOpened] = useState(false)
    const [slots, setSlots] = useState<any>([])
    const [desks, setDesks] = useState<any>([])
    const [slot, setSlot] = useState<any>()
    const [hour, setHour] = useState<any>()
    const [_me, setMe] = useState<User | any>()
    const [location, setLocation] = useState<any>()
    const [chosenLocation, setChoseLocation] = useState<any>()
    const [editing, setEditing] = useState(false)
    const [seatsAvailable, setSeatsAvailable] = useState(0)
    const [invalidDates, setInvalidDates] = useState<any>([])
    const { formatMessage } = props.intl;
    const { switchToLanguage, locale, calendar } = React.useContext(IntlContext);
    const [node, setNode] = useState()
    const [motivationDropdown, setMotivationDropdown] = useState()
    const [currentSlot, setCurrentSlot] = useState()
    const [userSearch, setUserSearch] = useState<any>()

    useEffect(() => {

        console.log("RESERVATION", reservation)

        // Init id utente
        let _reservation: any = { ...reservation }
        let me = new User()
        if (location !== props.selectedNode.guid) {
            setLocation(props.selectedNode.guid)
            _reservation = new BaseReservation
            me.set(props.authState.auth).setFullname()
            setMe(me)
            _reservation.booker_guid = me.guid
            if (owners.length === 0) {
                let _owners = [...owners]
                _owners.push({ text: me.fullname, value: me.guid, key: me.guid })
                setOwners(_owners)
            }
            clearReservationCreation()
            
            setReservation(_reservation)
        }


        if ((props.authState && !_me) || (props.selectedNode && props.selectedNode.guid !== location)) {

            //Clear content if location changes

            if (location !== props.selectedNode.guid) {



                // _reservation.location_guid = props.selectedNode.guid
                let _desks: any[] = []
                for (var i in props.selectedNode.desks_properties) {
                    let desk = props.selectedNode.desks_properties[i]
                    if (desk.available) {

                        _desks.push({ value: desk.guid, text: desk.name, key: desk.guid })
                    }
                }
                setDesks(_desks)
            }

            


            //Init reservation
            setReservation(_reservation)

        }

        //download delle motivazioni

        if ((!props.settings || (props.settings.motivations.length === 0 || props.settings.entrance.guid === '')) && !props.settings.loading) {
            if (props.settings.motivations.length === 0) {
                props.onGetMotivations()
            }
            if (props.settings.entrance.guid === '' || _reservation.book_all_day === undefined) {
                props.onGetEntrance()
            }
        } else {
            // init motivazioni
            let tmpMotivations: any = []
            for (var i in props.settings.motivations) {
                tmpMotivations.push({ key: i, text: props.settings.motivations[i].text, value: props.settings.motivations[i].guid })
            }
            setMotivations(tmpMotivations)


            if (props.settings.entrance.guid !== '') {

                _reservation.book_all_day = props.settings.entrance.book_all_day ? props.settings.entrance.book_all_day : _reservation.book_all_day

                setReservation(_reservation)
            }
        }

        //init download list utenti
        if (!props.usersState || (props.usersState.items.length === 0 && !props.usersState.loading)) {
            // props.onGetUsers({ page: 1, per_page: 10 })
            if (!suggested || suggested.length === 0) {
                setUserSuggestions(undefined)

            }

        } else {

            let suggestions: any[] = [...props.usersState.items]
            suggestions = suggestions.filter((user: User) => owners.some((filter: User) => filter.guid !== user.guid) && user.guid !== _me.guid);
            console.log("SUGGESTIONS", suggestions)

            for (var i in suggestions) {
                let found = false
                for (var x in reservation.tags) {
                    if (reservation.tags[x].email === suggestions[i].email) {
                        found = true
                        break
                    }
                }
                if (!found) {
                    suggestions[i].title = suggestions[i].fullname
                    suggestions[i].description = suggestions[i].email
                    delete suggestions[i]['setLoading']
                } else {
                    suggestions.splice(parseInt(i), 1)
                }

            }

            setUserSuggestions(suggestions)

        }

        if (props.reservation && props.reservation.guid !== '' && !reservation.guid) {
            console.log('ENTROOO')
            setReservation(props.reservation)
            if (props.reservation.desk_seats_available) {
                setSeatsAvailable(props.reservation.desk_seats_available)
            }
            console.log("Editing reservation", props.reservation)
            let startDate: any = moment(new Date(props.reservation.booking_datetime_start)).format("YYYY-MM-DD HH:mm:ss")
            let endDate: any = moment(new Date(props.reservation.booking_datetime_end)).format("YYYY-MM-DD HH:mm:ss")
            let h = moment(props.reservation.booking_datetime_start).get('hours')
            setHour(h)
            setSlot({ hour: h })
            setStartDate(startDate)
            setEndDate(endDate)
            setEditing(true)
            setDateAndTime()
        }

        setSlots(props.bookingState?.slots)
        if (props.bookingState?.slots?.length === 1 && !props.bookingState?.loading) {
            let available = seatsAvailable - props.bookingState.slots[0].seats_busy
            if (invalidDates.indexOf(startDate) === -1 && !available) {
                invalidDates.push(startDate)
            }
        }

        console.log("props.usersState ", props.usersState)

    }, [props.selectedNode, props.settings, props.usersState, props.authState, props.bookingState, props.reservation])





    const set = (value: any, prop: string) => {
        let _reservation: any = { ...reservation }
        
        switch (prop) {
            case 'location_guid':
                _reservation.location_guid = value
                let desk: any = undefined
                for (var i in props.selectedNode.desks_properties) {
                    if (props.selectedNode.desks_properties[i].guid === _reservation.location_guid) {
                        desk = props.selectedNode.desks_properties[i]
                    }
                }
                if (desk) {
                    setSeatsAvailable(desk.seats_available)
                }
                break
            case 'book_all_day':
                _reservation.book_all_day = value
                if (value) {
                    let string = moment(startDate, 'yyyy-MM-DD').format()
                    props.onGetTimetables(string.split('T')[0], reservation.location_guid, true)
                } else {
                    setInvalidDates([])
                }
                break
            case 'include_myself':
                _reservation.include_myself = value ? 1 : 0
                break
            case 'booking_datetime_start':
                let _startDate: any = moment(startDate, 'yyyy-MM-DD HH:mm:ss').startOf('day').add(hour, 'hours').format()
                let _endDate: any = moment(startDate, 'yyyy-MM-DD HH:mm:ss').startOf('day').add(hour + 1, 'hours').format()

                _reservation.entrance_time = _reservation.booking_datetime_start = Common.FormatDateAndHourFE(_startDate)
                _reservation.booking_datetime_end = Common.FormatDateAndHourFE(_endDate)

                break
            case 'booking_datetime_daily':

                let dailyStartDate: any = moment(startDate, 'yyyy-MM-DD HH:mm:ss').startOf('day').add(9, 'hours').format()
                let dailyEndDate: any = moment(startDate, 'yyyy-MM-DD HH:mm:ss').startOf('day').add(18, 'hours').format()
                if (props.settings && props.settings.entrance.guid !== '') {
                    let first_entrance = moment(props.settings.entrance.first_entrance, 'yyyy-MM-DD HH:mm:ss').get('hours')
                    let last_entrance = moment(props.settings.entrance.last_entrance, 'yyyy-MM-DD HH:mm:ss').get('hours') + 1
                    dailyStartDate = moment(startDate, 'yyyy-MM-DD HH:mm:ss').set('hour', first_entrance).format()
                    dailyEndDate = moment(startDate, 'yyyy-MM-DD HH:mm:ss').set('hour', last_entrance).format()

                }
                _reservation.entrance_time = _reservation.booking_datetime_start = Common.FormatDateAndHourFE(dailyStartDate)
                _reservation.booking_datetime_end = Common.FormatDateAndHourFE(dailyEndDate)

                break
            case 'tags':
                _reservation.tags.push(value)
                let _owners = [...owners]
                let found = _owners.find((user: any) => user.guid === value.guid)
                if (!found) {
                    _owners.push({ value: value.guid, key: value.guid, text: value.fullname })
                }

                setOwners(_owners)
                break
            default:
                _reservation[prop] = value
                break

        }

        setReservation(_reservation)
    }

    const removeTag = (tag: any) => {
        let _reservation: BaseReservation = new BaseReservation().set(reservation)
        let _owners = [...owners]

        if (tag.guid === _me.guid) {
            _reservation.include_myself = 0
        } else {
            _owners = _owners.filter((user: any) => tag.guid !== user.key)
        }

        for (var i in _reservation.tags) {
            let _tag = _reservation.tags[i]
            if (tag.guid === _tag.guid) {
                _reservation.tags.splice(parseInt(i), 1)
                break
            }
        }

        setReservation(_reservation)
        setOwners(_owners)
    }


    const suggestUsers = (event: any) => {
        if (event.query.length > 0) {
            props.onGetUsers({ page: 1, per_page: 10, filters: { fullname: { value: event.query } } })
        }
    }



    const onSelectChange = (e: any) => {

        if (e && e.value) {
            setUserSearch(e.value)
            setSuggested(e.value)
        } else {
            setUserSearch("")
            setSuggested("")
        }
    }


    const onSuggestionSelection = (e: any) => {
        set(e.value, 'tags')
    }

    const suggestUser = (e: any, { value }: any) => {
        if (value && value.length > 3) {

            props.onGetUsers({ page: 1, per_page: 10, filters: { fullname: { value: value } } })
        } else {
            setUserSuggestions(undefined)
        }

        setSuggested(value)

        // const re = new RegExp('^' + _.escapeRegExp(value), 'i')
        // const isMatch = (result: any) => re.test(result.fullname)
        // let suggestions = _.filter(props.usersState.list, isMatch)

        // suggestions = suggestions.filter((user: User) => owners.some((filter: User) => filter.guid !== user.guid) && user.guid !== _me.guid);


        // console.log("SUGGESTIONS", suggestions)

        // for (var i in suggestions) {
        //     let found = false
        //     for (var x in reservation.tags) {
        //         if (reservation.tags[x].email === suggestions[i].email) {
        //             found = true
        //             break
        //         }
        //     }
        //     if (!found) {
        //         suggestions[i].title = suggestions[i].fullname
        //         suggestions[i].description = suggestions[i].email
        //         delete suggestions[i]['setLoading']
        //     } else {
        //         suggestions.splice(parseInt(i), 1)
        //     }

        // }



        // setUserSuggestions(suggestions)
    }

    const dateChanged = (e: any) => {
        console.log("INVALID DATES", invalidDates)
        setStartDate(e.value);
        setEndDate(e.value);
        getDailyTimetables(e.value)
        setHour(undefined)
        setSlot(undefined)
    }

    const openTimeModal = () => {
        setTimeModalOpened(true)
        let string = moment(startDate, 'yyyy-MM-DD').format()
        props.onGetTimetables(string.split('T')[0], reservation.location_guid)
    }


    const selectSlot = () => {
        if (slot) {
            setHour(slot.hour)
            setTimeModalOpened(false)
        } else {
            // props.onError()
        }

    }

    const setDateAndTime = () => {

        if (slot && startDate) {
            set(null, 'booking_datetime_start')
            setDateModalOpened(false)
        } else if (reservation.book_all_day) {
            set(null, 'booking_datetime_daily')
            setDateModalOpened(false)
        }

    }

    const onBooking = () => {
        let r = new BaseReservation().set(reservation)
        let array: any = []
        let email_array: any = []
        for (var i in r.tags) {
            array.push({ guid: r.tags[i].guid })
            email_array.push({ email: r.tags[i].email})
        }
        r.tags = array
        props.onBooking(r,email_array,chosenMotivation,chosenLocation)
    }

    const cancelSlotSelection = () => {
        setSlot(undefined)
        setTimeModalOpened(false)
        setInvalidDates([])
    }
    const openDateModal = () => {

        if (reservation.location_guid !== '') {

            getDailyTimetables()
            setDateModalOpened(true)
        } else {
            props.onError({ message: formatMessage({ id: 'app.wsmanager.location.booking.no_desk_selected' }) })
        }
    }

    const getDailyTimetables = (date?: any) => {
        let tmp = date ? date : startDate

        if (props.settings?.entrance.book_all_day || reservation.book_all_day) {
            let string = moment(tmp, 'yyyy-MM-DD').format()
            props.onGetTimetables(string.split('T')[0], reservation.location_guid, reservation.book_all_day)
            return true
        }

        return false
    }

    const cancelDateSelection = () => {
        if (!editing) {
            setStartDate(new Date())
            setEndDate(new Date())
            setHour(undefined)
        } else {
            let startDate: any = moment(new Date(props.reservation.booking_datetime_start)).format("YYYY-MM-DD HH:mm:ss")
            let endDate: any = moment(new Date(props.reservation.booking_datetime_end)).format("YYYY-MM-DD HH:mm:ss")
            let h = moment(props.reservation.booking_datetime_start).get('hours')
            setHour(h)
            setStartDate(startDate)
            setEndDate(endDate)
        }

        setDateModalOpened(false)
    }

    const clearReservationCreation = () => {
        setHour(undefined)
        setSlot(undefined)
        setTimeModalOpened(false)
        setDateModalOpened(false)
        setReservation(undefined)
        setStartDate(new Date())
        setEndDate(new Date())
        setSeatsAvailable(0)
        setInvalidDates([])
        setDesks([])

    }

    const disableBookingCreate = () => {
        return reservation.booker_guid === ""
            || reservation.location_guid === ""
            || reservation.booking_datetime_start === ""
            || reservation.booking_datetime_end === ""
            || reservation.booking_reason_guid === ""
            || (reservation.tags && reservation.tags.length === 0 && !reservation.include_myself)
    }



    const isDeskBusy = (slot: any) => {
        return seatsAvailable - slot.seats_busy <= 0
    }

    const getDateAndTime = () => {
        if (reservation.booking_datetime_start && reservation.booking_datetime_start !== '') {
            return GetDateAndTimeByLocale(locale, reservation.booking_datetime_start)
        }
        return ''
    }

    const setDateAndTimeDisabled = () => {
        return (!reservation.book_all_day && (!hour || !startDate)) || (reservation.book_all_day && invalidDates.indexOf(startDate) !== -1)
    }

    const isReadonly = () => {
        return (props.reservation && props.reservation !== undefined)
    }

    function itemTemplate(item: any) {
        return (
            <div >
              <h6>{item.fullname}</h6>
              <p>{item.email}</p>
            </div>
        );
      }

    return (
        <div className={`LocationReservation _flex h-100 detail-tab flex-direction-column ${props.selectedNode.guid !== "" ? "active" : ""} ${props.wrapperClass}`} style={{ background: '#efeeef' }}>
            <div>
                {
                    !props.reservation ?
                        <div className="detail-title">
                            <IoCloseOutline className="icon-close" onClick={props.onCloseDetails}/>
                            <h2><FormattedMessage id="app.wsmanager.location.booking.title" defaultMessage="New Reservation" /></h2>
                        </div>
                        :
                        ""
                }
                <div className={`detail-form justify-content-center ${isReadonly() ? 'no-border' : ''}`}>

                    {
                        !isReadonly() ?
                            <div className="_flex w-100 flex-direction-column input-with-label mt-4">
                                
                                {(props.linkToMap!='') ? <Button
                            btntype="OK green-bg px-4 w-100 rounded-input my-3"
                            clicked={(e: any) => { 
                            window.open(props.linkToMap)
                        }}
                            loading={props.selectedNode.loading}>
                            <FormattedMessage id="app.generic.download_map" defaultMessage="Get" />
                        </Button> : ""}
                                
                                <label className="_flex">
                                    <FormattedMessage id="app.wsmanager.location.booking.desk_selection" defaultMessage="Select Desk" />
                                </label>
                                <FormattedMessage id="app.wsmanager.location.booking.desk_selection" defaultMessage="Select Desk">
                                    {(placeholder: string) => (
                                        <Dropdown
                                            dataKey="guid"
                                            placeholder={placeholder}
                                            selection
                                            className="my-2 rounded-input w-100"
                                            options={desks}
                                            onChange={(e: any, data: any) => {
                                                console.log(e, data)
                                                set(data.value, 'location_guid')
                                                let tmpLocation=""
                                                data.options.map((opt: any)=>{
                                                    if (opt.value===data.value){
                                                        tmpLocation=opt.text
                                                    }
                                                })
                                                setChoseLocation(tmpLocation)
                                            }}
                                            value={reservation.location_guid}
                                        />

                                    )}
                                </FormattedMessage>
                            </div>
                            :
                            ""
                    }
                    <div className="_flex w-100 flex-direction-column input-with-label  mt-4" >
                        <label className="_flex">
                            <FormattedMessage id="app.wsmanager.location.booking.motivation_selection" defaultMessage="Select Motivation" />
                        </label>
                        <FormattedMessage id="app.wsmanager.location.booking.motivation_selection" defaultMessage="Select Motivation">
                            {(placeholder: string) => (
                                <Dropdown
                                    dataKey="guid"
                                    clearable={true}
                                    placeholder={placeholder}
                                    selection
                                    disabled={isReadonly()}
                                    loading={props.settings ? props.settings.loading : false}
                                    className="my-2 rounded-input w-100"
                                    options={motivations}
                                    onChange={(e: any, data: any) => {
                                        console.log(e, data)
                                        let tmpMotivation=""
                                                data.options.map((opt: any)=>{
                                                    if (opt.value===data.value){
                                                        tmpMotivation=opt.text
                                                    }
                                                })
                                        setChosenMotivation(tmpMotivation)
                                        set(data.value, 'booking_reason_guid')
                                    }}
                                    value={reservation.booking_reason_guid}
                                />

                            )}
                        </FormattedMessage>
                    </div>
                    <div className="_flex w-100 flex-direction-column input-with-label mt-4">
                        <label className="_flex">
                            <FormattedMessage id="app.wsmanager.location.booking.datetime_selection" defaultMessage="Select Date & Time" />
                        </label>
                        <FormattedMessage id="app.wsmanager.location.booking.datetime_selection" defaultMessage="Select Date & Time">
                            {(placeholder: string) => (
                                <Input elementType="input"
                                    fluid
                                    disabled={props.settings?.entrance.guid === '' || isReadonly()}
                                    placeholder={placeholder}
                                    readnly={true}
                                    elementConfig={{ onClick: (e: any) => { openDateModal() } }}
                                    type="text"
                                    containerClass="ui input w-100"
                                    className="my-2 rounded-input w-100"
                                    value={getDateAndTime()}></Input>
                            )}
                        </FormattedMessage>

                    </div>
                </div>


                <div className="detail-title">
                    <h2><FormattedMessage id="app.wsmanager.location.booking.tag" defaultMessage="Tag By Name" /></h2>
                </div>
                <div className="detail-form justify-content-center">
                    <div className="_flex w-100 flex-direction-column input-with-label" style={{}}>
                        <label className="_flex">
                            <FormattedMessage id="app.wsmanager.location.booking.owner_selection" defaultMessage="Select Owner" />
                        </label>
                        <FormattedMessage id="app.wsmanager.location.booking.owner_selection" defaultMessage="Select Owner">
                            {(placeholder: string) => (
                                <Dropdown

                                    dataKey="guid"
                                    placeholder={placeholder}
                                    selection
                                    disabled={isReadonly()}
                                    className="my-2 rounded-input w-100"
                                    options={owners}
                                    onChange={(e: any, data: any) => {
                                        console.log(e, data)
                                        set(data.value, 'booker_guid')
                                    }}
                                    value={reservation.booker_guid}
                                />

                            )}
                        </FormattedMessage>
                    </div>
                    <div className="_flex w-100 flex-direction-column input-with-label mt-3" >
                        {/* <label className="_flex">
                            <FormattedMessage id="app.wsmanager.location.booking.users_selection" defaultMessage="Search names" />
                        </label> */}
                        {/* <Search
                            loading={props.usersState ? props.usersState.loading : false}
                            onResultSelect={(e: any, res: any) => { set(res.result, 'tags') }}
                            onSearchChange={_.debounce(suggestUser, 500, {
                                leading: true,
                            })}
                            results={userSuggestions}
                            value={suggested}
                        /> */}

                        {/* <AutoComplete className="_flex mr-3 " 
                            value={suggested} 
                            dropdown={true} 
                            field="fullname" 
                            onChange={onSelectChange}
                            onSelect={onSuggestionSelection} 
                            disabled={isReadonly()}
                            suggestions={userSuggestions} 
                            completeMethod={suggestUsers} 
                            onDropdownClick={suggestUsers} 
                        /> */}

                        <FormattedMessage id="app.wsmanager.location.booking.users_selection" defaultMessage="Search names">
                            {(placeholder: string) => (
                                <FormattedMessage id="biohazard.query.filter" defaultMessage="Search User">
                                    {(filterPlaceholder: string) => (
                                        <div className="_flex">
                                            <AutoComplete 
                                                    className="_flex mr-3 " 
                                                    value={userSearch} 
                                                    dropdown={true} 
                                                    placeholder={filterPlaceholder} 
                                                    field="fullname" 
                                                    onChange={onSelectChange}
                                                    onSelect={onSuggestionSelection} 
                                                    suggestions={userSuggestions} 
                                                    completeMethod={suggestUsers} 
                                                    onDropdownClick={suggestUsers}
                                                    itemTemplate={itemTemplate}
                                            />
                                        </div>
                                    )}
                                </FormattedMessage>
                            )}
                        </FormattedMessage>
                    </div>

                    <div className="_flex w-100 flex-direction-row input-with-label mt-4" style={{}}>
                        <Checkbox className="_flex mr-3" onChange={e => set(e.checked, 'include_myself')} checked={reservation.include_myself === 1} disabled={isReadonly()}></Checkbox>
                        <label className="_flex">
                            <FormattedMessage id="app.wsmanager.location.booking.include_me" defaultMessage="Include Myself" />
                        </label>
                    </div>
                    {
                        reservation.tags?.map((user: any, i: number) => (

                            <div key={i}>
                                <div className="card desk">
                                    <div className={`desk-title align-items-center justify-content-between `} >
                                        <div className="_flex align-items-center h-100 container" style={{ flex: 1 }}>
                                            <i className="icon-cv19-user"></i> <h5>{user.fullname}</h5>
                                        </div>
                                        {!isReadonly() ? <i className="icon-cv19-close input-icon right" onClick={(e: any) => removeTag(user)}></i> : ""}
                                    </div>
                                </div>
                            </div>


                        ))
                    }
                </div>
                {
                    !isReadonly() ?
                        <div className="_flex w-100" style={{ marginBottom: '4.5em' }}>
                            <Button
                                btntype="OK detail-bg px-4 w-100 rounded-input my-3"
                                clicked={(e: any) => { props.onClearNode() }}
                            >
                                <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button
                                btntype="OK green-bg px-4 w-100 rounded-input my-3"
                                clicked={(e: any) => { onBooking() }}
                                loading={props.bookingState?.loading}
                                disabled={disableBookingCreate()}
                            >
                                <FormattedMessage id="app.generic.save" defaultMessage="Save" />
                            </Button>
                        </div>
                        :
                        ""
                }

                <div className={`calendar h-90 w-100 ${dateModalOpened ? 'opened' : ''}`}>
                    <div className="card mx-4 my-2 h-100 w-100 p-4">
                        <div className="detail-title">
                            <h2><FormattedMessage id="app.wsmanager.location.booking.date_selection" defaultMessage="Select date" /></h2>
                        </div>
                        <div className="detail-form">


                            <Calendar
                                locale={calendar}
                                value={startDate}
                                onChange={dateChanged}
                                inline={true}
                                showWeek={false}
                                disabledDates={invalidDates}
                            >
                            </Calendar>
                            {
                                reservation.book_all_day ?
                                    <div className="_flex justify-content-center mt-2">
                                        {
                                            props.bookingState?.loading ?

                                                <div className="_flex justify-content-center  align-items-center detail-grey">
                                                    <Loader active size='small' inline="centered" />
                                                    <p className="_flex ml-2"><FormattedMessage id="location.booking.detail.verify_availability" defaultMessage="Verifying availability" /></p>
                                                </div>
                                                :
                                                <div className="_flex justify-content-center align-items-center detail-grey">
                                                    {
                                                        invalidDates.indexOf(startDate) === -1 ?
                                                            <p><i className="icon-cv19-circle-check green"></i> <FormattedMessage id="location.booking.detail.date_available" defaultMessage="Date Available" />
                                                                {
                                                                    props.bookingState?.slots.length === 1 ?
                                                                        <span>
                                                                            <span> </span>
                                                                        - {`${seatsAvailable - props.bookingState?.slots[0].seats_busy} `}
                                                                            {
                                                                                seatsAvailable - props.bookingState?.slots[0].seats_busy > 1 ?
                                                                                    <FormattedMessage id="app.wsmanager.location.detail.seats" defaultMessage="Seats" />
                                                                                    :
                                                                                    <FormattedMessage id="app.wsmanager.location.detail.seat" defaultMessage="Seat" />
                                                                            }
                                                                        </span>
                                                                        :
                                                                        ""
                                                                }

                                                            </p>
                                                            :
                                                            <p><i className="icon-cv19-warning delete-red"></i> <FormattedMessage id="location.booking.detail.date_unavailable" defaultMessage="Date Unavailable" />

                                                            </p>
                                                    }
                                                </div>



                                        }
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div className="detail-form py-2">
                            <Row className="px-4">
                                <Col md="9" className="label _flex justify-content-start align-items-center "> <label style={{ margin: '0' }} ><FormattedMessage id="location.entrance.book_all_day" defaultMessage="All day booking" /></label></Col>
                                <Col md="3" className="justify-content-center _flex">
                                    <Checkbox disabled={props.settings?.entrance.book_all_day} className="my-3" onChange={e => set(e.checked, 'book_all_day')} checked={reservation.book_all_day}></Checkbox>
                                </Col>
                            </Row>
                        </div>

                        {
                            !reservation.book_all_day ?

                                <div className="detail-form">
                                    <Row className="px-4">
                                        <Col xs="8" className="_flex justify-content-start align-items-center">
                                            <label style={{ margin: '0' }} >
                                                <FormattedMessage id="app.wsmanager.location.booking.time_selection" defaultMessage="Select time slot" />
                                            </label>
                                        </Col>
                                        <Col xs="4">
                                            <UInput
                                                className="w-100 rounded-input text-center"
                                                onClick={(e: any) => { openTimeModal() }}
                                                loading={props.bookingState?.loading}
                                                value={hour ? hour + ":00" : ""}
                                                readOnly={true}
                                                placeholder='00:00' />
                                        </Col>
                                    </Row>
                                </div>
                                :
                                ""
                        }


                        <div className="detail-form justify-content-end _flex" style={{ border: 'none' }}>
                            <Row>
                                <Col className="px-3">
                                    <Button
                                        btntype="OK detail-bg px-4 w-100 rounded-input my-3"
                                        clicked={(e: any) => { cancelDateSelection() }}
                                    >
                                        <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" />
                                    </Button>
                                </Col>
                                <Col className="px-3">
                                    <Button
                                        disabled={setDateAndTimeDisabled()}
                                        btntype="OK green-bg px-4 w-100 rounded-input my-3 _flex align-self-end"
                                        clicked={(e: any) => {
                                            setDateAndTime()
                                        }}
                                    >
                                        <FormattedMessage
                                            id="app.generic.select"
                                            defaultMessage="Select"
                                        />
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <div className={`calendar h-90 w-100 ${timeModalOpened ? 'opened' : ''}`}>
                    <div className="card mx-4 my-2 h-100 w-100 p-4">
                        <div className="detail-title">
                            <h2><FormattedMessage id="app.wsmanager.location.booking.timetables" defaultMessage="Timetables Available" /></h2>
                        </div>

                        {console.log("props.bookingState ", props.bookingState)}
                        {
                            
                            props.bookingState?.loading ?
                                <div className="h-100 align-items-center _flex">
                                    <Loader active inline='centered' />
                                </div>
                                :
                                slots ?
                                    <div className="_flex h-100 flex-direction-column">
                                        <div className="slots_wrapper">
                                            {
                                                slots.map(
                                                    (_slot: any, i: number) => (
                                                        <div className="detail-form align-items-center" key={i} onClick={(e: any) => { !isDeskBusy(_slot) ? setSlot(_slot) : setSlot(undefined) }}>

                                                            <Row>
                                                                <Col xs="7" className="justify-content-start">
                                                                    <Col xs="1">
                                                                        {_slot.hour === slot?.hour ? <div className="circle lightest-green-bg"><i className="icon-cv19-circle-check green"></i></div> : ""}
                                                                    </Col>
                                                                    <p className={`slothour ${!isDeskBusy(_slot) ? 'available' : ''}`}>{`${_slot.hour}:00`}</p>
                                                                </Col>
                                                                <Col xs="5">
                                                                    <div className={`ui button rounded-input ${!isDeskBusy(_slot) ? 'green-bg' : ''}`}>
                                                                        {
                                                                            isDeskBusy(_slot) ?
                                                                                <FormattedMessage id="app.wsmanager.location.booking.busy" defaultMessage="busy" />
                                                                                :
                                                                                <span>
                                                                                    {`${seatsAvailable - _slot.seats_busy} `}
                                                                                    {
                                                                                        seatsAvailable - _slot.seats_busy > 1 ?
                                                                                            <FormattedMessage id="app.wsmanager.location.detail.seats" defaultMessage="Seats" />
                                                                                            :
                                                                                            <FormattedMessage id="app.wsmanager.location.detail.seat" defaultMessage="Seat" />
                                                                                    }
                                                                                </span>
                                                                        }
                                                                    </div>

                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                        <div className="detail-form justify-content-end _flex" style={{ border: 'none' }}>
                                            <Row>
                                                <Col className="px-3">
                                                    <Button
                                                        btntype="OK detail-bg px-4 w-100 rounded-input my-3"
                                                        clicked={(e: any) => { cancelSlotSelection() }}
                                                    >
                                                        <FormattedMessage id="app.generic.cancel" defaultMessage="Cancel" />
                                                    </Button>
                                                </Col>
                                                <Col className="px-3">
                                                    <Button
                                                        disabled={!slot}
                                                        btntype="OK green-bg px-4 rounded-input my-3 _flex align-self-end m-0"
                                                        clicked={(e: any) => {
                                                            selectSlot()
                                                        }}
                                                    >
                                                        <FormattedMessage
                                                            id="app.generic.select"
                                                            defaultMessage="Select"
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                    :
                                    ""


                        }


                    </div>
                </div>
            </div>
        </div >
    )
}

export default injectIntl(LocationReservation)