import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Icon, Modal, List, Label, Button as UIButton } from 'semantic-ui-react';
import Button from '../../../UI/Button/Button';
import { Dropdown } from 'primereact/dropdown';
import { ReservationStatusClasses, ReservationStatusNames, ReservationStatusIcons, Reservation } from '../../../../models/Reservation';
import { IntlContext } from '../../../../utils/IntlContext';
import moment from 'moment';

import './MyReservation.scss';

const MyReservation = (props: any) => {
    const { formatMessage } = props.intl;
    const [noReservation, setNoReservation] = useState(true);
    const [initialReservations, setInitialReservations] = useState<any>([]);
    const [myReservations, setMyReservations] = useState<any>([]);
    const [openDetailReservation, setOpenDetailReservation] = useState(false);
    const [reservationSelected, setReservationSelected] = useState<any>([]);
    const [selectedStatus, setSelectedStatus] = useState<any>(undefined)
    const [status, setStatus] = useState([])
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        console.log("[MYRESERVATION_MOBILE] RESERVATIONSLIST: " + props.reservationsList)
        if (props.reservationsList.length > 0) {
            setNoReservation(false);
        } else {
            setNoReservation(true);
        }

        if (props.reservationsList !== undefined) {
            let listTmp = props.reservationsList;
            setMyReservations(listTmp);
            setInitialReservations(listTmp);
        }

        if (myReservations.length === 0) {
            props.onGetReservations(props.isModerator, { page: 1, per_page: props.reservationsTotalItems });
        }

        if (status.length === 0) {
            let array: any = []
            for (var i in ReservationStatusNames) {
                let id = parseInt(i)
                array.push({ label: formatMessage({ id: ReservationStatusNames[i] }), value: id })
            }
            setStatus(array)
            for (var j = 0; j < array.length; j += 1) {
                if (array[j].value === 2) {
                    setSelectedStatus(array[j].label)
                }
                console.log("ARRAY: " + JSON.stringify(array[j]));
            }
        }

    }, [props.reservationsList, props.reservationsTotalItems])

    useEffect(() => {
        console.log("myReservations ", myReservations)
    }, [myReservations])

    const onOpenDetailReservation = (reservation: any) => {
        console.log("RESERVATION SELECTED: " + JSON.stringify(reservation));
        setReservationSelected(reservation);
        setOpenDetailReservation(true)
    }

    

    const deleteReservation = (reservation: any) => {
        props.onDeleteReservation(reservation, { page: 1, per_page: props.reservationsTotalItems })
        setOpenDetailReservation(false)
        setSelectedStatus(undefined)
        setMyReservations([]);
        setInitialReservations([]);
        setOpenDeleteModal(false)
    }

    const detailReservation = () => {
        return (
            <div className="container" style={{ width: '100%', paddingRight: '0.05em', paddingLeft: '0.05em', marginRight: 'auto', marginLeft: 'auto' }}>
                <div className="labelContent">
                    <div className="containerCol" style={{ marginLeft: '1em' }}>
                        <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>
                            <FormattedMessage id="app.generic.summary" defaultMessage="Summary" style={{ fontSize: '18px', marginBottom: '10px' }} />
                        </div>
                        <div className="containerCol">
                            <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="reservations.booker" defaultMessage="Booker" />:</div>
                            <label style={{fontSize: '16px'}}>{reservationSelected.booker_name}</label>
                        </div>
                        <div className="containerCol">
                            <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="log.user" defaultMessage="User" />:</div>
                            <label style={{fontSize: '16px'}}>{reservationSelected.user_name}</label>
                        </div>
                        <div className="containerCol">
                            <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="checkin.table.date" defaultMessage="Date" />:</div>
                            <label style={{ fontSize: '16px' }}>{moment(reservationSelected.booking_datetime_start).format("DD-MM-YYYY HH:mm")}</label>
                        </div>
                        <div className="containerCol">
                            <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="reservations.office" defaultMessage="Office" />:</div>
                            <label style={{ fontSize: '16px' }}>{reservationSelected.location_root}</label>
                        </div>
                        <div className="containerCol">
                            <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="app.generic.locations" defaultMessage="Locations" />:</div>
                            <label style={{ fontSize: '16px' }}>{(reservationSelected.location_node != reservationSelected.desk_name) ? reservationSelected.location_node + " - " + reservationSelected.location_name : reservationSelected.location_name}</label>
                        </div>
                        <div className="containerCol">
                            <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="reservations.desk" defaultMessage="Desk" />:</div>
                            <label style={{ fontSize: '16px' }}>{reservationSelected.desk_name}</label>
                        </div>
                        <div className="containerCol">
                            <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="location.motivations.motivation" defaultMessage="Motivation" />:</div>
                            <label style={{ fontSize: '16px' }}>{reservationSelected.booking_reason}</label>
                        </div>
                        <div className="containerCol">
                            <div className='color' style={{ marginRight: '0.5em' }}><FormattedMessage id="import.table.state" defaultMessage="Status" />:</div>
                            <label style={{ fontSize: '16px' }}>{reservationSelected.status_text}</label>
                        </div>
                    </div>
                </div>
                <div className="containerButton">
                    <UIButton loading={props.inlineLoading} className='cancelButtonGrey' style={{ marginRight: '10px' }}
                        onClick={() => setOpenDetailReservation(false)}>
                        <FormattedMessage id="app.generic.back" defaultMessage="Back" />
                    </UIButton>
                    <UIButton loading={props.inlineLoading} className='actionButton'
                        onClick={() => setOpenDeleteModal(true)}>
                        <FormattedMessage id="app.generic.delete" defaultMessage="Delete" />
                    </UIButton>
                </div>
            </div>
        )
    }

    const onStatusSelected = (event: any) => {
        setSelectedStatus(event.value)
        var filteredReservations: any = [];
        if (ReservationStatusClasses[event.value] === "cancelled_no_show") {
            ReservationStatusClasses[event.value] = "cancelled for no show"
        }
        for (var i = 0; i < initialReservations.length; i += 1) {
            if (initialReservations[i].status_text === ReservationStatusClasses[event.value]) {
                filteredReservations.push(initialReservations[i]);
            }
        }
        setMyReservations(filteredReservations);
    }

    return (
        <div className="container" style={{ width: '100%', paddingRight: '0.05em', paddingLeft: '0.05em', marginRight: 'auto', marginLeft: 'auto' }}>
            <div className="containerElement">
                {(!openDetailReservation) ? (
                    (noReservation) ?
                        <div className="labelContent">
                            <label style={{ margin: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Non ci sono ancora prenotazioni</label>
                        </div>
                        :
                        <>
                            <div className='containerElement'>
                                <FormattedMessage id="reservations.filters.status_id" defaultMessage="Search by status">
                                    {
                                        (placeholder: string) => (
                                            <div className="labelContent">
                                                <Label className="basic" style={{ border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', borderRadius: '20px', marginTop: '10px' }}>
                                                    <Icon className="marginIconLeft" name='filter' size='large' />
                                                    <div className="separator"></div>
                                                    <div style={{ marginRight: '5px' }}>{placeholder}</div>
                                                </Label>
                                                <div className="w-100" style={{ fontSize: '1.2em' }}>
                                                    <Dropdown
                                                        value={selectedStatus}
                                                        options={status}
                                                        className="my-2 rounded-input w-100"
                                                        //style={{padding: '.2em', fontSize: '1.4em '}}
                                                        onChange={onStatusSelected}
                                                        optionLabel="label"
                                                        placeholder={placeholder}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                </FormattedMessage>
                            </div>
                            {
                                myReservations.map((reservation: any, i: number) => (
                                    //reservation.status_text === 'approved' &&
                                    <div className="labelContent" key={i} style={{ marginBottom: '10px', marginTop: '10px' }} onClick={() => onOpenDetailReservation(reservation)}>
                                        <div className="containerRow" style={{ marginLeft: '1em' }}>
                                            <div className="containerCol">
                                                <label className='containerRow'>
                                                    <div className='color' style={{ marginRight: '0.5em' }}>
                                                        <FormattedMessage id="checkin.table.date" defaultMessage="Date" />:
                                                    </div> 
                                                    <label style={{fontSize: '16px'}}>
                                                        {moment(reservation.booking_datetime_start).format("DD-MM-YYYY")}
                                                    </label>
                                                </label>
                                                <label className='containerRow'>
                                                    <div className='color' style={{ marginRight: '0.5em' }}>
                                                        <FormattedMessage id="reservations.office" defaultMessage="Office" />:
                                                    </div>
                                                    <label style={{fontSize: '16px'}}>
                                                        {reservation.location_root}        
                                                    </label> 
                                                </label>
                                                <label className='containerRow'>
                                                    <div className='color' style={{ marginRight: '0.5em' }}>
                                                        <FormattedMessage id="log.user" defaultMessage="User" />:
                                                    </div> 
                                                    <label style={{fontSize: '16px'}}>
                                                        {reservation.user_name}
                                                    </label>
                                                </label>
                                            </div>
                                            {/*<Icon className="marginIconLeft" name='arrow right' size='large' onClick={() => onOpenDetailReservation(reservation)} />*/}
                                        </div>
                                    </div>

                                ))
                            }
                        </>
                ) :
                    (detailReservation())}
            </div>

            <Modal
                open={openDeleteModal}
            >
                <Modal.Header>
                    <FormattedMessage id="app.generic.notice" defaultMessage="Notice" />
                </Modal.Header>
                <Modal.Content>
                    <FormattedMessage id="app.generic.info_delete" defaultMessage="Delete the reservation?" />
                </Modal.Content>
                <Modal.Actions>
                    <div className="containerButton" style={{marginBottom: '10px'}}>
                        <UIButton loading={props.inlineLoading} style={{marginTop: '1%', width: '100%', boxShadow: 'rgba(71, 71, 71, 0.596) 0 0 10px', background: 'transparent linear-gradient(90deg, rgba(127, 127, 127, 1) 0%, rgba(71, 71, 71, 0.596) 100%) 0% 0% no-repeat padding-box', color: 'white', borderRadius: '5rem', paddingTop: '1.3em', paddingBottom: '1.3em', marginRight: '10px'}}
                            onClick={() => setOpenDeleteModal(false)}>
                            <FormattedMessage id="button.close" defaultMessage="Close" />
                        </UIButton>
                        <UIButton loading={props.inlineLoading} style={{marginTop: '1%', width: '100%', boxShadow: '#27bbcc 0 0 10px', background: 'transparent linear-gradient(90deg, #44ad8c 0%, #27bbcc 100%) 0% 0% no-repeat padding-box', color: 'white', borderRadius: '5rem', paddingTop: '1.3em', paddingBottom: '1.3em'}}
                            onClick={() => deleteReservation(reservationSelected)}>
                            <FormattedMessage id="app.generic.delete" defaultMessage="Delete" />
                        </UIButton>
                    </div>
                </Modal.Actions>
            </Modal>

        </div>
    )
}

export default injectIntl(MyReservation);
