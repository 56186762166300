import React, { useState, useEffect } from 'react'
import { Tab, Dropdown, Icon, Loader } from 'semantic-ui-react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../../../UI/Button/Button';
import { Button as SUIButton } from 'semantic-ui-react';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';


import './CheckInReports.scss';
import { GetDateAndTimeByLocale, GetCalendarDateFormatByLocale } from '../../../../../utils/localization/supported-languages';
import { IntlContext } from '../../../../../utils/IntlContext';
import { AutoComplete } from 'primereact/autocomplete';


const CheckInReportsTab = (props: any) => {


  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD').split("T")[0])
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD').split("T")[0])
  const [startDateLabel, setStartDateLabel] = useState(moment().startOf('day').toDate())
  const [endDateLabel, setEndDateLabel] = useState(moment().startOf('day').toDate())
  const [invalidStartDate, setInvalidStartDate] = useState(false)
  const [invalidEndDate, setInvalidEndDate] = useState(false)
  const [range, setRange] = useState([moment().startOf('day').toDate(), moment().endOf('day').toDate()])
  const [eventFilter, setEventFilter] = useState<any>()
  const [table, setTable] = useState<DataTable | undefined>()
  const { formatMessage } = props.intl;
  const moreOptions: any[] = [{ label: 'app.generic.sendmail', value: 'mail' }]
  const { locale, calendar } = React.useContext(IntlContext);

  const [firstIndex, setFirstIndex] = useState(0)
  const [perPage, setPerPage] = useState<any>(10)
  const [order, setOrder] = useState<any>()
  const [orderBy, setOrderBy] = useState<any>()
  const [filters, setFilters] = useState<any>({})

  const [checkinDataList, setCheckinDataList] = useState([])
  const [checkinDataTotalItem, setCheckinDataTotalItem] = useState(0)


  useEffect(() => {
    var today = new Date();
    var beforeweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    var startDate = moment(beforeweek).format('YYYY-MM-DD').split("T")[0]
    setStartDate(startDate)
    setStartDateLabel(moment(startDate).startOf('day').toDate())
    props.onGetData({ page: (firstIndex / perPage) + 1, per_page: perPage, startDate, endDate })
  }, [props.auth?.selectedCompany])

  useEffect(() => {
    if(props.checkinData?.list !== undefined && props.checkinData?.total_items !== undefined){
      setCheckinDataList(props.checkinData?.list)
      setCheckinDataTotalItem(props.checkinData?.total_items)
    }
    
  }, [props.checkinData?.list, props.checkinData?.total_items])


  const onReload = () => {
    props.onGetData({ page: (firstIndex / perPage) + 1, per_page: perPage, startDate, endDate })
  }

  const checkAppPermission = () => {
    let appPermission: any = localStorage.getItem("appPermission");
    if (appPermission === "ALL")
      return true;

    return false;
  }

  const checkApp = () => {
    let app: any = localStorage.getItem("app");
    if (app === "WSMAN")
      return true;

    return false;
  }

  const GetDateAndTimeByLocale = (locale: string, date: string) => {
    switch (locale) {
      case "it":
      case "en":
        return moment(date).format("DD-MM-YYYY");
        return;
      default:
        return moment(date).format("YYYY-MM-DD");
    }
  };

  const onSearch = () => {
    //console.log("[COMPONENT DATA ] startDate: ", startDate, " - endDate: ", endDate);
    props.onGetData({ page: (firstIndex / perPage) + 1, per_page: perPage, startDate, endDate })
  }

  /*const setDates = (e: any) => {
    console.log("RANGE", e)
    setRange(e.value)

    if (e.value.length > 1) {
      let tmp: any = e.value[0]
      setStartDate(moment(tmp).format('YYYY-MM-DD').split("T")[0])
      let tmp2: any = e.value[1]
      console.log("TMP", tmp, tmp2)
      setEndDate(moment(tmp2).format('YYYY-MM-DD').split("T")[0])


    }
  }*/

  const setStartDateHandler = (e: any) => {
    (e.value === null) ? setInvalidStartDate(true) : setInvalidStartDate(false)
    setStartDate(moment(e.value).format('YYYY-MM-DD').split("T")[0])
    setStartDateLabel(moment(e.value).startOf('day').toDate())
  }

  const setEndDateHandler = (e: any) => {
    (e.value === null) ? setInvalidEndDate(true) : setInvalidEndDate(false)
    setEndDate(moment(e.value).format('YYYY-MM-DD').split("T")[0])
    setEndDateLabel(moment(e.value).startOf('day').toDate())
  }

  /*const enableSearchButton = () => {
    return endDate && startDate
  }

  const enableExportButton = () => {
    return endDate && startDate
  }*/


  const moreOptionsTemplate = (option: any) => {
    return (
      <FormattedMessage id={option.label} defaultMessage={option.label} />
    )
  }

  const sendMail = (mail: string) => {
    window.location.href = 'mailto:' + mail;
  }

  const actionTemplate = (rowData: any, column: any) => {
    return <div>
      {rowData.loading ?
        <Loader active inline size="mini" />
        :
        <FormattedMessage id="app.generic.more" defaultMessage="More">
          {(placeholder: string) => (
            <PrimeDropdown options={moreOptions} placeholder={placeholder}
              onChange={(e: any) => {
                switch (e.value) {
                  case 'mail':
                    sendMail(rowData.email)
                    break
                }
              }}
              appendTo={document.body}
              itemTemplate={moreOptionsTemplate}

            />
          )}
        </FormattedMessage>

      }
    </div>;
  }

  const onExport = (event: any) => {
    props.onExportData({ page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, filters: filters, startDate: startDate, endDate: endDate });
  }

  const headerName = (field: string) => {
    return formatMessage({ id: "checkin.table." + field })
  }

  const dateTemplate = (rowData: any, column: any) => {
    if (!rowData || !rowData[column.field]) return ''
    return GetDateAndTimeByLocale(locale, rowData[column.field])

  }

  const getFilterPlaceholder = (field: string) => {
    return formatMessage({ id: "checkin.filters." + field })
  }


  const onEventFilter = (event: any) => {
    let filter = moment(event.value, 'YYYY-MM-DD').format().split("T")[0]
    //table?.filter(filter, 'date', 'contains')
    setEventFilter(event.value)
    filters.date = {value: filter}
    onFilter({ filters: filters })
  }

  const onPage = (event: any) => {
    setFirstIndex(event.first)
    props.onGetData({ page: (firstIndex / perPage) + 1, per_page: perPage, order: order, orderBy: orderBy, startDate: startDate, endDate: endDate })
  }

  const onSort = (event: any) => {
    setOrder(event.sortOrder)
    setOrderBy(event.sortField)
    props.onGetData({ page: (firstIndex / perPage) + 1, per_page: perPage, order: event.sortOrder, orderBy: event.sortField, filters: filters, startDate: startDate, endDate: endDate })
  }

  const onFilter = (event: any) => {
    setFilters(event.filters)
    props.onGetData({ page: (firstIndex / perPage) + 1, per_page: perPage, order: event.sortOrder, orderBy: event.sortField, filters: event.filters, startDate, endDate })
  }

  const calendarFilter =
    <Calendar inputClassName="filterCalendar" inputStyle={{ width: '100%', borderRadius: '0px' }} selectionMode="single" placeholder={getFilterPlaceholder('date')} style={{ width: '100%' }} value={eventFilter} onChange={onEventFilter} appendTo={document.body} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} minDate={startDateLabel} maxDate={endDateLabel} />


  return (
    <Tab.Pane className="Contacts">
      <div>
        <div className="_flex flex-direction-row justify-content-between my-3">

          <div className="_flex">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
              <label ><FormattedMessage id="biohazard.query.start_date" defaultMessage="Select start date" /></label>
              <Calendar className="rounded-input mr-3" placeholder={'Invalid date'} value={startDateLabel} onChange={(e: any) => setStartDateHandler(e)} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} maxDate={moment().startOf('day').toDate()} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
              <label ><FormattedMessage id="biohazard.query.end_date" defaultMessage="Select end date" /></label>
              <Calendar className="rounded-input mr-3" placeholder={'Invalid date'} value={endDateLabel} onChange={(e: any) => setEndDateHandler(e)} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)} minDate={moment().startOf('day').toDate()} />
            </div>
            {/*<FormattedMessage id="biohazard.query.range" defaultMessage="Select a range of dates" />
              {(placeholder: string) => (
                <Calendar selectionMode="range" className="rounded-input mr-3" placeholder={placeholder} readOnlyInput={true} value={range} onChange={(e: any) => setDates(e)} locale={calendar} dateFormat={GetCalendarDateFormatByLocale(locale)}></Calendar>
              )}*/}
            <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
              <Button
                btntype="OK green-bg px-4 w-100 rounded-input"
                clicked={onSearch}
                loading={props.loading}
                disabled={invalidEndDate || invalidStartDate}>
                <FormattedMessage id="app.generic.search" defaultMessage="Search" />
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <SUIButton onClick={onExport} basic loading={props.loading} disabled={invalidEndDate || invalidStartDate}><FormattedMessage id="app.generic.export" defaultMessage="Export Results" /></SUIButton>
          </div>

        </div>

        <DataTable value={checkinDataList}
          totalRecords={checkinDataTotalItem}
          paginator={true}
          rows={perPage}
          autoLayout
          lazy={true}
          loading={props.loading}
          first={firstIndex}
          id="checkin-table"
          ref={(el: any) => { setTable(el); }}
          //sortMode="multiple"
          onPage={(e: any) => onPage(e)}
          className="mt-3"
          onSort={(e: any) => onSort(e)}
          sortField={orderBy}
          sortOrder={order}
          filters={filters}
          onFilter={(e: any) => onFilter(e)}
        >

          <Column
            field="user.name"
            header={headerName('name')}
            filter={true}
            filterPlaceholder={getFilterPlaceholder('name')}
            sortable={true}
          />

          <Column
            field="user.surname"
            header={headerName('surname')}
            filter={true}
            filterPlaceholder={getFilterPlaceholder('surname')}
            sortable={true}
          />

          <Column
            field="user.email"
            header={headerName('email')}
            filter={true}
            filterPlaceholder={getFilterPlaceholder('email')}
            sortable={true}
          />

          {/* <Column
              field="counter"
              header={headerName('counter')}
              filter={true}
              filterPlaceholder={getFilterPlaceholder('counter')}
              sortable={true}
            /> */}

          <Column
            field="date"
            body={dateTemplate}
            header={headerName('date')}
            filter={true}
            filterPlaceholder={getFilterPlaceholder('date')}
            sortable={true}
            filterElement={calendarFilter}
          />

          {(checkApp() ? (
            <Column
              field="bookingFlag"
              header={headerName('bookingFlag')}
              filter={true}
              filterPlaceholder={getFilterPlaceholder('bookingFlag')}
              sortable={true}
            />) : null)}

          <Column
            field="location"
            header={headerName('location')}
            filter={true}
            filterPlaceholder={getFilterPlaceholder('location')}
            sortable={true}
          />

          {/* <Column 
          body={actionTemplate} header={headerName('actions')} style={{ textAlign: 'center', width: '8em' }} /> */}

        </DataTable>

      </div>
    </Tab.Pane >
  )
}

export default injectIntl(CheckInReportsTab)