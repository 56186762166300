import { UsersState } from "../../../interfaces/Users";
import { UserApi } from "../../../api/UserApi";
import { HTTP_STATUS, Api } from "../../../api/Api";
import { User } from "../../../models/User";
import { UserAction } from "../../../models/actions/UserAction";
import { AppState } from "../../../interfaces/AppState";
import { AddError, AddSuccess } from "../toast";
import { CommonApi } from "../../../api/CommonApi";
import { startLogout } from "../auth";
import { AuthState } from "../../../interfaces/AuthState";
import { UsersLog } from "../../../utils/UsersLog";
import { UserLog } from "../../../interfaces/UserLogState";
import { createUsersLog } from "./usersLog";

const USER_ACTION = "USER_ACTION - ";

export function getUsers(data?: any) {
  return (dispatch: any, getState: any) => {
    let state: AuthState = (getState() as AppState).authReducer;
    dispatch(UserAction.GenericUserRequest());
    if (data) data.company_code = state.auth.selectedCompany
    UserApi.GetUsersList(data)
      .then((response) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        dispatch(UserAction.onGetUsersSuccess(response));
      })
      .catch((error) => {
        console.log(USER_ACTION + "USERS LIST", error);
        if(error.toString().includes("access_token")){
          dispatch(startLogout())
        }
        dispatch(AddError(error));
        dispatch(UserAction.onUserError());
      });
  };
}

export function createUser(user: User) {
  return (dispatch: any, getState: any) => {
    let state: UsersState = (getState() as AppState).users;
    dispatch(UserAction.UserRequest(user, state.items));
    UserApi.CreateUser(user)
      .then((response) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        if (response && response.guid) {
          user.guid = response.guid;
          dispatch(UserAction.onCreateUserSuccess(user, state.items));
          dispatch(AddSuccess("app.operation.successful"));
        } else {
          dispatch(AddError({ message: "error" }));
          dispatch(UserAction.onUserError(user, state.items));
        }
      })
      .catch((error) => {
        console.log(USER_ACTION + "CREATE USER ERROR", error);
        dispatch(AddError(error));
        dispatch(UserAction.onUserError(user, state.items));
      });
  };
}

export function updateUser(user: User) {
  return (dispatch: any, getState: any) => {
    let state: UsersState = (getState() as AppState).users;
    dispatch(UserAction.UserRequest(user, state.items));
    UserApi.UpdateUser(user)
      .then((response) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        dispatch(UserAction.onUpdateUserSuccess(user, state.items));
        dispatch(AddSuccess("app.operation.successful"));
      })
      .catch((error) => {
        console.log(USER_ACTION + "USERS LIST", error);
        dispatch(AddError(error));
        dispatch(UserAction.onUserError(user, state.items));
      });
  };
}

export function deleteUser(user: User) {
  return (dispatch: any, getState: any) => {
    let state: UsersState = (getState() as AppState).users;
    dispatch(UserAction.UserRequest(user, state.items));
    UserApi.DeleteUser(user)
      .then((response) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        dispatch(UserAction.onDeleteUserSuccess(user, state.items));
        dispatch(AddSuccess("app.operation.successful"));
      })
      .catch((error) => {
        console.log(USER_ACTION + "USER DELETE ERROR", error);
        dispatch(AddError(error));
        dispatch(UserAction.onUserError(user, state.items));
      });
  };
}

export function uploadUsers(file: File, filterData?: any) {
  console.log("[ACTIONS - UPLOADUSERS] file: ", file, " - filterData: ", filterData);
  return (dispatch: any, getState: any) => {
    dispatch(UserAction.GenericUserRequest());
    let state: UsersState = (getState() as AppState).users;
    let formData = new FormData();
    formData.append('file', file);
    UserApi.UploadUsers(formData)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: User[]) => {
        dispatch(getUsers(filterData));
        dispatch(AddSuccess("app.operation.successful"));
      })
      .catch((error) => {
        console.log(USER_ACTION + "USERS UPLOAD ERROR", error);
        dispatch(AddError(error));
        dispatch(UserAction.onUserError());
      });
  };
}

export const resetPassword = (users: User[]) => {
  return (dispatch: any, getState: any) => {
    let state: UsersState = (getState() as AppState).users;
    dispatch(UserAction.UsersRequest(users, state.items));
    UserApi.ResetPasswords(users)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((json: any) => {
        console.log("json", json);
        dispatch(AddSuccess("app.operation.successful"));
        dispatch(UserAction.onGetUsersSuccess(state));
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(AddError(error));
        dispatch(UserAction.onGetUsersSuccess(state));
      });
  };
};

export const getRoles = () => {
  return (dispatch: any, getState: any) => {
    UserApi.GetRoles()
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((roles: any) => {
        console.log("ROLES", roles);
        dispatch(UserAction.onRoleSuccess(roles));
      })
      .catch((error: Error) => {
        console.log(error);
        if(error.toString().includes("access_token")){
          dispatch(startLogout())
        }
      });
  };
};


export const forgotPassword = (email: string) => {
  console.log("Forgot Password - ", email)
  let params: any = {};
  params.email = email;
  return (dispatch: any, getState: any) => {
    
    dispatch(UserAction.GenericUserRequest());
    UserApi.ForgotPassword(params)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        //console.log("json", json);
        dispatch(AddSuccess("app.operation.successful"));

        //SetUp UserLog Variable
        let userLog = new UserLog({
          action_guid: UsersLog.AUTH_FORGOT_PASSWORD,
          fe_view_guid: UsersLog.FORGOT_PAGE_VIEW,
          be_api_guid: UsersLog.RESET_PASSWORD_REQUEST_API,
          description: "Forgot password. Send Reset Request",
          email: email
        })
        //createUserLog
        createUsersLog(userLog);
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(AddError(error));
      });
  };
};


export const resetForgottenPassword = (password: string, passwordConfirm: string, nonce: string) => {
  console.log("Reset Password - ", password, " - ", passwordConfirm, " - ", nonce)
  return (dispatch: any, getState: any) => {
    let params:any = {}
    params.password = password
    params.nonce = nonce
    console.log("[Reset Password Parameters] ", params)

    dispatch(UserAction.GenericUserRequest());
    UserApi.ResetForgottenPassword(params)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res: any) => {
        //console.log("json", json);
        dispatch(AddSuccess("app.operation.successful"));
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(AddError(error));
      });
  };
};

export const downloadTemplate = () => {
  return (dispatch: any, getState: any) => {
    UserApi.DownloadTemplate()
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response: any) => {
        console.log("link template", response);
        window.open(response.link);
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(AddError(error));
      });
  };
};