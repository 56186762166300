import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Home from '../../components/tracker/Home/Home';
import { AppState } from '../../interfaces/AppState';
import { startLogout } from '../../store/actions/auth';
import { getTimetables, onBooking } from '../../store/actions/backoffice/booking';
import { getLocations } from '../../store/actions/backoffice/location';
import { getAvailableLocations, getParentLocation } from '../../store/actions/backoffice/locationV2';
import { getMotivations } from '../../store/actions/backoffice/settings';
import { acceptCertification, certificationList, checkIn, configuration, getUsersList, isCertificationDone, user } from '../../store/actions/tracker/users';
import { getUsers } from '../../store/actions/backoffice/users';
import techedgeBg from "./assets/imgs/tracker/techedgeBg.png"
import { switchTabRequest } from '../../store/actions/backoffice/app';
import { BaseReservation, Reservation } from '../../models/Reservation';
import { getReservations, getReservation, deleteReservation} from '../../store/actions/backoffice/booking';

const MobileHomeContainer = (props: any) => {

    console.log("HOME CONTAINER")
    
    useEffect(() => {
        console.log("props.listLocation} ", props.listLocation)

    }, [props.listLocation])

    useEffect(() => {    
        props.getReservations()

    }, [props.authState?.auth?.selectedCompany])

    const handleLogout = () => {
        console.log("[Home Container] Logout")
        // reset error
        props.startLogout();
    };

    const handleCertificationList = () => {
        console.log("[Home Container] certificationList")
        // reset error
        if (!props.certificationListDone) {
            props.certificationList();
        }
    };


    const handleAcceptCertification = () => {
        console.log("[Home Container] handleAcceptCertification")
        // reset error
        props.acceptCertification();
    };


    const handleGetColleagues = (page: any, limit: any, filters: any) => {
        console.log("[Home Container] getColleagues", page, " - with filters: ", filters)
        let body = {
            per_page: limit,
            page: page,
            filters: filters
        }

        props.getUsersList(body)
    }

    const handleGetLocations = () => {
        console.log("[Home Container] getLocations")
        props.getLocationsList()
    }

    const handleGetAvailableLocations = (guid: any, date: any, seats_amount: number) => {
        console.log("[Home Container] getAvailableLocations")
        props.getAvailableLocations(guid, date, seats_amount)
    }

    const handleGetUserInfo = () => {
        console.log("[Home Container] getLocations")
        props.getUserInfo()
    }

    const handleGetReservations = () => {
        console.log("[Home Container] getReservationsList")
        props.getReservationsList()
    }

    const handleScan = (location: string, tags: any[]) => {
        console.log("[Home Container] onScan")
        var m = new Date();
        const dateString =
            m.getFullYear() + "-" +
            ("0" + (m.getMonth() + 1)).slice(-2) + "-" +
            ("0" + m.getDate()).slice(-2) + " " +
            ("0" + m.getHours()).slice(-2) + ":" +
            ("0" + m.getMinutes()).slice(-2) + ":" +
            ("0" + m.getSeconds()).slice(-2);
        let tagsId = [];
        for(let i=0; i < tags.length; i++){
            tagsId.push(tags[i].guid)
        }
        const body = {
            checkin_timestamp: dateString,
            location_code: location,
            tags: tagsId
        }
        console.log("BODY SCAN", body)
        props.checkIn(body)
    }

    const handleCreateReservation = (params: any) => {
        console.log("[Home Container] handleCreateReservation", params)

        const body = {
            location_guid: params.location_guid,
            booker_guid: params.booker_guid,
            include_myself: 1,
            booking_datetime_start: params.booking_datetime_start,
            booking_datetime_end: params.booking_datetime_end,
            booking_reason_guid: params.booking_reason_guid,
            entrance_time: params.entrance_time,
            tags: params.tags
        }
        console.log("Body createReservation", body)
        props.createReservation(body)
    }

    const handleUpdateReservation = (params: any) => {
        console.log("[Home Container] handleUpdateReservation", params)

        const body = {
            location_guid: params.location_guid,
            booker_guid: params.booker_guid,
            include_myself: 1,
            booking_datetime_start: params.booking_datetime_start,
            booking_datetime_end: params.booking_datetime_end,
            booking_reason_guid: params.booking_reason_guid,
            entrance_time: params.entrance_time,
            tags: params.tags
        }
        console.log("Body updateReservation", body)
        props.updateReservation(body)
    }

    const handleDeleteReservation = (params: any) => {
        console.log("[Home Container] handleDeleteReservation", params)

        const body = params;
        console.log("Body deleteReservation", body)
        props.deleteReservation(body)
    }

    const handleReasonList = () => {
        console.log("[Home Container] reasonList")
        //props.listReasons()
        props.getMotivationsList()
    }

    const handleIsCertificationDone = () => {
        props.handleIsCertificationDone();
    }

    const handleConfiguration = () => {
        props.configuration();
    }


    const appHeight = window.innerHeight + "px"
    const topBg = (window.innerHeight / 2) * -1

    return (
        <div className="App flex flex-direction-column w-100" style={{ height: appHeight }}>
            <div className="bgRoundGradient" style={{ height: appHeight, top: topBg }}>
            </div>
            <Home
                onClickCertificationList={handleCertificationList}
                onClickAcceptCondition={handleAcceptCertification}
                certificationListDone={props.certificationListDone}
                onClickLogout={handleLogout}
                onScan={(location: string, tags: any[]) => handleScan(location, tags)}
                getColleagues={handleGetColleagues}
                getLocations={handleGetLocations}
                getAvailableLocations={props.getAvailableLocations}
                getParentLocation={props.getParentLocation}
                onBooking={props.onBooking}
                //getAvailableLocations={(guid: any, date: any, seats_amount: number) => handleGetAvailableLocations(guid, date, seats_amount)}
                getReasons={handleReasonList}
                getUserList={(page: any, limit: any, filters: any) => handleGetColleagues(page, limit, filters)}
                usersList={props.usersList}
                usersState={props.usersState}
                onGetUsers={props.getUsers}
                checkinDone={props.checkinDone}
                loading={props.usersFullScreenLoading}
                inlineLoading={props.inlineLoading}
                locationsList={props.locationsList}
                listLocationOptions={props.listLocation }
                reasonList={props.reasonList}
                reservationCreate={props.reservationCreate}
                reservationDelete={props.reservationDelete}
                updateReservation={(params: any) => handleUpdateReservation(params)}
                //deleteReservation={(params: any) => handleDeleteReservation(params)}
                onDeleteReservation={props.deleteReservation}
                acceptCertificationDone={props.acceptCertificationDone}
                isCertificationDone={props.isCertificationDone}
                handleIsCertificationDone={handleIsCertificationDone}
                configuration={handleConfiguration}
                configurationDone={props.configurationDone}
                bookingState={props.bookingState}
                onGetTimetables={props.getTimetables}
                activeApp={props.activeApp}
                auth={props.auth}
                onGetReservations={props.getReservations}
                //onGetReservations={(data?: any) => { props.getReservations(props.isModerator, data);} }
                listParentLocation={props.listParentLocation}
                reservationsList={props.reservationsList}
                reservationsTotalItems={props.reservationsTotalItems}
            >
            </Home>
        </div>
    )

};

const mapStateToProps = (state: AppState) => ({
    logoutLoading: state.authReducer.loading,
    inlineLoading: state.trackerUser.loading,
    usersFullScreenLoading: state.trackerUser.loadingFullScreen,
    usersList: state.trackerUser.usersList,
    usersState: state.users,
    checkinDone: state.trackerUser.checkin,
    locationsList: state.location.list,
    reasonList: state.settings.motivations,
    userInfo: state.trackerUser.userInfo,
    certificationListDone: state.trackerUser.certificationList,
    acceptCertificationDone: state.trackerUser.acceptCertification,
    isCertificationDone: state.trackerUser.isCertificationDone,
    configurationDone: state.trackerUser.configurationDone,
    bookingState: state.booking,
    listLocation: state.locationV2.listLocation,
    listParentLocation: state.locationV2.listParentLocation,
    activeApp: state.app.activeApp,
    auth: state.authReducer.auth,
    reservationsList: state.booking.list,
    reservationsTotalItems: state.booking.total_items,
});

const mapDispatchToProps = (dispatch: any) => ({
    startLogout: () => dispatch(startLogout()),
    getUsersList: (body: any) => dispatch(getUsersList(body)),
    getUsers: (data?: any) => { dispatch(getUsers(data)) },
    checkIn: (body: any[]) => dispatch(checkIn(body)),
    getLocationsList: () => dispatch(getLocations(false)),
    getAvailableLocations: (guid: any, date: any, seats_amount: number) => dispatch(getAvailableLocations(guid, date, seats_amount)),
    getParentLocation: (disabled: boolean, companies: any[]) => dispatch(getParentLocation(disabled, companies)),
    getMotivationsList: () => dispatch(getMotivations()),
    getUserInfo: () => dispatch(user()),
    certificationList: () => dispatch(certificationList()),
    acceptCertification: () => dispatch(acceptCertification()),
    handleIsCertificationDone: () => dispatch(isCertificationDone()),
    configuration: () => dispatch(configuration()),
    getTimetables: (booking_datetime: string, location_guid: string, book_all_day?: boolean) => { dispatch(getTimetables(booking_datetime, location_guid, book_all_day)) },
    switchTab: () => dispatch(switchTabRequest()),
    onBooking: (reservation: BaseReservation, email_array: any, motivation: string, location: string) => { dispatch(onBooking(reservation, email_array, motivation, location)) },    
    //getReservations: () => dispatch(getReservations()),
    getReservations: (
        isModerator: boolean,
        data?: any
    ) => { dispatch(getReservations(isModerator, data)); },
    deleteReservation: (reservation: Reservation, isModerator: boolean, data?: any) => { dispatch(deleteReservation(reservation, isModerator, data)) },
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileHomeContainer);