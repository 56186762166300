import { BiohazardState } from "../../../interfaces/Biohazard";
import { BiohazardApi } from "../../../api/BiohazardApi";
import { HTTP_STATUS, Api } from "../../../api/Api";
import { BiohazardAction } from "../../../models/actions/BiohazardAction";
import { AddError } from "../toast";
import { AppState } from "../../../interfaces/AppState";
import { startLogout } from "../auth";

export function getBiohazardData(
  guid: string,
  startDate: string,
  endDate: string
) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: BiohazardAction.REQUEST });
    BiohazardApi.GetBiohazardData(guid, startDate, endDate)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response) => {
        console.log("BIOHDATA", response);
        dispatch(BiohazardAction.onGetDataSuccess(response.Contagion_List));
      })
      .catch((error) => {
        console.log("BIOHDATA ERR", error);
        if(error.toString().includes("access_token")){
          dispatch(startLogout())
        }
        dispatch(AddError(error));
        dispatch(BiohazardAction.onRequestFailed());
      });
  };
}

export function getContactsData(
  guid: string,
  startDate: string,
  endDate: string
) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: BiohazardAction.REQUEST });
    BiohazardApi.GetReportData(guid, startDate, endDate)
      .then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response) => {
        console.log("BIOHDATA", response);
        dispatch(BiohazardAction.onGetReportDataSuccess(response.Location_contagion_list));
      })
      .catch((error) => {
        console.log("BIOHDATA ERR", error);
        if(error.toString().includes("access_token")){
          dispatch(startLogout())
        }
        dispatch(AddError(error));
        dispatch(BiohazardAction.onRequestFailed());
      });
  };
}

export const exportData = (params: any) => {
  console.log("[CONTAGION EXPORT DATA] ", params);
  //let [isModerator, data] = params;
  return (dispatch: any, getState: any) => {
    //dispatch(BiohazardAction.OnGenericRequest());
    return BiohazardApi.GetContagionReport(params)
      .then((response:any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((res:any) => {
        if (res) {
          dispatch(BiohazardAction.onGetDataSuccess(res.Contagion_List));
          console.log("[CONTAGION EXPORT RESULTS] ", res);
        }
      })
      .catch((err:any) => {
        console.log(err);
        dispatch(BiohazardAction.onRequestFailed());
        dispatch(AddError(err));
      }); 
  }
}


export const exportContactData = (params: any) => {
  console.log("[BIOHDATA -  EXPORT CONTACT REPORT] ", params);

  return (dispatch: any, getState: any) => {
    dispatch({ type: BiohazardAction.REQUEST });

    let parameters: any = {};
    parameters.date_start = params.startDate;
    parameters.date_end = params.endDate;
    parameters.guid = params.guid;
    //console.log("[BIOHDATA -  EXPORT CONTACT REPORT - PARAMETERS] ", parameters);

    return BiohazardApi.GetContactReportData(parameters)
      .then((response:any) => {
        console.log("RES", response);
        if (response.status === HTTP_STATUS.OK) {
          return response.json();
        } else {
          let unauthorized = false;
          let error = "";
          return Api.IsAuthorized(response)
            .then((text: any) => {
              error = text;
            })
            .catch((err: string) => {
              error = err;
              unauthorized = true;
            })
            .finally(() => {
              if (unauthorized) {
                dispatch(startLogout());
              }
              throw new Error(error);
            });
        }
      })
      .then((response:any) => {
        if (response) {
          dispatch(BiohazardAction.onGetReportDataSuccess(response));
          console.log("[BOOKING RESULTS] ", response);
        }
      })
      .catch((err:any) => {
        console.log("[BIOHDATA ERR] ", err);
        dispatch(BiohazardAction.onRequestFailed());
        dispatch(AddError(err));
      }); 
  }
}
